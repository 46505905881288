import React, { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { loadingState, isServerReqErrorState } from "atom";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import Layout from "Layout";
import Header from "Layout/Header";
import CenterTitle from "Layout/Header/CenterTitle";
import Alarm from "Layout/Header/Alarm";
import StoreScheduleItem from "components/StoreScheduleItem";
import { SVG, ERROR_TEXT } from "constants";
import { fetchUserInfo } from "api/user";
import CustomBtn from "components/common/CustomBtn";
import CustomLink from "components/common/CustomLink";
import { fetchReservationList } from "api/reservation";
import CustomPagination from "components/common/CustomPagination";
import CustomModal from "components/common/CustomModal";
import ModalHeading from "components/common/CustomModal/ModalHeading";
import HorizonLineThin from "components/common/HorizonLineThin";
import ModalText from "components/common/CustomModal/ModalText";
import ModalButtonBox from "components/common/CustomModal/ModalButtonBox";

export default function StoreSchedulePage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // recoil
  const [isLoading, setIsLoading] = useRecoilState(loadingState);
  const setIsServerError = useSetRecoilState(isServerReqErrorState);
  //state
  const [errorModal, setErrorModal] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [reservationList, setReservationList] = useState([]);
  const [totalPage, setTotalPage] = useState(1);

  // onClick handler
  const handleClickAddButton = async () => {
    setIsLoading(true);
    try {
      const { data } = await fetchUserInfo();
      return navigate("/reservation-detail/store", {
        state: {
          text: data.franchise.franchiseName,
          id: data.franchise.id,
        },
      });
    } catch (error) {
      if (error.response.status === 500) {
        setIsServerError(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getReservationList = async (page) => {
    setIsLoading(true);
    if (window) {
      window.scrollTo(0, 0);
    }
    try {
      const { data } = await fetchReservationList(page);
      setReservationList(data.reservationList);
      setTotalPage(data.pagingResponse.totalPage > 0 ? data.pagingResponse.totalPage : 1);
    } catch (error) {
      if (error.response.status === 500) {
        setErrorText(ERROR_TEXT.failedToRequest);
        setErrorModal(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getReservationList(searchParams.get("page") ? Number(searchParams.get("page")) : 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <>
      <Header reservation>
        <CenterTitle title="예약 내역" />
        <Alarm />
      </Header>
      <Layout>
        <CustomModal isOpen={errorModal}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text={errorText} />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn text="확인" buttonGrade="tertiary" onClick={() => setErrorModal(false)} />
          </ModalButtonBox>
        </CustomModal>
        <section
          className="schedule-section"
          style={{ height: reservationList.length > 0 ? "94%" : "87%" }}
        >
          <div className="schedule-section__store-add">
            <CustomBtn
              iconSrc={SVG.PLUS_BLUE_NO_CIRCLE_SVG}
              imgAlt="plus link img"
              text="추가"
              addClass="fit-element btn--white-bg"
              onClick={handleClickAddButton}
            />
            {/* <CustomLink
              href="/reservation-detail/store"
              iconSrc={SVG.PLUS_BLUE_NO_CIRCLE_SVG}
              imgAlt="plus link img"
              text="추가"
              addClass="fit-element btn--white-bg"
            /> */}
          </div>
          {!isLoading && reservationList.length <= 0 && (
            <div className="none-text-relative">예약 내역이 없습니다.</div>
          )}
          <ol className="card-list">
            {reservationList.length > 0 &&
              reservationList.map((reservation) => {
                return (
                  <StoreScheduleItem
                    key={`schedule-list-${reservation.id}`}
                    reservation={reservation}
                  />
                );
              })}
          </ol>
        </section>
        {!isLoading && reservationList.length > 0 && (
          <CustomPagination
            activePage={searchParams.get("page") ? Number(searchParams.get("page")) : 1}
            totalPage={totalPage}
            pageUrl="/schedule-list/store"
            paddingStyle={reservationList.length}
          />
        )}
      </Layout>
    </>
  );
}
