import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { some } from "lodash";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";

import { loadingState, isEducationState, isServerReqErrorState } from "atom";
import FullPageLoading from "components/common/FullPageLoading";
import CustomModal from "components/common/CustomModal";
import HorizonLineThin from "components/common/HorizonLineThin";
import ModalText from "components/common/CustomModal/ModalText";
import ModalHeading from "components/common/CustomModal/ModalHeading";
import ModalButtonBox from "components/common/CustomModal/ModalButtonBox";
import CustomBtn from "components/common/CustomBtn";
import { linkUrl, ERROR_TEXT } from "constants";

function Layout({ children, mainClassName }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const refreshToken = localStorage.getItem("refreshToken");

  const isLoading = useRecoilValue(loadingState);
  const [isEducation, setIsEducation] = useRecoilState(isEducationState);
  const [isServerError, setIsServerError] = useRecoilState(isServerReqErrorState);

  const publicPathnames = [
    "/sign-in",
    "/find",
    "/sign-up",
    "/sns-sign",
    "/auth",
    "/password-setting",
    "/payment?",
  ];
  const isPublicUrl = some(publicPathnames, (prefix) => pathname.startsWith(prefix));

  useEffect(() => {
    if ((isPublicUrl || pathname === "/") && pathname !== "/sign-up-complete") {
      if (refreshToken) {
        axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/auth/refreshtoken`, { refreshToken })
          .then((response) => {
            const newAccessToken = response.data.accessToken;
            localStorage.setItem("accessToken", newAccessToken);
            if (window.ReactNativeWebView) {
              window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  type: "setStorage",
                  value: { type: "accessToken" },
                }),
              );
            }
            navigate(linkUrl.main);
          })
          .catch((error) => {
            if (error.response.status === 403) {
              localStorage.removeItem("accessToken");
              localStorage.removeItem("refreshToken");
              if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(
                  JSON.stringify({
                    type: "removeStorage",
                    value: { type: "refreshToken" },
                  }),
                );
              }
              return Promise.reject(error);
            }
          });
      }
      return;
    }

    if (!refreshToken) {
      navigate(linkUrl.signInGuide);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading && <FullPageLoading />}
      <CustomModal isOpen={isEducation}>
        <ModalHeading heading="알림" />
        <HorizonLineThin />
        <ModalText text="교육이 필요합니다." />
        <HorizonLineThin />
        <ModalButtonBox>
          <CustomBtn
            text="확인"
            buttonGrade="tertiary"
            onClick={() => {
              setIsEducation(false);
              navigate("/my-page/work/education/education-list");
            }}
          />
        </ModalButtonBox>
      </CustomModal>
      <CustomModal isOpen={isServerError}>
        <ModalHeading heading="알림" />
        <HorizonLineThin />
        <ModalText text={ERROR_TEXT.failedToRequest} />
        <HorizonLineThin />
        <ModalButtonBox>
          <CustomBtn text="확인" buttonGrade="tertiary" onClick={() => setIsServerError(false)} />
        </ModalButtonBox>
      </CustomModal>
      <main className={mainClassName && mainClassName}>{children}</main>
    </>
  );
}

export default Layout;
