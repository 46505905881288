import { axiosTokenInstance } from "./api";

export const fetchAttendanceList = async (page) => {
  const url = `/caddie/attendance?page=${page}`;
  const { data } = await axiosTokenInstance.get(url);
  return data;
};

export const fetchAttendanceDetail = async (id) => {
  const url = `/caddie/attendance/${id}`;
  const { data } = await axiosTokenInstance.get(url);
  return data;
};

export const fetchCaddieReservationList = async (
  caddieTypeEnum = "NONE",
  page = 1,
  sortType = "",
) => {
  let url = `/attendance?caddieTypeEnum=${caddieTypeEnum}&page=${page}`;
  if (sortType) {
    url += `&sortType=${sortType}`;
  }
  const { data } = await axiosTokenInstance.get(url);
  return data;
};

export const applyAttendance = async (reservationId) => {
  const url = `/attendance?reservationId=${reservationId}`;
  const { data } = await axiosTokenInstance.post(url, {});
  return data;
};

export const deleteAttendance = async (attendanceId) => {
  const url = `/caddie/attendance/${attendanceId}`;
  const { data } = await axiosTokenInstance.delete(url);
  return data;
};
