import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router";

import Layout from "Layout";
import Header from "Layout/Header";
import Back from "Layout/Header/Back";
import CenterTitle from "Layout/Header/CenterTitle";
import MyEducationItem from "components/MyEducationItem";
import CustomLink from "components/common/CustomLink";
import { loadingState } from "atom";
import CustomModal from "components/common/CustomModal";
import ModalHeading from "components/common/CustomModal/ModalHeading";
import HorizonLineThin from "components/common/HorizonLineThin";
import ModalText from "components/common/CustomModal/ModalText";
import ModalButtonBox from "components/common/CustomModal/ModalButtonBox";
import CustomBtn from "components/common/CustomBtn";
import { ERROR_TEXT } from "constants";
import { fetchMyEducation } from "api/education";
import EmptyContents from "components/common/EmptyContents";

export default function MyEducationPage() {
  const navigate = useNavigate();
  // recoil
  const [isLoading, setIsLoading] = useRecoilState(loadingState);
  // state
  const [errorModal, setErrorModal] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [myEducation, setMyEducation] = useState(null);

  // getMyEducation
  const getMyEducation = async () => {
    setIsLoading(true);
    try {
      const { data } = await fetchMyEducation();
      setMyEducation(data);
      return;
    } catch (error) {
      if (error.response.status === 500) {
        setErrorText(ERROR_TEXT.failedToRequest);
        setErrorModal(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMyEducation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header BottomNavigation>
        <Back />
        <CenterTitle title="나의 교육" />
      </Header>
      <Layout>
        <CustomModal isOpen={errorModal}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text={errorText} />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn text="확인" buttonGrade="tertiary" onClick={() => navigate(-1)} />
          </ModalButtonBox>
        </CustomModal>
        <section className="work-list-section">
          {!isLoading && !myEducation && <EmptyContents text="진행 예정인 교육이 없습니다." />}
          <ol className="card-list">
            {/* There is only one training-related item in this area that you have completed or will be completing. */}
            {!isLoading && myEducation && (
              <MyEducationItem complete={myEducation.confirmed} education={myEducation.education} />
            )}
          </ol>
        </section>
        <div className="bottom-button">
          <CustomLink
            href="/my-page/work/education/education-list"
            text="교육 목록보기"
            linkGrade="primary"
          />
        </div>
      </Layout>
    </>
  );
}
