import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSetRecoilState } from "recoil";

import { PNG, SVG } from "constants";
import Layout from "Layout";
import Header from "Layout/Header";
import Back from "Layout/Header/Back";
import CenterTitle from "Layout/Header/CenterTitle";
import DetailHeading from "components/common/DetailItem/DetailHeading";
import Rate from "rc-rate";
import "rc-rate/assets/index.css";
// import CustomLink from "components/common/CustomLink";
import CustomBtn from "components/common/CustomBtn";
import { loadingState } from "atom";
import { postRating } from "api/rating";
import CustomModal from "components/common/CustomModal";
import ModalHeading from "components/common/CustomModal/ModalHeading";
import HorizonLineThin from "components/common/HorizonLineThin";
import ModalText from "components/common/CustomModal/ModalText";
import ModalButtonBox from "components/common/CustomModal/ModalButtonBox";

export default function ReviewPage() {
  const navigate = useNavigate();
  const location = useLocation();
  // recoil
  const setIsLoading = useSetRecoilState(loadingState);
  // state
  const [starRate, setStartRate] = useState(1);
  const [comment, setComment] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [completeModal, setCompleteModal] = useState(false);

  const updateRatingReservation = async (reservationId) => {
    setIsLoading(true);
    try {
      const { success } = await postRating(comment, starRate, reservationId);
      if (success) {
        setCompleteModal(true);
      }
    } catch (error) {
      setErrorModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  // onChange handler
  const handleChangeStarRate = (e) => {
    setStartRate(e);
  };

  const handleChangeComment = (e) => {
    setComment(e.target.value);
  };

  const handleBlurComment = (e) => {
    e.target.value = String(e.target.value).trimEnd();
    setComment(e.target.value);
  };

  const handleSubmitRating = async (e) => {
    e.preventDefault();
    updateRatingReservation(location.state.reservationId);
  };

  return (
    <>
      <Header>
        <Back />
        <CenterTitle title="별점 남기기" />
      </Header>
      <Layout>
        <CustomModal isOpen={errorModal}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text="요청에 실패했습니다. 다시 시도해주세요." />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn text="확인" buttonGrade="tertiary" onClick={() => setErrorModal(false)} />
          </ModalButtonBox>
        </CustomModal>
        <CustomModal isOpen={completeModal}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text="별점 등록이 완료되었습니다." />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn
              text="확인"
              buttonGrade="tertiary"
              onClick={() => navigate("/main", { replace: true })}
            />
          </ModalButtonBox>
        </CustomModal>
        <section className="review-section ">
          <DetailHeading
            className="page-heading"
            title={
              <>
                <strong>S캐디</strong>는 어떠셨나요?
              </>
            }
          />
          <div className="review-section__banner">
            <img src={PNG.REVIEW_BANNER} alt="" />
          </div>
          <div className="review-section__rate-box">
            <Rate
              value={starRate}
              onChange={(e) => handleChangeStarRate(e)}
              character={<img src={SVG.STAR_BLUE} alt="star img" />}
            />
          </div>

          <form onSubmit={handleSubmitRating}>
            <fieldset>
              <legend>리뷰 남기기</legend>
            </fieldset>
            <div className="review-section__textarea">
              <textarea
                value={comment}
                maxLength={50}
                onChange={handleChangeComment}
                onBlur={handleBlurComment}
              />
            </div>
            {/* <CustomLink href={linkUrl.main} linkGrade="primary" text="완료" /> */}
            <CustomBtn buttonType="submit" buttonGrade="primary" text="완료" />
          </form>
        </section>
      </Layout>
    </>
  );
}
