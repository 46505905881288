import { axiosTokenInstance } from "./api";

export const fetchReservationList = async (page) => {
  const url = `/reservation?page=${page}`;
  const { data } = await axiosTokenInstance.get(url);
  return data;
};

export const fetchReservationInfo = async (id) => {
  const url = `/reservation/detail?reservationId=${id}`;
  const { data } = await axiosTokenInstance.get(url);
  return data;
};

export const deleteReservation = async (id) => {
  const url = `/reservation?reservationId=${id}`;
  const { data } = await axiosTokenInstance.delete(url);
  return data;
};
