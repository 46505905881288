import { axiosInstance, axiosTokenInstance } from "./api";

export const signUp = async (body) => {
  const url = "/auth/signup";
  const { data } = await axiosInstance.post(url, body);
  return data;
};

export const checkEmail = async (email) => {
  const url = `/auth/email?email=${email}`;
  const { data } = await axiosInstance.get(url);
  return data;
};

export const signIn = async (username, password, authType) => {
  const url = "/auth/signin";
  const body = {
    username,
    password,
    authType,
  };
  const { data } = await axiosInstance.post(url, body);
  return data;
};

// check refresh token
export const checkRefreshToken = async () => {
  const url = "/auth/refreshtoken";
  const body = {
    refreshToken: String(localStorage.getItem("refreshToken")),
  };

  const { data } = await axiosInstance.post(url, body);
  return data;
};

export const signOut = async () => {
  const url = "/auth/signout";
  const { data } = await axiosTokenInstance.post(url, {});
  return data;
};

export const snsSignIn = async (authType, socialType, code, redirectURL) => {
  const url = `/auth/${authType}/${socialType}/callback?code=${code}&redirectURL=${redirectURL}`;
  const { data } = await axiosInstance.get(url);
  return data;
};

export const signUpSocial = async (body) => {
  const url = `/auth/signup/social`;
  const { data } = await axiosInstance.post(url, body);
  return data;
};

export const signInSocial = async (userId, erole) => {
  const url = `/auth/signin/social`;
  const { data } = await axiosInstance.post(url, { userId, erole });
  return data;
};

export const deleteUser = async () => {
  const url = "/auth/delete";
  const { data } = await axiosTokenInstance.delete(url);
  return data;
};

export const changePassword = async (body) => {
  const url = "/auth/password";
  const { data } = await axiosInstance.put(url, body);
  return data;
};
