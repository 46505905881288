import { axiosInstance, axiosTokenInstance } from "./api";

export const fetchUserInfo = async () => {
  const url = "/user";
  const { data } = await axiosTokenInstance.get(url);
  return data;
};

export const getUserEmailByNameAndPhone = async (userName, phone, certificationNumber, role) => {
  const url = "/user/email";
  const body = {
    userName,
    phone,
    certificationType: "FIND_ID",
    certificationNumber,
    role,
  };
  const { data } = await axiosInstance.post(url, body);
  return data;
};

export const getIsExistUserByEmailNameAndPhone = async (
  certificationNumber,
  email,
  phone,
  userName,
  role,
) => {
  const url = "/user/password";
  const body = {
    certificationNumber,
    certificationType: "FIND_PASSWORD",
    email,
    phone,
    userName,
    role,
  };
  const { data } = await axiosInstance.post(url, body);
  return data;
};

export const updateUserInfo = async (userName, phone, password) => {
  const url = "/user";
  const body = {
    userName,
    phone,
    password,
  };
  const { data } = await axiosTokenInstance.put(url, body);
  return data;
};
