import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import FullPageLoading from "components/common/FullPageLoading";
import { requestPayment } from "api/payment";
import CustomModal from "components/common/CustomModal";
import ModalHeading from "components/common/CustomModal/ModalHeading";
import HorizonLineThin from "components/common/HorizonLineThin";
import ModalText from "components/common/CustomModal/ModalText";
import ModalButtonBox from "components/common/CustomModal/ModalButtonBox";
import CustomBtn from "components/common/CustomBtn";
import { ERROR_TEXT, RN_TYPE } from "constants";
import windowReactNativeWebView from "utils/windowReactNativeWebView";

export default function PaymentProcessPage() {
  const navigate = useNavigate();

  const [errorModal, setErrorModal] = useState(false);
  const [errorText, setErrorText] = useState("");

  const listener = async (event) => {
    const { data, type } = JSON.parse(event.data);

    if (type === RN_TYPE.GET_STORAGE) {
      if (data) {
        localStorage.setItem("refreshToken", data);
        const refreshToken = localStorage.getItem("refreshToken");
        const url = String(window.location.href);
        const queryString = url.split("?")[1];
        const params = queryString.split("&");
        params.forEach((param) => {
          if (param.includes("imp_uid")) {
            const imp_uid = param.split("=")[1];
            axios
              .post(`${process.env.REACT_APP_API_BASE_URL}/auth/refreshtoken`, { refreshToken })
              .then((response) => {
                const newAccessToken = response.data.accessToken;
                localStorage.setItem("accessToken", newAccessToken);
                localStorage.setItem("refreshToken", response.data.refreshToken);
                window.ReactNativeWebView.postMessage(
                  JSON.stringify({
                    type: "setStorage",
                    value: { type: "refreshToken", value: response.data.refreshToken },
                  }),
                );
              })
              .then(() => {
                RequestPayment(imp_uid);
              });
          }
        });
      }
    }
  };

  const reactNativeListener = () => {
    if (windowReactNativeWebView) {
      document.addEventListener("message", listener); //안드로이드 에서만 인식
      window.addEventListener("message", listener); // IOS에서만 인식
    }
  };

  const RequestPayment = async (imp_uid) => {
    try {
      const { success, data } = await requestPayment(imp_uid);
      if (success) {
        if (data.user.roles[0].name === "ROLE_USER") {
          navigate("/payment-detail/user", {
            state: {
              reservationId: data.id,
              beforePayment: true,
            },
            replace: true,
          });
        } else if (data.user.roles[0].name === "ROLE_FRANCHISE") {
          navigate("/payment-detail/store", {
            state: {
              reservationId: data.id,
              beforePayment: true,
            },
            replace: true,
          });
        }
      } else {
        setErrorText(ERROR_TEXT.failedToReservation);
        setErrorModal(true);
      }
    } catch (error) {
      setErrorText(ERROR_TEXT.failedToReservation);
      setErrorModal(true);
    }
  };
  useEffect(() => {
    reactNativeListener();

    return () => {
      if (windowReactNativeWebView) {
        document.removeEventListener("message", listener); //안드로이드 에서만 인식
        window.removeEventListener("message", listener); // IOS에서만 인식
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (window.location.href) {
      if (windowReactNativeWebView) {
        windowReactNativeWebView.postMessage(
          JSON.stringify({ type: RN_TYPE.GET_STORAGE, value: { type: "refreshToken" } }),
        );
      } else {
        const url = String(window.location.href);
        const queryString = url.split("?")[1];
        const params = queryString.split("&");

        let error_code = "";
        let imp_uid = "";
        params.forEach((param) => {
          if (param.includes("error_code")) {
            error_code = param.split("=")[1];
          }
          if (param.includes("imp_uid")) {
            imp_uid = param.split("=")[1];
          }
        });

        if (error_code) {
          setErrorText(ERROR_TEXT.failedToPayment);
          setErrorModal(true);
          return;
        }

        RequestPayment(imp_uid);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  return (
    <>
      {!errorModal && <FullPageLoading />}
      <main className="PADDING_TOP_ZERO PADDING_BOTTOM_ZERO FULL_SIDES">
        <CustomModal isOpen={errorModal}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text={errorText} />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn
              text="확인"
              buttonGrade="tertiary"
              onClick={() => navigate("/main", { replace: true })}
            />
          </ModalButtonBox>
        </CustomModal>
      </main>
    </>
  );
}
