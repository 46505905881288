import React, { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";

import Layout from "Layout";
import Header from "Layout/Header";
import CenterTitle from "Layout/Header/CenterTitle";
import CustomLink from "components/common/CustomLink";
import { SVG } from "constants";
import { userState, loadingState } from "atom";
import { fetchUserPoints } from "./../../../api/point";
import { fetchUserInfo } from "api/user";

export default function MyPageHome({ adminType }) {
  // recoil
  const [userInfo, setUserInfo] = useRecoilState(userState);
  const setIsLoading = useSetRecoilState(loadingState);
  // state
  const [points, setPoints] = useState(0);
  const getUserInfo = async () => {
    setIsLoading(true);
    try {
      const { data } = await fetchUserInfo();
      setUserInfo({
        email: data.user.email,
        username: data.user.username,
        role: data.user.roles[0].name,
        social: data.user.social?.socialType ?? "normal",
        phone: data.user.phone ?? "",
      });
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };
  // setPoint
  const getUserPoint = async () => {
    setIsLoading(true);
    try {
      const { data } = await fetchUserPoints();
      setPoints(data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserInfo().then(() => {
      getUserPoint();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header mypage>
        <CenterTitle title="마이페이지" />
      </Header>
      <Layout>
        {userInfo && (
          <section className="my-page-section">
            <div className="my-page__link-wrapper">
              {userInfo.role === "ROLE_USER" && (
                <>
                  <CustomLink
                    href="/my-page/info/user"
                    linkGrade="primary"
                    text="개인정보"
                    addClass="btn--my-page-bg"
                    iconSrc={SVG.PROFILE_BLACK}
                    imgAlt="profile img"
                    rightArr
                  />
                  <CustomLink
                    href=""
                    linkGrade="primary"
                    text="포인트"
                    addClass="btn--my-page-bg POINTER_NONE"
                    iconSrc={SVG.POINT_SVG}
                    imgAlt="profile img"
                    pointText={`${Number(points).toLocaleString("USD")} P`}
                  />
                  <CustomLink
                    href="/schedule-list/user"
                    linkGrade="primary"
                    text="예약"
                    addClass="btn--my-page-bg"
                    iconSrc={SVG.TIME_REVERSE_SVG}
                    imgAlt="profile img"
                    rightArr
                  />
                  <CustomLink
                    href="/my-page/service"
                    linkGrade="primary"
                    text="고객센터"
                    addClass="btn--my-page-bg"
                    iconSrc={SVG.SUPPORT_SVG}
                    imgAlt="profile img"
                    rightArr
                  />
                  <CustomLink
                    href="/my-page/account-setting/user"
                    linkGrade="primary"
                    text="계정관리"
                    addClass="btn--my-page-bg"
                    iconSrc={SVG.PROFILE_SETTING_SVG}
                    imgAlt="profile img"
                    rightArr
                  />
                </>
              )}
              {userInfo.role === "ROLE_CADDIE" && (
                <>
                  <CustomLink
                    href="/my-page/info/caddie"
                    linkGrade="primary"
                    text="개인정보"
                    addClass="btn--my-page-bg"
                    iconSrc={SVG.PROFILE_BLACK}
                    imgAlt="profile img"
                    rightArr
                  />
                  <CustomLink
                    href=""
                    linkGrade="primary"
                    text="캐시"
                    addClass="btn--my-page-bg POINTER_NONE"
                    iconSrc={SVG.CASH_SVG}
                    imgAlt="profile img"
                    pointText={`${Number(points).toLocaleString("USD")} C`}
                  />
                  <CustomLink
                    href="/my-page/work"
                    linkGrade="primary"
                    text="근무"
                    addClass="btn--my-page-bg"
                    iconSrc={SVG.CADDIE_SVG}
                    imgAlt="profile img"
                    rightArr
                  />
                  <CustomLink
                    href="/my-page/service"
                    linkGrade="primary"
                    text="고객센터"
                    addClass="btn--my-page-bg"
                    iconSrc={SVG.SUPPORT_SVG}
                    imgAlt="profile img"
                    rightArr
                  />
                  <CustomLink
                    href="/my-page/account-setting/caddie"
                    linkGrade="primary"
                    text="계정관리"
                    addClass="btn--my-page-bg"
                    iconSrc={SVG.PROFILE_SETTING_SVG}
                    imgAlt="profile img"
                    rightArr
                  />
                </>
              )}
              {userInfo.role === "ROLE_FRANCHISE" && (
                <>
                  <CustomLink
                    href="/my-page/info/store"
                    linkGrade="primary"
                    text="개인정보"
                    addClass="btn--my-page-bg"
                    iconSrc={SVG.PROFILE_BLACK}
                    imgAlt="profile img"
                    rightArr
                  />
                  <CustomLink
                    href=""
                    linkGrade="primary"
                    text="포인트"
                    addClass="btn--my-page-bg POINTER_NONE"
                    iconSrc={SVG.POINT_SVG}
                    imgAlt="profile img"
                    pointText={`${Number(points).toLocaleString("USD")} P`}
                  />
                  <CustomLink
                    href="/schedule-list/store"
                    linkGrade="primary"
                    text="예약"
                    addClass="btn--my-page-bg"
                    iconSrc={SVG.TIME_REVERSE_SVG}
                    imgAlt="profile img"
                    rightArr
                  />
                  <CustomLink
                    href="/my-page/service"
                    linkGrade="primary"
                    text="고객센터"
                    addClass="btn--my-page-bg"
                    iconSrc={SVG.SUPPORT_SVG}
                    imgAlt="profile img"
                    rightArr
                  />
                  <CustomLink
                    href="/my-page/account-setting/store"
                    linkGrade="primary"
                    text="계정관리"
                    addClass="btn--my-page-bg"
                    iconSrc={SVG.PROFILE_SETTING_SVG}
                    imgAlt="profile img"
                    rightArr
                  />
                </>
              )}
            </div>
          </section>
        )}
      </Layout>
    </>
  );
}
