import { axiosInstance, axiosTokenInstance } from "./api";

export const fetchAddressList = async () => {
  const url = "/address";
  const { data } = await axiosInstance.get(url);
  return data;
};

export const fetchCaddieLocation = async () => {
  const url = "/caddie/location";
  const { data } = await axiosTokenInstance.get(url);
  return data;
};

export const saveCaddieLocation = async (locationList) => {
  const newLocationList = [];
  locationList.forEach((location) => {
    if (String(location[1]) === "전체") {
      newLocationList.push(`${location[0].slice(0, 3)}`);
    } else {
      newLocationList.push(`${location[0].slice(0, 3)}%${location[1].slice(-2)}`);
    }
  });

  const url = "/caddie/location";
  const body = newLocationList;
  const { data } = await axiosTokenInstance.post(url, body);
  return data;
};
