import React, { useState } from "react";
import { useNavigate } from "react-router";
import { userState, loadingState } from "atom";
import { useSetRecoilState } from "recoil";

import Layout from "Layout";
import Header from "Layout/Header";
import CenterTitle from "Layout/Header/CenterTitle";
import { SVG, linkUrl, SYSTEM_TEXT, ERROR_TEXT } from "constants";
import CustomBtn from "components/common/CustomBtn";
import CustomModal from "components/common/CustomModal";
import ModalHeading from "components/common/CustomModal/ModalHeading";
import ModalText from "components/common/CustomModal/ModalText";
import HorizonLineThin from "components/common/HorizonLineThin";
import ModalButtonBox from "components/common/CustomModal/ModalButtonBox";
import Back from "Layout/Header/Back";
import { deleteUser, signOut } from "api/auth";

export default function MyAccountSetting({ adminType }) {
  const navigate = useNavigate();

  // recoil
  const setUserInfo = useSetRecoilState(userState);
  const setIsLoading = useSetRecoilState(loadingState);
  // state
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorModal, setErrorModal] = useState(false);

  const doSignOut = async () => {
    setIsLoading(true);
    try {
      const { success } = await signOut();
      if (success) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: "removeStorage",
              value: { type: "refreshToken" },
            }),
          );
        }
        setUserInfo(null);
        navigate(linkUrl.signInGuide, { replace: true });
      }
    } catch (error) {
      if (error.response.status === 500) {
        setErrorText(ERROR_TEXT.failedToRequest);
        setErrorModal(true);
        return;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const userDelete = async () => {
    setIsLoading(true);
    try {
      const { success } = await deleteUser();
      if (success) {
        setOpenModal(false);
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: "removeStorage",
              value: { type: "refreshToken" },
            }),
          );
        }
        setUserInfo(null);
        setOpenConfirmModal(true);
      }
    } catch (error) {
      if (error.response.status === 500) {
        setOpenModal(false);
        setErrorText(ERROR_TEXT.failedToRequest);
        setErrorModal(true);
        return;
      }
    } finally {
      setIsLoading(false);
    }
  };

  // onClick handler
  const handleClickSignOutButton = async () => {
    await doSignOut();
  };

  return (
    <>
      <Header mypage>
        <Back />
        <CenterTitle title="계정관리" />
      </Header>
      <Layout>
        <CustomModal isOpen={openModal} handleClose={() => setOpenModal(false)}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText
            text={
              <>
                보유중인 캐시 및 포인트가 소멸될 수 있습니다.
                <br /> 정말 탈퇴하시겠습니까?
              </>
            }
          />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn
              text="예"
              type="tertiary"
              addClass="btn--white-bg"
              onClick={() => {
                userDelete();
              }}
            />
            <CustomBtn
              text="아니오"
              type="tertiary"
              addClass="MARGIN_LEFT_10"
              onClick={() => setOpenModal(false)}
            />
          </ModalButtonBox>
        </CustomModal>

        <CustomModal isOpen={openConfirmModal}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text={SYSTEM_TEXT.deleteComplete} />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn
              text="확인"
              type="tertiary"
              onClick={() => {
                navigate(linkUrl.signInGuide);
                setOpenConfirmModal(false);
              }}
            />
          </ModalButtonBox>
        </CustomModal>
        <CustomModal isOpen={errorModal}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text={errorText} />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn text="확인" buttonGrade="tertiary" onClick={() => setErrorModal(false)} />
          </ModalButtonBox>
        </CustomModal>
        <section className="my-page-account-setting-section">
          <div className="my-page__link-wrapper">
            <CustomBtn
              buttonGrade="primary"
              text="로그아웃"
              addClass="btn--my-page-bg border-bottom-block"
              iconSrc={SVG.LOG_OUT_SVG}
              imgAlt="profile img"
              rightArr
              onClick={handleClickSignOutButton}
            />
            <CustomBtn
              buttonGrade="primary"
              text="탈퇴"
              addClass="btn--my-page-bg border-bottom-block"
              iconSrc={SVG.SIGN_OUT_SVG}
              imgAlt="profile img"
              rightArr
              onClick={() => setOpenModal(true)}
            />
          </div>
        </section>
      </Layout>
    </>
  );
}
