import { axiosInstance } from "./api";

export const fetchFaqAll = async (page) => {
  const url = `/faq?page=${page}`;
  const { data } = await axiosInstance.get(url);
  return data;
};

export const fetchFaqByCategory = async (faqCategory, page) => {
  const url = `/faq/${faqCategory}?page=${page}`;
  const { data } = await axiosInstance.get(url);
  return data;
};
