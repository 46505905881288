import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import parse from "html-react-parser";

import Layout from "Layout";
import Header from "Layout/Header";
import Back from "Layout/Header/Back";
import CenterTitle from "Layout/Header/CenterTitle";
import InputWrap from "components/common/InputWrap";
import HorizonLineThin from "components/common/HorizonLineThin";
import DetailHeading from "components/common/DetailItem/DetailHeading";
import CustomCheckBox from "components/common/InputWrap/CustomCheckBox";
import CustomModal from "components/common/CustomModal";
import ModalDangerousHtml from "components/common/CustomModal/ModalDangerousHtml";
import CustomBtn from "components/common/CustomBtn";
import { fetchPrivacyPolicy } from "api/common";

const INIT_MODAL = { open: false, title: "", content: "" };

const CHECKBOX_IDS = {
  personalInfo: "personalInfo",
  locationInfo: "locationInfo",
  financialTransaction: "financialTransaction",
};

export default function SignUpTermsOfUsePage() {
  const [openModal, setOpenModal] = useState(INIT_MODAL);
  const navigate = useNavigate();
  const location = useLocation();

  // check box
  const [checkBoxes, setCheckBoxes] = useState([]);

  // checkbox onchange handler
  const handleOnChangeCheckBox = (checked, id) => {
    if (checked) {
      setCheckBoxes((prev) => [...prev, id]);
    } else {
      setCheckBoxes(checkBoxes.filter((el) => el !== id));
    }
  };

  const handleAllCheck = (checked) => {
    if (checked) {
      setCheckBoxes([
        CHECKBOX_IDS.personalInfo,
        CHECKBOX_IDS.locationInfo,
        CHECKBOX_IDS.financialTransaction,
      ]);
    } else {
      setCheckBoxes([]);
    }
  };

  // policy
  const [infoPolicy, setInfoPolicy] = useState([]);
  const [locationPolicy, setLocationPolicy] = useState([]);
  const [financialPolicy, setFinancialPolicy] = useState([]);

  useEffect(() => {
    const getInfoPolicy = async () => {
      try {
        const { data } = await fetchPrivacyPolicy("PRIVACY_POLICY");
        if (!data) {
          return;
        }
        setInfoPolicy(data);
      } catch (err) {}
    };

    const getLocationPolicy = async () => {
      try {
        const { data } = await fetchPrivacyPolicy("LOCATION_POLICY");
        if (!data) {
          return;
        }
        setLocationPolicy(data);
      } catch (err) {}
    };
    const getFinancialPolicy = async () => {
      try {
        const { data } = await fetchPrivacyPolicy("FINANCIAL_POLICY");
        if (!data) {
          return;
        }
        setFinancialPolicy(data);
      } catch (err) {}
    };

    getInfoPolicy();
    getLocationPolicy();
    getFinancialPolicy();
  }, []);

  return (
    <>
      <Header BottomNavigation>
        <Back />
        <CenterTitle title="회원가입 이용약관" />
      </Header>
      <Layout>
        <CustomModal
          addClassName="full-size"
          isOpen={openModal.open}
          handleClose={() => setOpenModal(INIT_MODAL)}
        >
          <ModalDangerousHtml
            title={openModal.title}
            text={parse(openModal.content)}
            onClose={() => setOpenModal(INIT_MODAL)}
          />
        </CustomModal>
        <section className="terms-of-use-section">
          <DetailHeading
            className="page-heading TEXT_CENTER"
            title={
              <>
                캐디 <strong>서비스 이용약관</strong>
                <br />
                동의가 필요합니다
              </>
            }
          />
          <form>
            <fieldset>
              <legend>이용약관</legend>
              <InputWrap>
                <div className="terms-of-use-section__top">
                  <CustomCheckBox
                    required
                    id="personalInfo"
                    labelText="개인정보 처리방침동의"
                    checked={!!checkBoxes.includes(CHECKBOX_IDS.personalInfo)}
                    onChange={(e) =>
                      handleOnChangeCheckBox(e.target.checked, CHECKBOX_IDS.personalInfo)
                    }
                  >
                    <button
                      type="button"
                      onClick={() =>
                        setOpenModal({
                          open: true,
                          title: "개인정보 처리방침동의",
                          content: infoPolicy[0].policyContent,
                        })
                      }
                    >
                      보기
                    </button>
                  </CustomCheckBox>
                  <CustomCheckBox
                    required
                    id="locationInfo"
                    labelText="위치정보 이용약관 동의"
                    checked={!!checkBoxes.includes(CHECKBOX_IDS.locationInfo)}
                    onChange={(e) =>
                      handleOnChangeCheckBox(e.target.checked, CHECKBOX_IDS.locationInfo)
                    }
                  >
                    <button
                      type="button"
                      onClick={() =>
                        setOpenModal({
                          open: true,
                          title: "위치정보 이용약관 동의",
                          content: locationPolicy[0].policyContent,
                        })
                      }
                    >
                      보기
                    </button>
                  </CustomCheckBox>
                  <CustomCheckBox
                    required
                    id="financialTransaction"
                    labelText="전자금융거래 이용약관"
                    checked={!!checkBoxes.includes(CHECKBOX_IDS.financialTransaction)}
                    onChange={(e) =>
                      handleOnChangeCheckBox(e.target.checked, CHECKBOX_IDS.financialTransaction)
                    }
                  >
                    <button
                      type="button"
                      onClick={() =>
                        setOpenModal({
                          open: true,
                          title: "전자금융거래 이용약관",
                          content: financialPolicy[0].policyContent,
                        })
                      }
                    >
                      보기
                    </button>
                  </CustomCheckBox>
                </div>
                <HorizonLineThin />
                <div className="terms-of-use-section__bottom">
                  <CustomCheckBox
                    id="all"
                    labelText="전체 동의하기"
                    checked={checkBoxes.length === 3}
                    onChange={(e) => handleAllCheck(e.target.checked)}
                  />
                </div>
              </InputWrap>
            </fieldset>
          </form>
        </section>
        <div className="bottom-button">
          <CustomBtn
            disabled={checkBoxes.length < 3}
            buttonType="button"
            text="회원가입"
            buttonGrade="primary"
            onClick={() =>
              navigate("/sign-up-form", {
                state: {
                  adminType: location.state.adminType,
                },
              })
            }
          />
        </div>
      </Layout>
    </>
  );
}
