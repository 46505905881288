import { axiosInstance } from "./api";

export const fetchFranchiseCoordinate = async (
  neLatitude,
  neLongitude,
  swLatitude,
  swLongitude,
) => {
  const url = `/franchise/coordinate?neLatitude=${neLatitude}&neLongitude=${neLongitude}&swLatitude=${swLatitude}&swLongitude=${swLongitude}`;

  const { data } = await axiosInstance.get(url);
  return data;
};

export const fetchFranchiseKeyword = async (keyword) => {
  const url = `/franchise/keyword?keyword=${keyword}`;

  const { data } = await axiosInstance.get(url);
  return data;
};

export const fetchFranchiseInfo = async (id) => {
  const url = `/franchise/info?franchiseId=${id}`;
  const { data } = await axiosInstance.get(url);
  return data;
};

export const fetchBusinessHours = async (id) => {
  const url = `/franchise/business-hours/${id}`;
  const { data } = await axiosInstance.get(url);
  return data;
};
