import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import Layout from "Layout";
import Header from "Layout/Header";
import CenterTitle from "Layout/Header/CenterTitle";
import { MOCK_QAN_DATA } from "constants";
import Back from "Layout/Header/Back";
import ScrollContainer from "react-indiana-drag-scroll";
import AccordionItem from "components/AccordionItem";
import CustomPagination from "components/common/CustomPagination";
import { fetchFaqAll, fetchFaqByCategory } from "api/faq";
import { loadingState } from "atom";

export default function MyQnaPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // recoil
  const setIsLoading = useSetRecoilState(loadingState);
  // state
  const [isClick, setIsClick] = useState("ALL");
  const [faqList, setFaqList] = useState([]);
  const [totalPage, setTotalPage] = useState(1);

  // fetch Faq All List
  const getFaqAllList = async (page) => {
    try {
      setIsLoading(true);
      const { success, data } = await fetchFaqAll(page);
      if (!success || !data) {
        return;
      }
      setFaqList(data.faqList);
      setTotalPage(data.pagingResponse.totalPage > 0 ? data.pagingResponse.totalPage : 1);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  // fetch Faq Type List
  const getFaqTypeList = async (type, page) => {
    try {
      setIsLoading(true);
      const { success, data } = await fetchFaqByCategory(type, page);
      if (!success || !data) {
        return;
      }
      setFaqList(data);
      setFaqList(data.faqList);
      setTotalPage(data.pagingResponse.totalPage > 0 ? data.pagingResponse.totalPage : 1);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
    if (searchParams.get("type") && searchParams.get("type") !== "ALL") {
      setIsClick(searchParams.get("type"));
      getFaqTypeList(
        searchParams.get("type"),
        searchParams.get("page") ? Number(searchParams.get("page")) : 1,
      );
    } else {
      setIsClick("ALL");
      getFaqAllList(searchParams.get("page") ? Number(searchParams.get("page")) : 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <>
      <Header mypage>
        <Back />
        <CenterTitle title="자주 묻는 질문" />
      </Header>
      <Layout>
        <section className="my-page-service-qna-section">
          <div className="qna-tab">
            <ScrollContainer className="scroll-container">
              {MOCK_QAN_DATA &&
                MOCK_QAN_DATA.map((item, index) => {
                  return (
                    <React.Fragment key={`menu-${item.id}`}>
                      <button
                        type="button"
                        className={`scroll-item btn-common qna-button ${
                          item.id === isClick ? "active" : ""
                        } `}
                        onClick={() => {
                          setIsClick(item.id);
                          navigate(`/my-page/service/qna?type=${item.id}`);
                        }}
                      >
                        {item.title}
                      </button>
                    </React.Fragment>
                  );
                })}
            </ScrollContainer>
          </div>

          <div className="qna-accordion">
            {faqList &&
              faqList.map((faq, index) => {
                return (
                  <AccordionItem key={`faq-${faq.id}`} title={faq.question} text={faq.answer} />
                );
              })}
          </div>
          <CustomPagination
            activePage={searchParams.get("page") ? Number(searchParams.get("page")) : 1}
            totalPage={totalPage}
            pageUrl="/my-page/service/qna"
          />
        </section>
      </Layout>
    </>
  );
}
