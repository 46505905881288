import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import { useSetRecoilState } from "recoil";

import { SVG, RN_TYPE } from "constants";
import Layout from "Layout";
import Header from "Layout/Header";
import Back from "Layout/Header/Back";
import CenterTitle from "Layout/Header/CenterTitle";
import DetailHeading from "components/common/DetailItem/DetailHeading";
import CustomLink from "components/common/CustomLink";
import windowReactNativeWebView from "utils/windowReactNativeWebView";
import reactNativeHandler from "utils/reactNativeEx";
import { loadingState } from "atom";
import { saveFireBaseToken } from "api/push";

export default function SignInGuidePage() {
  const navigate = useNavigate();

  const setIsLoading = useSetRecoilState(loadingState);
  const listener = (event) => {
    const { data, type } = JSON.parse(event.data);

    if (type === RN_TYPE.GET_STORAGE) {
      if (data) {
        localStorage.setItem("refreshToken", data);
        checkToken(data);
      }
    }
  };

  const reactNativeListener = () => {
    if (windowReactNativeWebView) {
      document.addEventListener("message", listener); //안드로이드 에서만 인식
      window.addEventListener("message", listener); // IOS에서만 인식
    }
  };

  const checkToken = async (data) => {
    const refreshToken = data;
    if (refreshToken) {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/auth/refreshtoken`,
          { refreshToken },
        );
        const newAccessToken = response.data.accessToken;
        localStorage.setItem("accessToken", newAccessToken);
        localStorage.setItem("refreshToken", response.data.refreshToken);
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "setStorage",
            value: { type: "refreshToken", value: data.refreshToken },
          }),
        );
        if (windowReactNativeWebView) {
          const deviceId = localStorage.getItem("deviceId");
          const fcmToken = localStorage.getItem("fcmToken");
          if (deviceId && fcmToken) {
            await saveFireBaseToken(deviceId, fcmToken);
          }
        }
        navigate("/main");
      } catch (err) {
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "removeStorage",
            value: { type: "refreshToken" },
          }),
        );
        return Promise.reject(err);
      } finally {
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    reactNativeListener();
    if (windowReactNativeWebView) {
      windowReactNativeWebView.postMessage(
        JSON.stringify({ type: "getStorage", value: { type: "refreshToken" } }),
      );
    }
    return () => {
      if (windowReactNativeWebView) {
        document.removeEventListener("message", listener); //안드로이드 에서만 인식
        window.removeEventListener("message", listener); // IOS에서만 인식
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (windowReactNativeWebView) {
      const value = "in";
      reactNativeHandler("back", value);
    }

    return () => {
      if (windowReactNativeWebView) {
        const value = "out";
        reactNativeHandler("back", value);
      }
    };
  }, []);

  return (
    <>
      <Header BottomNavigation>
        <Back />
        <CenterTitle title="로그인" />
      </Header>
      <Layout>
        <section className="sign-in-guide-section">
          <DetailHeading
            className="page-heading"
            title={
              <>
                원하시는 <strong>로그인</strong>
                <br />
                유형을 선택해 주세요.
              </>
            }
          />

          <div className="sign-in-guide__btn-box">
            <CustomLink
              href="/sign-in-social/user"
              linkGrade="primary"
              text="사용자"
              addClass="btn--white-bg"
              iconSrc={SVG.PROFILE_BLUE}
              imgAlt="profile img"
            />
            <CustomLink
              href="/sign-in-social/caddie"
              linkGrade="primary"
              text="캐디"
              addClass="btn--white-bg"
              iconSrc={SVG.CADDIE_BLUE}
              imgAlt="caddie img"
            />
            <CustomLink
              href="/sign-in-social/store"
              linkGrade="primary"
              text="이용점"
              addClass="btn--white-bg"
              iconSrc={SVG.STORE_BLUE}
              imgAlt="store img"
            />
          </div>
        </section>
      </Layout>
    </>
  );
}
