import React, { useEffect, useState } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { userState, loadingState } from "atom";

import Layout from "Layout";
import Header from "Layout/Header";
import CenterTitle from "Layout/Header/CenterTitle";
import CustomLink from "components/common/CustomLink";
import { SVG, USER_TYPE } from "constants";
import Back from "Layout/Header/Back";
import MyPageInfo from "components/common/MyPageInfo";
import { phoneNumberFormat } from "utils/commonFunction";
import { fetchUserInfo } from "api/user";

export default function MyInfoPage({ adminType }) {
  // recoil
  const [userInfo, setUserInfo] = useRecoilState(userState);
  const setIsLoading = useSetRecoilState(loadingState);

  const [franchiseInfo, setFranchiseInfo] = useState(null);

  const userRoute = (type) => {
    switch (type) {
      case "KAKAO":
        return "카카오";
      case "NAVER":
        return "네이버";
      case "GOOGLE":
        return "구글";
      case "APPLE":
        return "애플";
      default:
        return "일반";
    }
  };

  const getUserInfo = async () => {
    setIsLoading(true);
    try {
      const { data } = await fetchUserInfo();
      setUserInfo({
        email: data.user.email,
        username: data.user.username,
        role: data.user.roles[0].name,
        social: data.user.social?.socialType ?? "normal",
        phone: data.user.phone ?? "",
      });
      if (adminType === "store") {
        setFranchiseInfo({
          franchiseName: data.franchise.franchiseName,
          address:
            data.franchise.address +
            ` ${data.franchise.addressDetail ? data.franchise.addressDetail : ""}`,
        });
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header BottomNavigation>
        <Back />
        <CenterTitle title="개인정보" />
      </Header>
      <Layout>
        {userInfo && (
          <>
            <section className="my-page-info-section">
              <ul className="info-list">
                {userInfo.role !== "ROLE_FRANCHISE" && (
                  <>
                    <MyPageInfo title="이메일" subTitle={userInfo?.email ?? ""} />
                    <MyPageInfo
                      title="회원가입 경로"
                      subTitle={userRoute(userInfo?.social ?? "")}
                    />
                    <MyPageInfo title="회원명" subTitle={userInfo?.username ?? ""} />
                    <MyPageInfo
                      title="연락처"
                      subTitle={phoneNumberFormat(userInfo?.phone ?? "")}
                    />
                  </>
                )}
                {userInfo.role === "ROLE_FRANCHISE" && franchiseInfo && (
                  <>
                    <MyPageInfo title="이메일" subTitle={userInfo?.email ?? ""} />
                    <MyPageInfo title="이용점명" subTitle={franchiseInfo.franchiseName ?? ""} />
                    <MyPageInfo title="연락처" subTitle={userInfo?.phone ?? ""} />
                    <MyPageInfo title="주소" subTitle={franchiseInfo.address ?? ""} />
                  </>
                )}
              </ul>
            </section>
            <div className="bottom-button">
              {userInfo.role !== "ROLE_FRANCHISE" && (
                <CustomLink
                  href={`/my-page/info/edit/${adminType}`}
                  text="변경"
                  linkGrade="primary"
                />
              )}
              {userInfo.role === "ROLE_FRANCHISE" && (
                <>
                  <span className="bottom-button__guide-text">
                    <img src={SVG.NOTICE_SVG} alt="notice img" />
                    이용점 정보 변경은 관리자이게 문의해주세요.
                  </span>
                  <CustomLink
                    href={`/my-page/info/edit/${adminType}`}
                    text="변경"
                    linkGrade="primary"
                  />
                </>
              )}
            </div>
          </>
        )}
      </Layout>
    </>
  );
}
