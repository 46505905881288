import { useState, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { signInSocial, snsSignIn } from "api/auth";
import { userState, loadingState } from "atom";
import { linkUrl, ERROR_TEXT } from "constants";
import Layout from "Layout";
import CustomModal from "components/common/CustomModal";
import ModalHeading from "components/common/CustomModal/ModalHeading";
import HorizonLineThin from "components/common/HorizonLineThin";
import ModalText from "components/common/CustomModal/ModalText";
import ModalButtonBox from "components/common/CustomModal/ModalButtonBox";
import CustomBtn from "components/common/CustomBtn";
import windowReactNativeWebView from "utils/windowReactNativeWebView";
import { saveFireBaseToken } from "api/push";

const SocialPage = () => {
  const navigate = useNavigate();
  const { socialType, userType } = useParams();
  const searchParams = useSearchParams()[0];

  // recoil
  const setUserInfo = useSetRecoilState(userState);
  const setIsLoading = useSetRecoilState(loadingState);

  // alert modal
  const [isOpen, setIsOpen] = useState(false);
  const [errorText, setErrorText] = useState("");

  // social sign in
  const socialCodeSignIn = async () => {
    let signInSocialType = "";
    switch (socialType) {
      case "kakao":
        signInSocialType = "KAKAO";
        break;
      case "naver":
        signInSocialType = "NAVER";
        break;
      case "google":
        signInSocialType = "GOOGLE";
        break;
      default:
        signInSocialType = "APPLE";
        break;
    }
    setIsLoading(true);
    if (signInSocialType !== "APPLE") {
      try {
        const { success, data } = await snsSignIn(
          userType,
          signInSocialType,
          searchParams.get("code"),
          `${process.env.REACT_APP_BASE_URL}/auth/${socialType}/${userType}`,
        );
        if (!success || !data) {
          navigate(-1);
          return;
        }
        if (!data.registered) {
          const socialSignUpInfo = {
            email: data.email,
            refreshToken: data.refreshToken,
            oauthId: data.oauthId,
            socialType: data.socialType,
            authType: data.authType,
          };
          sessionStorage.setItem("socialSignUpInfo", JSON.stringify(socialSignUpInfo));
          setIsLoading(false);
          navigate("/sns-sign-up", { replace: true });
          return;
        }

        const { success: signInSuccess, data: signInData } = await signInSocial(
          data.userId,
          userType === "user"
            ? "ROLE_USER"
            : userType === "caddie"
              ? "ROLE_CADDIE"
              : "ROLE_FRANCHISE",
        );

        if (signInData.accessToken && signInSuccess) {
          localStorage.setItem("accessToken", signInData.accessToken);
          localStorage.setItem("refreshToken", signInData.refreshToken);
          // mobile
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                type: "setStorage",
                value: { type: "refreshToken", value: signInData.refreshToken },
              }),
            );
          }

          setUserInfo({
            email: signInData.email,
            username: signInData.username,
            role: signInData.roles[0],
            social: signInData.socialType ?? "normal",
            phone: signInData.phone ?? "",
            point: signInData.point,
          });
          if (windowReactNativeWebView) {
            const deviceId = localStorage.getItem("deviceId");
            const fcmToken = localStorage.getItem("fcmToken");
            if (deviceId && fcmToken) {
              await saveFireBaseToken(deviceId, fcmToken);
            }
          }
          navigate(linkUrl.main, { replace: true });
        }
      } catch (err) {
        if (err?.errCode === "1001") {
          setErrorText(ERROR_TEXT.alreadySignUp);
        } else {
          setErrorText(ERROR_TEXT.failedToSignIn);
        }
        setIsOpen(true);
        setIsLoading(false);
        return;
      } finally {
        setIsLoading(false);
      }
    } else {
      const isRegistered = searchParams.get("isRegistered");
      if (isRegistered === "false") {
        const email = searchParams.get("email");
        const oauthId = searchParams.get("oauthId");
        const socialSignUpInfo = {
          email,
          refreshToken: null,
          oauthId: oauthId,
          socialType: signInSocialType,
          authType: userType,
        };
        sessionStorage.setItem("socialSignUpInfo", JSON.stringify(socialSignUpInfo));
        setIsLoading(false);
        navigate("/sns-sign-up", { replace: true });
        return;
      } else {
        const userId = searchParams.get("userId");
        try {
          const { success, data } = await signInSocial(
            userId,
            userType === "user"
              ? "ROLE_USER"
              : userType === "caddie"
                ? "ROLE_CADDIE"
                : "ROLE_FRANCHISE",
          );

          if (data.accessToken && success) {
            localStorage.setItem("accessToken", data.accessToken);
            localStorage.setItem("refreshToken", data.refreshToken);
            // mobile
            if (window.ReactNativeWebView) {
              window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  type: "setStorage",
                  value: { type: "refreshToken", value: data.refreshToken },
                }),
              );
            }

            setUserInfo({
              email: data.email,
              username: data.username,
              role: data.roles[0],
              social: data.socialType ?? "normal",
              phone: data.phone ?? "",
              point: data.point,
            });
            navigate(linkUrl.main, { replace: true });
          }
        } catch (error) {
          if (error?.errCode === "1001") {
            setErrorText(ERROR_TEXT.alreadySignUp);
          } else {
            setErrorText(ERROR_TEXT.failedToSignIn);
          }
          setIsOpen(true);
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    socialCodeSignIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <CustomModal isOpen={isOpen}>
        <ModalHeading heading="알림" />
        <HorizonLineThin />
        <ModalText text={errorText} />
        <HorizonLineThin />
        <ModalButtonBox>
          <CustomBtn text="확인" buttonGrade="tertiary" onClick={() => navigate(-1)} />
        </ModalButtonBox>
      </CustomModal>
    </Layout>
  );
};

export default SocialPage;
