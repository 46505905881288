import { axiosInstance } from "./api";

export const sendSms = async (type, to) => {
  const body = { certificationType: type, to };
  const url = "/sms";
  const { data } = await axiosInstance.post(url, body);
  return data;
};

export const certificationSmsCode = async (certificationNumber, certificationType, phone) => {
  const body = { certificationNumber, certificationType, phone };
  const url = "/certification";
  const { data } = await axiosInstance.post(url, body);
  return data;
};
