import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSetRecoilState } from "recoil";

import { SVG, ERROR_TEXT } from "constants";
import Layout from "Layout";
import Header from "Layout/Header";
import Back from "Layout/Header/Back";
import CenterTitle from "Layout/Header/CenterTitle";
import DetailItem from "components/common/DetailItem";
import DetailList from "components/common/DetailItem/DetailList";
import DetailHeading from "components/common/DetailItem/DetailHeading";
import DetailIconList from "components/common/DetailItem/DetailIconList";
import HorizonLineThick from "components/common/HorizonLineThick";
import BannerLInk from "components/common/BannerLInk";
import CustomModal from "components/common/CustomModal";
import ModalHeading from "components/common/CustomModal/ModalHeading";
import HorizonLineThin from "components/common/HorizonLineThin";
import ModalText from "components/common/CustomModal/ModalText";
import ModalButtonBox from "components/common/CustomModal/ModalButtonBox";
import CustomBtn from "components/common/CustomBtn";
import { loadingState } from "atom";
import { deleteReservation, fetchReservationInfo } from "api/reservation";
import moment from "moment";
import { getKoreanAmPm, getKoreanDayByDay } from "utils/commonFunction";
import { fetchBanners } from "api/common";

export default function StorePaymentDetailPage() {
  const navigate = useNavigate();
  const location = useLocation();
  // recoil
  const setIsLoading = useSetRecoilState(loadingState);
  // state
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [reservation, setReservation] = useState(null);
  const [adBanner, setAdBanner] = useState(null);

  const getReservationInfo = async (id) => {
    setIsLoading(true);
    try {
      const { data } = await fetchReservationInfo(id);
      setReservation(data);
    } catch (error) {
      setErrorText(ERROR_TEXT.failedToRequest);
      setErrorModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getBannerInfo = async () => {
    const { success, data } = await fetchBanners(true);
    if (success) {
      const bannerIdx =
        parseInt(Math.random() * data.length, 10) === data.length
          ? 0
          : parseInt(Math.random() * data.length, 10);
      setAdBanner(data[bannerIdx]);
    }
  };

  const cancelReservation = async (id) => {
    setIsLoading(true);
    try {
      const { success } = await deleteReservation(id);
      if (success) {
        setOpenConfirmModal(true);
      } else {
        setErrorText(ERROR_TEXT.failedToCancelPayment);
        setErrorModal(true);
      }
    } catch (error) {
      setErrorText(ERROR_TEXT.failedToRequest);
      setErrorModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (location) {
      if (!location.state) {
        setErrorText(ERROR_TEXT.invalidAccess);
        setErrorModal(true);
        return;
      } else {
        getReservationInfo(location.state.reservationId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    getBannerInfo();
  }, []);

  return (
    <>
      <Header reservation BottomNavigation>
        <Back href="/schedule-list/store" />
        <CenterTitle title="결제 완료" />
      </Header>
      <Layout mainClassName="PADDING_TOP_ZERO PADDING_BOTTOM_ZERO FULL_SIDES">
        <CustomModal isOpen={openModal} handleClose={() => setOpenModal(false)}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text="정말 취소하시겠습니까?" />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn
              text="예"
              type="tertiary"
              addClass="btn--white-bg"
              onClick={() => {
                cancelReservation(location.state.reservationId);
                setOpenModal(false);
              }}
            />
            <CustomBtn
              text="아니오"
              type="tertiary"
              addClass="MARGIN_LEFT_10"
              onClick={() => setOpenModal(false)}
            />
          </ModalButtonBox>
        </CustomModal>

        <CustomModal isOpen={openConfirmModal}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text="취소가 완료되었습니다." />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn
              text="확인"
              type="tertiary"
              onClick={() => {
                navigate("/payment-cancel", {
                  state: {
                    reservationId: location.state.reservationId,
                  },
                  replace: true,
                });
                setOpenConfirmModal(false);
              }}
            />
          </ModalButtonBox>
        </CustomModal>
        <CustomModal isOpen={errorModal}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text={errorText} />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn text="확인" type="tertiary" onClick={() => navigate(-1)} />
          </ModalButtonBox>
        </CustomModal>

        {reservation && (
          <section className="detail-section">
            <DetailHeading
              title={
                <>
                  예약완료! <strong>S캐디</strong>가 배정중!
                </>
              }
            />
            <DetailItem title="예약 일시">
              <DetailIconList
                imgSrc={SVG.CALENDAR_SVG}
                imgAlt="calendar img"
                subTitle={`${moment(new Date(reservation.reservationDatetime)).format(
                  "YYYY.MM.DD",
                )} (${getKoreanDayByDay(moment(new Date(reservation.reservationDatetime)).day())})`}
                className="FONT_WEIGHT_SEMI FONT_SIZE_16"
              />
              <DetailIconList
                imgSrc={SVG.TIME_SVG}
                imgAlt="time img"
                subTitle={
                  reservation.reservationDatetime
                    ? `${getKoreanAmPm(moment(new Date(reservation.reservationDatetime)).hour())} 
                  ${moment(new Date(reservation.reservationDatetime)).format("HH:mm")}`
                    : ""
                }
                className="FONT_WEIGHT_SEMI FONT_SIZE_16"
              />
            </DetailItem>
            <HorizonLineThick />

            <DetailItem title="예약 정보">
              <DetailList
                listTitle="캐디 유형"
                listSubTitle={reservation.caddieTypeName === "S_CADDIE" ? "S캐디" : "라운드 S캐디"}
                className="BLUE FONT_WEIGHT_SEMI"
              />
              <DetailList
                listTitle="캐디 수"
                listSubTitle={`${reservation.caddieCount}명`}
                className="BLUE FONT_WEIGHT_SEMI"
              />
              <DetailList
                listTitle="인원 수"
                listSubTitle={`${reservation.numberOfUser}명`}
                className="BLUE FONT_WEIGHT_SEMI"
              />
            </DetailItem>
            <HorizonLineThick />
            <DetailItem title="요청 사항">
              <textarea
                readOnly={true}
                defaultValue={
                  reservation.requestMessage
                    ? reservation.requestMessage
                    : "요청사항 텍스트가 적혀있는 영역입니다."
                }
              />
            </DetailItem>
            <HorizonLineThick />
            <DetailItem title="사용 포인트">
              <DetailList listTitle="사용 포인트" listSubTitle={`${reservation.usedPoint} p`} />
            </DetailItem>
            <HorizonLineThick />
            <DetailItem title="결제 금액">
              <DetailList
                listTitle="결제 수단"
                listSubTitle={reservation.paymentMethod}
                className="FONT_WEIGHT_SEMI"
              />
              <DetailList
                listTitle="결제 금액"
                listSubTitle={`${Number(reservation.paymentAmount).toLocaleString("USD")} 원`}
                className="FONT_SIZE_16"
              />
            </DetailItem>
            <HorizonLineThick />
            <DetailItem title="총 적립 포인트">
              <DetailList
                listTitle="기존 포인트"
                listSubTitle={`${Number(reservation.prevPoint).toLocaleString("USD")} p`}
              />
              <DetailList
                listTitle="결제 시 적립 예정 포인트"
                listSubTitle={`${Number(reservation.earnPoint).toLocaleString("USD")} p`}
              />
              <DetailList
                listTitle="결제 시 사용 포인트"
                listSubTitle={`-${Number(reservation.usedPoint).toLocaleString("USD")} p`}
                className="ERROR"
              />
              <DetailList
                listTitle="총 적립 예정 포인트"
                listSubTitle={`${(
                  Number(reservation.prevPoint) +
                  Number(reservation.earnPoint) -
                  Number(reservation.usedPoint)
                ).toLocaleString("USD")} p`}
                className="FONT_SIZE_16 FONT_WEIGHT_SEMI BLUE"
              />
            </DetailItem>
            <HorizonLineThick />

            <div className="detail-button-box">
              <CustomBtn
                text="예약 취소"
                buttonGrade="primary"
                addClass="btn--white-bg"
                onClick={() => setOpenModal(true)}
                disabled={new Date() >= new Date(reservation.reservationDatetime)}
              />
            </div>
            {adBanner && (
              <BannerLInk
                bannerUrl={adBanner.url}
                bannerImage={`${process.env.REACT_APP_S3_BUCKET_URL}${adBanner.filePath}`}
                imgAlt="advertisement-banner-img"
              />
            )}
          </section>
        )}
      </Layout>
    </>
  );
}
