/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

import Layout from "Layout";
import Header from "Layout/Header";
import Back from "Layout/Header/Back";
import CenterTitle from "Layout/Header/CenterTitle";
import InputWrap from "components/common/InputWrap";
import CustomBtn from "components/common/CustomBtn";
import CustomModal from "components/common/CustomModal";
import ModalHeading from "components/common/CustomModal/ModalHeading";
import HorizonLineThin from "components/common/HorizonLineThin";
import ModalText from "components/common/CustomModal/ModalText";
import ModalButtonBox from "components/common/CustomModal/ModalButtonBox";
import DynamicInput from "components/common/InputWrap/DynamicInput";
import { passwordValidation } from "utils/commonFunction";
import { linkUrl, ERROR_TEXT, SYSTEM_TEXT } from "constants";
import { changePassword } from "api/auth";

export default function PasswordSettingPage() {
  const navigate = useNavigate();
  const location = useLocation();
  // ref
  const passwordRef = useRef(null);
  const rePasswordRef = useRef(null);

  // state
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState({
    value: "",
    invalid: false,
  });
  const [rePassword, setRePassword] = useState({
    value: "",
    invalid: false,
  });
  const [errorText, setErrorText] = useState("");

  const handleChangePassword = (e) => {
    setPassword({ ...password, value: e.target.value, invalid: false });
  };
  const handleChangeRePassword = (e) => {
    setRePassword({ ...rePassword, value: e.target.value, invalid: false });
  };

  // onClick handle
  const handleClickNewPassword = async () => {
    initializeStateValid();
    // 비밀번호 유효성 체크
    if (passwordValidation(password.value)) {
      if (passwordRef) {
        passwordRef.current.focus();
      }
      setPassword({ ...password, invalid: true });
      return;
    }
    // 비밀번호 두 개 동인한 지 확인
    if (password.value !== rePassword.value) {
      if (rePasswordRef) {
        rePasswordRef.current.focus();
      }
      setRePassword({ ...rePassword, invalid: true });
      return;
    }
    const requestBody = {
      email,
      phone: location.state.phone,
      password: password.value,
      userName: location.state.name,
    };
    const { success, data, error } = await changePassword(requestBody);
    if (!success) {
      if (error.errCode === "1014") {
        setErrorText(ERROR_TEXT.cannotChangePasswordForSocial);
        setOpenModal(true);
        return;
      }
    }
    setErrorText(SYSTEM_TEXT.changePasswordComplete);
    setOpenModal(true);
  };
  // function
  const initializeStateValid = () => {
    setPassword({ ...password, invalid: false });
    setRePassword({ ...rePassword, invalid: false });
  };

  // useEffect
  useEffect(() => {
    if (!location.state || !location.state.email) {
      return;
    }
    setEmail(location.state.email);
  }, []);

  return (
    <>
      <Header BottomNavigation>
        <Back />
        <CenterTitle title="비밀번호 재설정" />
      </Header>
      <Layout>
        <CustomModal isOpen={openModal}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text={errorText} />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn
              text="확인"
              buttonGrade="tertiary"
              onClick={() => {
                navigate(linkUrl.signInGuide);
                setOpenModal(false);
              }}
            />
          </ModalButtonBox>
        </CustomModal>
        <section className="find-email-section">
          <form>
            <fieldset>
              <legend>비밀번호 재설정</legend>
              <InputWrap>
                <DynamicInput
                  required
                  labelText="비밀번호"
                  inputType="password"
                  id="password-check-confirm"
                  placeholder="8~15자 · 영문 대소문자, 숫자, 특수문자를 조합하세요."
                  guideText="비밀번호가 동일하지 않습니다."
                  invalid={password.invalid}
                  onChange={handleChangePassword}
                  value={password.value}
                  inputRef={passwordRef}
                />
                <DynamicInput
                  required
                  labelText="비밀번호 확인"
                  inputType="password"
                  id="password-check-confirm"
                  placeholder="위 비밀번호와 동일하게 입력하세요."
                  guideText="비밀번호가 동일하지 않습니다."
                  invalid={rePassword.invalid}
                  onChange={handleChangeRePassword}
                  value={rePassword.value}
                  inputRef={rePasswordRef}
                />
              </InputWrap>
            </fieldset>
          </form>
        </section>
        <div className="bottom-button">
          <CustomBtn text="확인" buttonGrade="primary" onClick={() => handleClickNewPassword()} />
        </div>
      </Layout>
    </>
  );
}
