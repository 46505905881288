import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useSetRecoilState } from "recoil";
import { loadingState } from "atom";

import Layout from "Layout";
import Header from "Layout/Header";
import Back from "Layout/Header/Back";
import CenterTitle from "Layout/Header/CenterTitle";
import { SVG, ERROR_TEXT } from "constants";
import ScrollContainer from "react-indiana-drag-scroll";
import CustomBtn from "components/common/CustomBtn";
import CaddieSearchItem from "components/CaddieSearchItem";
import CustomModal from "components/common/CustomModal";
import ModalLocation from "components/common/CustomModal/ModalLocation";
import { fetchUserPoints } from "api/point";
import ModalHeading from "components/common/CustomModal/ModalHeading";
import HorizonLineThin from "components/common/HorizonLineThin";
import ModalText from "components/common/CustomModal/ModalText";
import ModalButtonBox from "components/common/CustomModal/ModalButtonBox";
import ReservationCaddiePagination from "components/common/ReservationCaddiePagination";
import { fetchCaddieReservationList } from "api/attendance";
import { MoonLoader } from "react-spinners";
import { fetchUserInfo } from "api/user";
import { fetchCaddieLocation, saveCaddieLocation } from "api/address";

const BIG_CITIES = [
  "서울특별시",
  "경기도",
  "인천광역시",
  "부산광역시",
  "대구광역시",
  "대전광역시",
  "광주광역시",
  "울산광역시",
  "강원도",
  "경상남도",
  "경상북도",
  "충청북도",
  "충청남도",
  "전라남도",
  "전라북도",
  "제주특별자치도",
];

export default function ReservationCaddiePage({ adminType }) {
  const navigate = useNavigate();
  // recoil
  const setIsLoading = useSetRecoilState(loadingState);
  // state
  const [openModal, setOpenModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [alreadyApply, setAlreadyApply] = useState(false);
  const [cashPoint, setCashPoint] = useState(0);

  const [reservationList, setReservationList] = useState([]);
  const [isCaddie, setIsCaddie] = useState(true);
  const [isSCaddie, setIsSCaddie] = useState(false);
  const [isDisabledCaddie, setIsDisabledCaddie] = useState(true);
  const [orderType, setOrderType] = useState("CREATED_DATE");
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [listLoading, setListLoading] = useState(false);

  const [locationList, setLocationList] = useState([]);

  const handleClickLocationItem = async (idx) => {
    try {
      const { success } = await saveCaddieLocation([
        ...locationList.slice(0, idx),
        ...locationList.slice(idx + 1, locationList.length),
      ]);
      if (success) {
        setLocationList([
          ...locationList.slice(0, idx),
          ...locationList.slice(idx + 1, locationList.length),
        ]);
      }
    } catch (error) {}
  };

  const handleChangeOrderType = (e) => {
    setOrderType(e.target.value);
  };

  const getCaddiePoint = async () => {
    setIsLoading(true);
    try {
      const { data: userData } = await fetchUserInfo();
      if (userData.caddie.grade === "ROUND_S_CADDIE") {
        setIsDisabledCaddie(false);
      }
      const { success, data } = await fetchUserPoints();
      if (success) {
        setCashPoint(Number(data));
      }
    } catch (error) {
      if (error.response.status === 500) {
        setErrorText(ERROR_TEXT.failedToRequest);
        setErrorModal(true);
        return;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getCaddieLocation = async () => {
    try {
      const { data } = await fetchCaddieLocation();
      if (data.length > 0) {
        const tempList = data.map(function (loc) {
          if (BIG_CITIES.includes(loc.location)) {
            return [loc.location, "전체"];
          } else {
            const locations = String(loc.location).split(" ");
            return [locations[0], locations.slice(1, locations.length).join(" ")];
          }
        });
        setLocationList(tempList);
      } else {
        setLocationList([]);
      }
    } catch (error) {}
  };

  const getCaddieReservationList = async () => {
    setListLoading(true);
    setReservationList([]);
    if (!isSCaddie && !isCaddie) {
      setTotalPage(1);
      setListLoading(false);
      return;
    }
    const caddieTypeEnum =
      isCaddie ^ isSCaddie ? (isCaddie ? "S_CADDIE" : "ROUND_S_CADDIE") : "NONE";
    try {
      const { success, data } = await fetchCaddieReservationList(caddieTypeEnum, page, orderType);
      if (success) {
        setReservationList(data.attendanceList);
        setTotalPage(data.pagingResponse.totalPage > 0 ? data.pagingResponse.totalPage : 1);
        // const tempList = data.storedLocation.map(function (item) {
        //   return item.location;
        // });
        // if (JSON.stringify(locationList) !== JSON.stringify(tempList)) {
        //   setLocationList(tempList);
        // }
      }
    } catch (err) {
    } finally {
      setListLoading(false);
    }
  };

  useEffect(() => {
    getCaddiePoint();
    getCaddieLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPage(1);
    getCaddieReservationList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCaddie, isSCaddie, orderType, locationList]);

  useEffect(() => {
    getCaddieReservationList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <>
      <Header reservation>
        <Back />
        <CenterTitle title="예약" />
      </Header>
      <Layout mainClassName="PADDING_TOP_ZERO ">
        <CustomModal isOpen={errorModal}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text={errorText} />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn text="확인" buttonGrade="tertiary" onClick={() => navigate(-1)} />
          </ModalButtonBox>
        </CustomModal>
        <CustomModal isOpen={alreadyApply}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text="이미 출근 신청한 예약입니다." />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn text="확인" buttonGrade="tertiary" onClick={() => setAlreadyApply(false)} />
          </ModalButtonBox>
        </CustomModal>
        <CustomModal isOpen={openModal} handleClose={() => setOpenModal(false)}>
          <ModalLocation
            onClose={() => setOpenModal(false)}
            locationList={locationList}
            setLocationList={setLocationList}
          />
        </CustomModal>

        <section className="reservation-search-section">
          <div className="reservation-search-box">
            <div className="reservation-search__item">
              <div className="reservation-search__item--title">
                <p>보유중인 캐시</p>
                <div>
                  <span className="reservation-search__item--subtitle">
                    {Number(cashPoint).toLocaleString("USD")}C
                  </span>
                </div>
              </div>
            </div>

            <div className="reservation-search__item">
              <div className="reservation-search__item--title">
                <p>지역 선택</p>
                <div>
                  <button type="button" onClick={() => setOpenModal(true)}>
                    <img src={SVG.ROUND_SETTING_SVG} alt="setting img" />
                  </button>
                </div>
              </div>

              <div className="reservation-search__item--contents">
                <ScrollContainer className="scroll-container">
                  {locationList.map((location, index) => {
                    return (
                      <div key={`location-${location[0]}-${location[1]}`} className="scroll-item">
                        <div className="local-preview">
                          <span className="local-preview__title">{`${location[0]} ${location[1]}`}</span>
                          <button
                            type="button"
                            className="local-preview__close-button"
                            onClick={() => {
                              handleClickLocationItem(index);
                            }}
                          >
                            <img src={SVG.CLOSE_WHITE_SVG} alt="close img" />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </ScrollContainer>
              </div>
            </div>

            <div className="reservation-search__item">
              <div className="reservation-search__item--title">
                <p>캐디 유형</p>
              </div>

              <div className="reservation-search__item--contents">
                <div className="caddie-type">
                  <CustomBtn
                    buttonType="primary"
                    text="S 캐디"
                    addClass={!isCaddie && "btn--white-bg"}
                    onClick={() => setIsCaddie((val) => !val)}
                  />
                  {/* <CustomBtn buttonType="primary" text="S 캐디" /> */}
                  {/* <CustomBtn buttonType="primary" text="S 캐디" disabled /> */}
                  <CustomBtn
                    buttonType="primary"
                    text="라운드 S 캐디"
                    addClass={!isSCaddie && "btn--white-bg"}
                    onClick={() => setIsSCaddie((val) => !val)}
                    disabled={isDisabledCaddie}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="reservation-result-list">
            {listLoading && (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <MoonLoader color="#C63DEE" height={15} width={5} radius={2} />
              </div>
            )}
            {!listLoading && (
              <>
                <div className="select-wrap">
                  <div className="select-wrap__select MARGIN_LEFT_AUTO">
                    <select
                      name="filter"
                      id="filter"
                      onChange={handleChangeOrderType}
                      value={orderType}
                    >
                      <option value="CREATED_DATE">접수순</option>
                      <option value="RESERVATION_DATETIME">날짜순</option>
                      <option value="PRICE">높은 가격순</option>
                    </select>
                  </div>
                </div>
                <ol className="card-list">
                  {reservationList &&
                    reservationList.map((reservation) => {
                      return (
                        <CaddieSearchItem
                          key={`reservation-${reservation.id}`}
                          reservation={reservation}
                          setAlreadyApply={setAlreadyApply}
                        />
                      );
                    })}
                  {/* <CaddieSearchItem />
                  <CaddieSearchItem /> */}
                </ol>
              </>
            )}
          </div>

          {!listLoading && (
            <ReservationCaddiePagination
              activePage={page}
              totalPage={totalPage}
              setPage={setPage}
            />
          )}
          {/* <CustomPagination /> */}
        </section>
      </Layout>
    </>
  );
}
