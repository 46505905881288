import React, { useState, useRef, useEffect } from "react";
import { userState, loadingState } from "atom";
import { useNavigate } from "react-router";
import { useRecoilState, useSetRecoilState } from "recoil";

import Layout from "Layout";
import Header from "Layout/Header";
import CenterTitle from "Layout/Header/CenterTitle";
import CustomLink from "components/common/CustomLink";
import Back from "Layout/Header/Back";
import CustomBtn from "components/common/CustomBtn";
import HorizonLineThin from "components/common/HorizonLineThin";
import InputWrap from "components/common/InputWrap";
import DynamicInput from "components/common/InputWrap/DynamicInput";
import CustomModal from "components/common/CustomModal";
import ModalHeading from "components/common/CustomModal/ModalHeading";
import ModalText from "components/common/CustomModal/ModalText";
import ModalButtonBox from "components/common/CustomModal/ModalButtonBox";
import useTimer from "hook/useTimer";
import { SEND_VERIFICATION_TIME, OTP_GUIDE_TEXT, ERROR_TEXT } from "constants";
import { passwordValidation, phoneNumberValidation } from "utils/commonFunction";
import { certificationSmsCode, sendSms } from "api/sms";
import { fetchUserInfo, updateUserInfo } from "api/user";

export default function MyInfoEditPage({ adminType }) {
  const navigate = useNavigate();
  // recoil
  const setIsLoading = useSetRecoilState(loadingState);
  const [userInfo, setUserInfo] = useRecoilState(userState);

  // ref
  const passwordRef = useRef(null);
  const rePasswordRef = useRef(null);
  const nameRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const otpRef = useRef(null);
  // state
  const [openModal, setOpenModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [password, setPassword] = useState({
    value: "",
    invalid: false,
  });
  const [rePassword, setRePassword] = useState({
    value: "",
    invalid: false,
  });
  const [name, setName] = useState({
    value: "",
    invalid: false,
  });
  const [phoneNumber, setPhoneNumber] = useState({
    value: "",
    invalid: false,
    isChecked: true,
    btnDisabled: false,
  });
  const [otpNumber, setOtpNumber] = useState({
    value: "",
    invalid: false,
    valid: false,
    btnDisabled: false,
  });
  const [otpGuideText, setOtpGuideText] = useState("");
  const [phoneChange, setPhoneChange] = useState(false);
  const [isSend, setIsSend] = useState(false);

  const getUserInfo = async () => {
    setIsLoading(true);
    try {
      const { data } = await fetchUserInfo();
      setUserInfo({
        email: data.user.email,
        username: data.user.username,
        role: data.user.roles[0].name,
        social: data.user.social?.socialType ?? "normal",
        phone: data.user.phone ?? "",
      });
      setName({ ...name, value: data.user.username });
      setPhoneNumber({ ...phoneNumber, value: data.user.phone });
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const saveUserInfo = async () => {
    setIsLoading(true);
    try {
      const { success } = await updateUserInfo(name.value, phoneNumber.value, password.value);
      if (success) {
        setOpenModal(true);
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  // timer
  const [currentMinutes, setCurrentMinutes] = useState(0);
  const [currentSeconds, setCurrentSeconds] = useState(0);
  const { count, start, stop, reset } = useTimer(SEND_VERIFICATION_TIME, 1000);

  const timer = () => {
    const checkMinutes = Math.floor(count / 60);
    const minutes = checkMinutes % 60;
    const seconds = count % 60;
    setCurrentMinutes(minutes);
    setCurrentSeconds(seconds);
  };

  // onChange input handler
  const handleChangePassword = (e) => {
    setPassword({ ...password, value: e.target.value, invalid: false });
  };
  const handleChangeRePassword = (e) => {
    setRePassword({ ...rePassword, value: e.target.value, invalid: false });
  };
  const handleChangeName = (e) => {
    setName({ ...name, value: e.target.value, invalid: false });
  };
  const handleChangePhoneNumber = (e) => {
    setPhoneNumber({
      ...phoneNumber,
      value: e.target.value,
      invalid: false,
      isChecked: false,
      btnDisabled: false,
    });
    setOtpNumber({ ...otpNumber, btnDisabled: true });
  };
  const handleChangeOtpNumber = (e) => {
    setOtpNumber({ ...otpNumber, value: e.target.value, btnDisabled: false, invalid: false });
  };

  // onClick handler
  const handleModalClose = () => {
    navigate(-1);
    setOpenModal(false);
  };
  const handleClickSendOtp = async () => {
    setPhoneNumber({ ...phoneNumber, btnDisabled: true });
    if (phoneNumberValidation(phoneNumber.value)) {
      setPhoneNumber({ ...phoneNumber, invalid: true, btnDisabled: false });
      return;
    }

    try {
      setIsLoading(true);
      // TODO::parameter 수정
      await sendSms("CHANGE_USER_INFO", phoneNumber.value);
      // const data = await sendSms("SIGN_UP", phoneNumber.value);
      reset();

      if (!isSend) {
        setIsSend(true);
      }

      start();
      setPhoneNumber({ ...phoneNumber, isChecked: true, btnDisabled: true });
      setOtpNumber({ ...otpNumber, invalid: false, btnDisabled: false });
    } catch (err) {
      setErrorText(ERROR_TEXT.failedToSendSms);
      setOpenModal(true);
      setPhoneNumber({ ...phoneNumber, btnDisabled: false });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickReSendOtp = async () => {
    try {
      setIsLoading(true);
      // TODO::parameter 수정
      await sendSms("CHANGE_USER_INFO", phoneNumber.value);
      // const data = await sendSms("SIGN_UP", phoneNumber.value);
      reset();
      start();
      setOtpNumber({ ...otpNumber, invalid: false, btnDisabled: false });
    } catch (err) {
      setErrorText(ERROR_TEXT.failedToSendSms);
      setOpenModal(true);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickSaveButtonNotStore = async () => {
    initializeStateValid();
    // 이름 비었는지 확인
    if (!name.value) {
      if (nameRef) {
        nameRef.current.focus();
      }
      setName({ ...name, invalid: true });
      return;
    }
    // phoneNumber 확인
    if (!phoneNumber.isChecked) {
      if (phoneNumberRef) {
        phoneNumberRef.current.focus();
      }
      setPhoneNumber({ ...phoneNumber, invalid: true });
      return;
    }
    // timer 확인
    if (count <= 0) {
      setOtpGuideText(OTP_GUIDE_TEXT.timeOver);
      setOtpNumber({ ...otpNumber, invalid: true, valid: false });
      return;
    }
    // otp 확인
    if (isSend) {
      try {
        setIsLoading(true);
        const { success, data } = await certificationSmsCode(
          otpNumber.value,
          "CHANGE_USER_INFO",
          phoneNumber.value,
        );
        if (!success || !data) {
          if (otpRef) {
            otpRef.current.focus();
          }
          setOtpGuideText(OTP_GUIDE_TEXT.wrong);
          setOtpNumber({ ...otpNumber, invalid: true });
          setIsLoading(false);
          return;
        }
        setOtpNumber({ ...otpNumber, valid: true });
      } catch (err) {}
    }
    // 비밀번호 유효성 체크
    if (userInfo.social === "normal") {
      if (password.value.length > 0 || rePassword.value.length > 0) {
        if (passwordValidation(password.value)) {
          if (passwordRef) {
            passwordRef.current.focus();
          }
          setPassword({ ...password, invalid: true });
          return;
        }
        // 비밀번호 두 개 동인한 지 확인
        if (password.value !== rePassword.value) {
          if (rePasswordRef) {
            rePasswordRef.current.focus();
          }
          setRePassword({ ...rePassword, invalid: true });
          return;
        }
      }
    }

    saveUserInfo();
  };
  const handleClickSaveButtonStore = async () => {
    initializeStateValid();
    // 비밀번호 유효성 체크
    if (userInfo.social === "normal") {
      if (passwordValidation(password.value)) {
        if (passwordRef) {
          passwordRef.current.focus();
        }
        setPassword({ ...password, invalid: true });
        return;
      }
      // 비밀번호 두 개 동인한 지 확인
      if (password.value !== rePassword.value) {
        if (rePasswordRef) {
          rePasswordRef.current.focus();
        }
        setRePassword({ ...rePassword, invalid: true });
        return;
      }
    }
    saveUserInfo();
  };

  // function
  const initializeStateValid = () => {
    setPassword({ ...password, invalid: false });
    setRePassword({ ...rePassword, invalid: false });
    setName({ ...name, invalid: false });
    setPhoneNumber({ ...phoneNumber, invalid: false });
    setOtpNumber({ ...otpNumber, invalid: false, valid: false });
  };

  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    timer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  useEffect(() => {
    if (count <= 0) {
      stop();
    }
  }, [count, stop]);

  return (
    <>
      <Header BottomNavigation>
        <Back />
        <CenterTitle title="개인정보 수정" />
      </Header>
      <Layout>
        <CustomModal isOpen={openModal}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text="저장 되었습니다." />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn text="확인" buttonGrade="tertiary" onClick={handleModalClose} />
          </ModalButtonBox>
        </CustomModal>
        <CustomModal isOpen={errorModal} handleClose={() => setErrorModal(false)}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text={errorText} />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn text="확인" buttonGrade="tertiary" onClick={() => setErrorModal(false)} />
          </ModalButtonBox>
        </CustomModal>
        {userInfo && (
          <>
            <section className="terms-of-use-section">
              <form>
                <fieldset>
                  <legend>개인정보 수정 </legend>
                  {userInfo.role !== "ROLE_FRANCHISE" && (
                    <InputWrap>
                      <DynamicInput
                        required
                        labelText="이름"
                        id="name"
                        placeholder="이름을 입력하세요."
                        guideText="입력정보를 확인 해주세요."
                        invalid={name.invalid}
                        onChange={handleChangeName}
                        value={name.value}
                        inputRef={nameRef}
                      />
                      <DynamicInput
                        required
                        labelText="휴대폰 번호"
                        id="phone"
                        placeholder="휴대폰 번호를 입력하세요."
                        guideText="휴대폰 번호를 확인해주세요."
                        invalid={phoneNumber.invalid}
                        onChange={handleChangePhoneNumber}
                        value={phoneNumber.value}
                        inputRef={phoneNumberRef}
                        disabled={!phoneChange}
                      >
                        {!phoneChange && (
                          <CustomBtn
                            text="변경"
                            buttonGrade="primary"
                            onClick={() => setPhoneChange(true)}
                          />
                        )}
                        {phoneChange && (
                          <CustomBtn
                            text="인증"
                            buttonGrade="primary"
                            disabled={phoneNumber.isChecked || phoneNumber.btnDisabled}
                            onClick={() => handleClickSendOtp()}
                          />
                        )}
                      </DynamicInput>
                      {isSend && (
                        <>
                          <DynamicInput
                            required
                            id="code-confirm"
                            placeholder="인증번호를 입력하세요."
                            guideText={otpGuideText}
                            validText="인증번호가 확인되었습니다."
                            invalid={otpNumber.invalid}
                            valid={otpNumber.valid}
                            onChange={handleChangeOtpNumber}
                            value={otpNumber.value}
                            inputRef={otpRef}
                            maxLength={6}
                          >
                            <p className="time-check">
                              {String(currentMinutes).padStart(2, "0")}:
                              {String(currentSeconds).padStart(2, "0")}
                            </p>
                          </DynamicInput>
                          <div className="re-send-button-box">
                            <button
                              className="re-send-button"
                              type="button"
                              disabled={!phoneNumber.btnDisabled || otpNumber.btnDisabled}
                              onClick={() => handleClickReSendOtp()}
                            >
                              재전송
                            </button>
                          </div>
                        </>
                      )}
                      {userInfo.social === "normal" && (
                        <>
                          <DynamicInput
                            required
                            labelText="비밀번호"
                            inputType="password"
                            id="password-check"
                            placeholder="8~15자 · 영문 대소문자, 숫자, 특수문자를 조합하세요."
                            guideText="비밀번호가 일치하지 않습니다."
                            invalid={password.invalid}
                            onChange={handleChangePassword}
                            value={password.value}
                            inputRef={passwordRef}
                          />
                          <DynamicInput
                            required
                            labelText="비밀번호 확인"
                            inputType="password"
                            id="password-check-confirm"
                            placeholder="위 비밀번호와 동일하게 입력하세요."
                            guideText="비밀번호가 동일하지 않습니다."
                            invalid={rePassword.invalid}
                            onChange={handleChangeRePassword}
                            value={rePassword.value}
                            inputRef={rePasswordRef}
                          />
                        </>
                      )}
                    </InputWrap>
                  )}
                  {userInfo.role === "ROLE_FRANCHISE" && (
                    <InputWrap>
                      <DynamicInput
                        required
                        labelText="비밀번호"
                        inputType="password"
                        id="password-check"
                        placeholder="8~15자 · 영문 대소문자, 숫자, 특수문자를 조합하세요."
                        guideText="비밀번호가 일치하지 않습니다."
                        invalid={password.invalid}
                        onChange={handleChangePassword}
                        value={password.value}
                        inputRef={passwordRef}
                      />
                      <DynamicInput
                        required
                        labelText="비밀번호 확인"
                        inputType="password"
                        id="password-check-confirm"
                        placeholder="위 비밀번호와 동일하게 입력하세요."
                        guideText="비밀번호가 동일하지 않습니다."
                        invalid={rePassword.invalid}
                        onChange={handleChangeRePassword}
                        value={rePassword.value}
                        inputRef={rePasswordRef}
                      />
                    </InputWrap>
                  )}
                </fieldset>
              </form>
            </section>
            <div className="detail-button-box multiple-btn FULL_SIDES">
              <CustomBtn
                text="저장"
                buttonGrade="secondary"
                onClick={
                  userInfo.role === "ROLE_FRANCHISE"
                    ? () => handleClickSaveButtonStore()
                    : () => handleClickSaveButtonNotStore()
                }
              />
              <CustomLink
                href={`/my-page/${adminType}`}
                linkGrade="secondary"
                text="취소"
                addClass="btn--white-bg"
              />
            </div>
          </>
        )}
      </Layout>
    </>
  );
}
