import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useSetRecoilState } from "recoil";

import Layout from "Layout";
import Header from "Layout/Header";
import Back from "Layout/Header/Back";
import CenterTitle from "Layout/Header/CenterTitle";
import InputWrap from "components/common/InputWrap";
import CustomBtn from "components/common/CustomBtn";
import HorizonLineThin from "components/common/HorizonLineThin";
import DynamicInput from "components/common/InputWrap/DynamicInput";
import CustomModal from "components/common/CustomModal";
import ModalHeading from "components/common/CustomModal/ModalHeading";
import ModalText from "components/common/CustomModal/ModalText";
import ModalButtonBox from "components/common/CustomModal/ModalButtonBox";
import useTimer from "hook/useTimer";
import { SEND_VERIFICATION_TIME } from "constants";
import { phoneNumberValidation } from "utils/commonFunction";
import { OTP_GUIDE_TEXT } from "constants";
import { certificationSmsCode, sendSms } from "api/sms";
import { ERROR_TEXT } from "constants";
import { loadingState, userState } from "atom";
import { signInSocial, signUpSocial } from "api/auth";

export default function SnsSignUpPage() {
  const navigate = useNavigate();
  const socialUserInfo = sessionStorage.getItem("socialSignUpInfo")
    ? JSON.parse(sessionStorage.getItem("socialSignUpInfo"))
    : null;
  // recoil
  const setIsLoading = useSetRecoilState(loadingState);
  const setUserInfo = useSetRecoilState(userState);
  // ref
  const nameRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const otpRef = useRef(null);

  // state
  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState({
    value: "",
    invalid: false,
  });
  const [phoneNumber, setPhoneNumber] = useState({
    value: "",
    invalid: false,
    isChecked: false,
    btnDisabled: false,
  });
  const [otpNumber, setOtpNumber] = useState({
    value: "",
    invalid: false,
    valid: false,
    btnDisabled: false,
  });
  const [otpGuideText, setOtpGuideText] = useState("");
  const [errorText, setErrorText] = useState("");
  const [isSend, setIsSend] = useState(false);

  // timer
  const [currentMinutes, setCurrentMinutes] = useState(0);
  const [currentSeconds, setCurrentSeconds] = useState(0);
  const { count, start, stop, reset } = useTimer(SEND_VERIFICATION_TIME, 1000);

  const timer = () => {
    const checkMinutes = Math.floor(count / 60);
    const minutes = checkMinutes % 60;
    const seconds = count % 60;
    setCurrentMinutes(minutes);
    setCurrentSeconds(seconds);
  };

  const signUp = async (userId, erole) => {
    // social sign in
    try {
      const { success, data } = await signInSocial(userId, erole);
      if (success && data.accessToken) {
        localStorage.setItem("accessToken", data.accessToken);
        localStorage.setItem("refreshToken", data.refreshToken);
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: "setStorage",
              value: { type: "refreshToken", value: data.refreshToken },
            }),
          );
        }
        setUserInfo({
          email: data.email,
          username: data.username,
          role: data.roles[0],
          social: data.socialType ?? "normal",
          phone: data.phone ?? "",
          point: data.point,
        });
        navigate("/sign-up-complete");
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  // onChange handler
  const handleChangeName = (e) => {
    setName({ ...name, value: e.target.value, invalid: false });
  };
  const handleChangePhoneNumber = (e) => {
    setPhoneNumber({
      ...phoneNumber,
      value: e.target.value,
      invalid: false,
      isChecked: false,
      btnDisabled: false,
    });
    setOtpNumber({ ...otpNumber, btnDisabled: true });
  };
  const handleChangeOtpNumber = (e) => {
    setOtpNumber({ ...otpNumber, value: e.target.value, btnDisabled: false, invalid: false });
  };

  // onClick handler
  const handleClickSendOtp = async () => {
    setPhoneNumber({ ...phoneNumber, btnDisabled: true });
    if (phoneNumberValidation(phoneNumber.value)) {
      setPhoneNumber({ ...phoneNumber, invalid: true, btnDisabled: false });
      return;
    }
    try {
      setIsLoading(true);
      await sendSms("SIGN_UP", phoneNumber.value);
      // const data = await sendSms("SIGN_UP", phoneNumber.value);
      reset();
      if (!isSend) {
        setIsSend(true);
      }
      start();
      setPhoneNumber({ ...phoneNumber, isChecked: true, btnDisabled: true });
      setOtpNumber({ ...otpNumber, invalid: false, btnDisabled: false });
    } catch (err) {
      setErrorText(ERROR_TEXT.failedToSendSms);
      setOpenModal(true);
      setPhoneNumber({ ...phoneNumber, btnDisabled: false });
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickReSendOtp = async () => {
    try {
      setIsLoading(true);
      await sendSms("SIGN_UP", phoneNumber.value);
      // const data = await sendSms("SIGN_UP", phoneNumber.value);
      reset();
      start();
      setOtpNumber({ ...otpNumber, invalid: false, btnDisabled: false });
    } catch (err) {
      setErrorText(ERROR_TEXT.failedToSendSms);
      setOpenModal(true);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickSignUp = async () => {
    initializeStateValid();
    // 이름 비었는지 확인
    if (!name.value) {
      if (nameRef) {
        nameRef.current.focus();
      }
      setName({ ...name, invalid: true });
      return;
    }
    // phoneNumber 확인
    if (!phoneNumber.isChecked) {
      if (phoneNumberRef) {
        phoneNumberRef.current.focus();
      }
      setPhoneNumber({ ...phoneNumber, invalid: true });
      return;
    }
    // timer 확인
    if (count <= 0) {
      setOtpGuideText(OTP_GUIDE_TEXT.timeOver);
      setOtpNumber({ ...otpNumber, invalid: true, valid: false });
      return;
    }
    // otp check
    try {
      setIsLoading(true);
      const { success, data } = await certificationSmsCode(
        otpNumber.value,
        "SIGN_UP",
        phoneNumber.value,
      );
      if (!success || !data) {
        if (otpRef) {
          otpRef.current.focus();
        }
        setOtpGuideText(OTP_GUIDE_TEXT.wrong);
        setOtpNumber({ ...otpNumber, invalid: true });
        setIsLoading(false);
        return;
      }
      setOtpNumber({ ...otpNumber, valid: true });
    } catch (err) {}
    // sign up api
    try {
      setIsLoading(true);
      const { success, data, error } = await signUpSocial({
        email: socialUserInfo.email,
        name: name.value,
        refreshToken: socialUserInfo.refreshToken,
        role: [
          socialUserInfo.authType === "user"
            ? "ROLE_USER"
            : socialUserInfo.authType === "caddie"
            ? "ROLE_CADDIE"
            : "ROLE_FRANCHISE",
        ],
        socialId: socialUserInfo.oauthId,
        socialType: socialUserInfo.socialType,
        phone: phoneNumber.value,
      });
      if (!success) {
        if (error.errCode === "1013") {
          setErrorText(ERROR_TEXT.alreadySignUp);
          setOpenModal(true);
        }
        if (error.errCode === "4019") {
          setErrorText(ERROR_TEXT.alreadyPhone);
          setOpenModal(true);
        }
        return;
      }
      sessionStorage.removeItem("socialSignUpInfo");
      signUp(data.id, data.roles[0].name);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const initializeStateValid = () => {
    setName({ ...name, invalid: false });
    setPhoneNumber({ ...phoneNumber, invalid: false });
    setOtpNumber({ ...otpNumber, invalid: false, valid: false });
  };

  // useEffect
  useEffect(() => {
    timer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  useEffect(() => {
    if (count <= 0) {
      stop();
    }
  }, [count, stop]);

  return (
    <>
      <Header BottomNavigation>
        <Back />
        <CenterTitle title="SNS 회원가입" />
      </Header>
      <Layout>
        <CustomModal isOpen={openModal} handleClose={() => setOpenModal(false)}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text={errorText} />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn text="확인" buttonGrade="tertiary" onClick={() => setOpenModal(false)} />
          </ModalButtonBox>
        </CustomModal>
        <section className="terms-of-use-section">
          <form id="sign-up">
            <fieldset>
              <legend>이용약관</legend>
              <InputWrap>
                <DynamicInput
                  required
                  labelText="이름"
                  id="name"
                  placeholder="이름을 입력하세요."
                  guideText="입력정보를 확인 해주세요."
                  invalid={name.invalid}
                  onChange={handleChangeName}
                  value={name.value}
                  inputRef={nameRef}
                />
                <DynamicInput
                  required
                  labelText="휴대폰 번호"
                  id="phone"
                  placeholder="휴대폰 번호를 입력하세요."
                  guideText="휴대폰 번호를 확인해주세요."
                  invalid={phoneNumber.invalid}
                  onChange={handleChangePhoneNumber}
                  value={phoneNumber.value}
                  inputRef={phoneNumberRef}
                >
                  <CustomBtn
                    text="인증코드 발송"
                    buttonGrade="primary"
                    disabled={phoneNumber.isChecked || phoneNumber.btnDisabled}
                    onClick={() => handleClickSendOtp()}
                  />
                </DynamicInput>
                {isSend && (
                  <>
                    <DynamicInput
                      required
                      id="code-confirm"
                      placeholder="인증번호를 입력하세요."
                      guideText={otpGuideText}
                      validText="인증번호가 확인되었습니다."
                      invalid={otpNumber.invalid}
                      valid={otpNumber.valid}
                      onChange={handleChangeOtpNumber}
                      value={otpNumber.value}
                      inputRef={otpRef}
                      maxLength={6}
                    >
                      <p className="time-check">
                        {String(currentMinutes).padStart(2, "0")}:
                        {String(currentSeconds).padStart(2, "0")}
                      </p>
                    </DynamicInput>

                    <div className="re-send-button-box">
                      <button
                        className="re-send-button"
                        type="button"
                        disabled={!phoneNumber.btnDisabled || otpNumber.btnDisabled}
                        onClick={() => handleClickReSendOtp()}
                      >
                        재전송
                      </button>
                    </div>
                  </>
                )}
              </InputWrap>
            </fieldset>
          </form>
        </section>
        <div className="bottom-button">
          {/* <CustomBtn text="회원가입" buttonGrade="primary" onClick={() => setOpenModal(true)} /> */}
          <CustomBtn
            buttonType="submit"
            text="다음으로"
            buttonGrade="primary"
            onClick={() => handleClickSignUp()}
          />
          {/* <CustomBtn form="sign-up" buttonType="submit" text="회원가입" buttonGrade="primary" /> */}
        </div>
      </Layout>
    </>
  );
}
