import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { loadingState } from "atom";
import { useSetRecoilState } from "recoil";

import { SVG, ERROR_TEXT, RN_TYPE } from "constants";
import Layout from "Layout";
import Header from "Layout/Header";
import Back from "Layout/Header/Back";
import CenterTitle from "Layout/Header/CenterTitle";
import DetailItem from "components/common/DetailItem";
import DetailList from "components/common/DetailItem/DetailList";
import DetailIconList from "components/common/DetailItem/DetailIconList";
import HorizonLineThick from "components/common/HorizonLineThick";
import CustomModal from "components/common/CustomModal";
import ModalHeading from "components/common/CustomModal/ModalHeading";
import HorizonLineThin from "components/common/HorizonLineThin";
import ModalText from "components/common/CustomModal/ModalText";
import ModalButtonBox from "components/common/CustomModal/ModalButtonBox";
import CustomBtn from "components/common/CustomBtn";
import { deleteAttendance, fetchAttendanceDetail } from "api/attendance";
import moment from "moment";
import { getKoreanAmPm, getKoreanDayByDay } from "utils/commonFunction";

export default function ReservationReceptionPage({ adminType }) {
  const navigate = useNavigate();
  const location = useLocation();
  // recoil
  const setIsLoading = useSetRecoilState(loadingState);
  // state
  const [openModal, setOpenModal] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [faqModal, setFaqModal] = useState(false);
  const [attendance, setAttendance] = useState(null);

  const getAttendanceInfo = async (id) => {
    setIsLoading(true);
    try {
      const { data } = await fetchAttendanceDetail(id);
      setAttendance(data);
    } catch (error) {
      setErrorText(ERROR_TEXT.failedToRequest);
      setErrorModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickCancelButton = async () => {
    setIsLoading(true);
    try {
      const { data } = await deleteAttendance(location.state.reservationId);
      if (data) {
        setOpenModal(true);
      }
    } catch (error) {
      if (error.response.data.error.errCode === "4016") {
        setFaqModal(true);
        return;
      }
      setErrorText(ERROR_TEXT.failedToRequest);
      setErrorModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (location) {
      if (!location.state) {
        setErrorText(ERROR_TEXT.invalidAccess);
        setErrorModal(true);
        return;
      } else {
        getAttendanceInfo(location.state.reservationId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      <Header reservation BottomNavigation>
        <Back />
        <CenterTitle title="예약접수" />
      </Header>
      <Layout mainClassName="PADDING_TOP_ZERO PADDING_BOTTOM_ZERO FULL_SIDES">
        <CustomModal isOpen={openModal}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text="취소가 완료되었습니다" />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn
              text="확인"
              type="tertiary"
              onClick={() => {
                setOpenModal(false);
                navigate(-1);
              }}
            />
          </ModalButtonBox>
        </CustomModal>

        <CustomModal isOpen={errorModal}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text={errorText} />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn text="확인" type="tertiary" onClick={() => navigate(-1)} />
          </ModalButtonBox>
        </CustomModal>

        <CustomModal isOpen={faqModal}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text="해당 예약 취소는 관리자에게 문의해주세요." />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn text="확인" type="tertiary" onClick={() => setFaqModal(false)} />
          </ModalButtonBox>
        </CustomModal>

        {attendance && (
          <section className="detail-section">
            <DetailItem title="예약 일시">
              <DetailIconList
                imgSrc={SVG.CALENDAR_SVG}
                imgAlt="calendar img"
                subTitle={`${moment(new Date(attendance.reservation.reservationDatetime)).format(
                  "YYYY.MM.DD",
                )} (${getKoreanDayByDay(
                  moment(new Date(attendance.reservation.reservationDatetime)).day(),
                )})`}
                className="FONT_WEIGHT_SEMI FONT_SIZE_16"
              />
              <DetailIconList
                imgSrc={SVG.TIME_SVG}
                imgAlt="time img"
                subTitle={
                  attendance.reservation.reservationDatetime
                    ? `${getKoreanAmPm(
                        moment(new Date(attendance.reservation.reservationDatetime)).hour(),
                      )} 
                  ${moment(new Date(attendance.reservation.reservationDatetime)).format("HH:mm")}`
                    : ""
                }
                className="FONT_WEIGHT_SEMI FONT_SIZE_16"
              />
            </DetailItem>
            <HorizonLineThick />

            <DetailItem title="예약 지점">
              <a
                href={`tel:${attendance.reservation.franchise.phone}`}
                onClick={(e) => {
                  e.preventDefault();
                  window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                      type: RN_TYPE.CALL,
                      value: attendance.reservation.franchise.phone,
                    }),
                  );
                }}
              >
                {attendance.reservation.franchise.franchiseName}&nbsp;&nbsp;
                <img src={SVG.SERVICE} alt="phone img" />
              </a>
            </DetailItem>
            <HorizonLineThick />

            <DetailItem title="예약 정보">
              <DetailList
                listTitle="캐디 유형"
                listSubTitle={
                  attendance.reservation.caddieType.type === "S_CADDIE" ? "S캐디" : "라운드 S캐디"
                }
                className="FONT_WEIGHT_SEMI"
              />
              <DetailList
                listTitle="인원수"
                listSubTitle={`${attendance.reservation.numberOfUser}명`}
                className="FONT_WEIGHT_SEMI"
              />
            </DetailItem>
            <HorizonLineThick />
            <DetailItem title="요청 사항">
              <textarea
                readOnly
                defaultValue={
                  attendance.reservation.requestMessage
                    ? attendance.reservation.requestMessage
                    : "요청사항 텍스트가 적혀있는 영역입니다."
                }
              />
            </DetailItem>
            <HorizonLineThick />

            <DetailItem title="금액">
              <DetailList
                listTitle="금액"
                listSubTitle={`${Number(attendance.reservation.payments.amount).toLocaleString(
                  "USD",
                )} 원`}
              />
            </DetailItem>
            <HorizonLineThick />

            <DetailItem title="캐디 메뉴얼 및 유의사항" addClass="grey-bg">
              <dl className="dl-list">
                <dt className="dl-list__title">[S캐디 메뉴얼]</dt>
                <dd className="dl-list__content">
                  1. 게임시작 20분 전에 도착하여 미리 셋팅해 놓는다.
                </dd>
                <dd className="dl-list__content">2. S캐디 유니폼과 편안한 신발을 착용한다.</dd>
              </dl>

              <dl className="dl-list">
                <dt className="dl-list__title">[S캐디 유의사항]</dt>
                <dd className="dl-list__content">1. 시간엄수 : 20분 전 도착</dd>
                <dd className="dl-list__content">2. 복장 준수 : S캐디 유니폼 착용, 편안한 신발</dd>
                <dd className="dl-list__content">3. 안전사고 주의 : 골프채, 공 등</dd>
                <dd className="dl-list__content">4. 신체접촉 금지</dd>
              </dl>
            </DetailItem>
            <HorizonLineThick />
            <div className="detail-button-box">
              <CustomBtn
                text="출근 취소"
                buttonGrade="primary"
                addClass="btn--white-bg"
                onClick={handleClickCancelButton}
              />
            </div>
          </section>
        )}
      </Layout>
    </>
  );
}
