import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSetRecoilState } from "recoil";

import Layout from "Layout";
import Header from "Layout/Header";
import Back from "Layout/Header/Back";
import CenterTitle from "Layout/Header/CenterTitle";
import InputWrap from "components/common/InputWrap";
import CustomBtn from "components/common/CustomBtn";
import CustomModal from "components/common/CustomModal";
import ModalHeading from "components/common/CustomModal/ModalHeading";
import HorizonLineThin from "components/common/HorizonLineThin";
import ModalText from "components/common/CustomModal/ModalText";
import ModalButtonBox from "components/common/CustomModal/ModalButtonBox";
import DynamicInput from "components/common/InputWrap/DynamicInput";
import useTimer from "hook/useTimer";
import { useRef } from "react";
import { emailValidation, phoneNumberValidation } from "utils/commonFunction";
import { SEND_VERIFICATION_TIME, OTP_GUIDE_TEXT, ERROR_TEXT, linkUrl } from "constants";
import { certificationSmsCode, sendSms } from "api/sms";
import { getIsExistUserByEmailNameAndPhone } from "api/user";
import { loadingState } from "atom";

export default function FindPasswordPage() {
  const navigate = useNavigate();
  const location = useLocation();
  // recoil
  const setIsLoading = useSetRecoilState(loadingState);
  // ref
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const otpRef = useRef(null);

  // state
  const [openModal, setOpenModal] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isSend, setIsSend] = useState(false);
  const [name, setName] = useState({
    value: "",
    invalid: false,
  });
  const [email, setEmail] = useState({
    value: "",
    invalid: false,
  });
  const [phoneNumber, setPhoneNumber] = useState({
    value: "",
    invalid: false,
    isChecked: false,
    btnDisabled: false,
  });
  const [otpNumber, setOtpNumber] = useState({
    value: "",
    invalid: false,
    valid: false,
    btnDisabled: false,
  });
  const [otpGuideText, setOtpGuideText] = useState("");

  // timer
  const [currentMinutes, setCurrentMinutes] = useState(0);
  const [currentSeconds, setCurrentSeconds] = useState(0);
  const { count, start, stop, reset } = useTimer(SEND_VERIFICATION_TIME, 1000);

  const timer = () => {
    const checkMinutes = Math.floor(count / 60);
    const minutes = checkMinutes % 60;
    const seconds = count % 60;
    setCurrentMinutes(minutes);
    setCurrentSeconds(seconds);
  };

  // onChange input handler
  const handleChangeEmail = (e) => {
    setEmail({ ...email, value: e.target.value, invalid: false });
  };
  const handleChangeName = (e) => {
    setName({ ...name, value: e.target.value, invalid: false });
  };
  const handleChangePhoneNumber = (e) => {
    setPhoneNumber({
      ...phoneNumber,
      value: e.target.value,
      invalid: false,
      isChecked: false,
      btnDisabled: false,
    });
    setOtpNumber({ ...otpNumber, btnDisabled: true });
  };
  const handleChangeOtpNumber = (e) => {
    setOtpNumber({ ...otpNumber, value: e.target.value, btnDisabled: false, invalid: false });
  };

  // onClick handler
  const handleClickSendOtp = async () => {
    setPhoneNumber({ ...phoneNumber, btnDisabled: true });
    if (phoneNumberValidation(phoneNumber.value)) {
      setPhoneNumber({ ...phoneNumber, invalid: true, btnDisabled: false });
      return;
    }
    try {
      setIsLoading(true);
      await sendSms("FIND_PASSWORD", phoneNumber.value);
      // const data = await sendSms("FIND_ID", phoneNumber.value);
      reset();

      if (!isSend) {
        setIsSend(true);
      }

      start();
      setPhoneNumber({ ...phoneNumber, isChecked: true, btnDisabled: true });
      setOtpNumber({ ...otpNumber, invalid: false, btnDisabled: false });
    } catch (err) {
      setErrorText(ERROR_TEXT.failedToSendSms);
      setOpenModal(true);
      setPhoneNumber({ ...phoneNumber, btnDisabled: false });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickReSendOtp = async () => {
    try {
      setIsLoading(true);
      await sendSms("FIND_PASSWORD", phoneNumber.value);
      // const data = await sendSms("FIND_ID", phoneNumber.value);
      reset();
      start();
      setOtpNumber({ ...otpNumber, invalid: false, btnDisabled: false });
    } catch (err) {
      setErrorText(ERROR_TEXT.failedToSendSms);
      setOpenModal(true);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickFindPassword = async () => {
    initializeStateValid();
    // 이름 비었는지 확인
    if (!name.value) {
      if (nameRef) {
        nameRef.current.focus();
      }
      setName({ ...name, invalid: true });
      return;
    }
    if (emailValidation(email.value)) {
      if (emailRef) {
        emailRef.current.focus();
      }
      setEmail({ ...email, invalid: true });
      return;
    }
    // phoneNumber 확인
    if (!phoneNumber.isChecked) {
      if (phoneNumberRef) {
        phoneNumberRef.current.focus();
      }
      setPhoneNumber({ ...phoneNumber, invalid: true });
      return;
    }
    // timer 확인
    if (count <= 0) {
      setOtpGuideText(OTP_GUIDE_TEXT.timeOver);
      setOtpNumber({ ...otpNumber, invalid: true, valid: false });
      return;
    }
    // otp 확인
    try {
      setIsLoading(true);
      const { success, data } = await certificationSmsCode(
        otpNumber.value,
        "FIND_PASSWORD",
        phoneNumber.value,
      );
      if (!success || !data) {
        if (otpRef) {
          otpRef.current.focus();
        }
        setOtpGuideText(OTP_GUIDE_TEXT.wrong);
        setOtpNumber({ ...otpNumber, invalid: true });
        setIsLoading(false);
        return;
      }
      setOtpNumber({ ...otpNumber, valid: true });
    } catch (err) {
      setOtpGuideText(OTP_GUIDE_TEXT.wrong);
      setOtpNumber({ ...otpNumber, invalid: true });
      setIsLoading(false);
      return;
    }
    // next
    try {
      const { success, data } = await getIsExistUserByEmailNameAndPhone(
        otpNumber.value,
        email.value,
        phoneNumber.value,
        name.value,
        location.state.adminType === "user"
          ? "ROLE_USER"
          : location.state.adminType === "caddie"
          ? "ROLE_CADDIE"
          : "ROLE_FRANCHISE",
      );
      if (!success || !data) {
        setErrorText(ERROR_TEXT.notUser);
        setOpenModal(true);
        setIsLoading(false);
        return;
      }

      if (!data.id) {
        setErrorText(ERROR_TEXT.notUser);
        setOpenModal(true);
        setIsLoading(false);
        return;
      }
    } catch (err) {}
    setIsLoading(false);
    navigate(linkUrl.passwordSetting, {
      state: {
        email: email.value,
        phone: phoneNumber.value,
        name: name.value,
      },
    });
  };

  // function
  const initializeStateValid = () => {
    setEmail({ ...email, invalid: false });
    setName({ ...name, invalid: false });
    setPhoneNumber({ ...phoneNumber, invalid: false });
    setOtpNumber({ ...otpNumber, invalid: false, valid: false });
  };

  // useEffect
  useEffect(() => {
    timer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  useEffect(() => {
    if (count <= 0) {
      stop();
    }
  }, [count, stop]);

  return (
    <>
      <Header BottomNavigation>
        <Back />
        <CenterTitle title="비밀번호 찾기" />
      </Header>
      <Layout>
        <CustomModal isOpen={openModal} handleClose={() => setOpenModal(false)}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text={errorText} />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn text="확인" buttonGrade="tertiary" onClick={() => setOpenModal(false)} />
          </ModalButtonBox>
        </CustomModal>
        <section className="find-email-section">
          <form>
            <fieldset>
              <legend>비밀번호 찾기</legend>
              <InputWrap>
                <DynamicInput
                  required
                  labelText="이름"
                  id="name-check"
                  placeholder="이름을 입력하세요."
                  guideText="이름을 확인해주세요."
                  invalid={name.invalid}
                  onChange={handleChangeName}
                  value={name.value}
                  inputRef={nameRef}
                />
                <DynamicInput
                  required
                  labelText="이메일"
                  id="email-check"
                  placeholder="이메일을 입력하세요."
                  guideText="이메일을 확인해주세요."
                  invalid={email.invalid}
                  onChange={handleChangeEmail}
                  value={email.value}
                  inputRef={emailRef}
                />
                <DynamicInput
                  required
                  labelText="휴대폰 번호"
                  id="phone"
                  placeholder="휴대폰 번호를 입력하세요."
                  guideText="휴대폰 번호를 확인해주세요."
                  invalid={phoneNumber.invalid}
                  onChange={handleChangePhoneNumber}
                  value={phoneNumber.value}
                  inputRef={phoneNumberRef}
                >
                  <CustomBtn
                    text="인증코드 발송"
                    buttonGrade="primary"
                    disabled={phoneNumber.isChecked || phoneNumber.btnDisabled}
                    onClick={() => handleClickSendOtp()}
                  />
                </DynamicInput>
                {isSend && (
                  <>
                    <DynamicInput
                      required
                      id="code-confirm"
                      placeholder="인증번호를 입력하세요."
                      guideText={otpGuideText}
                      validText="인증번호가 확인되었습니다."
                      invalid={otpNumber.invalid}
                      valid={otpNumber.valid}
                      onChange={handleChangeOtpNumber}
                      value={otpNumber.value}
                      inputRef={otpRef}
                      maxLength={6}
                    >
                      <p className="time-check">
                        {String(currentMinutes).padStart(2, "0")}:
                        {String(currentSeconds).padStart(2, "0")}
                      </p>
                    </DynamicInput>

                    <div className="re-send-button-box">
                      <button
                        className="re-send-button"
                        type="button"
                        disabled={!phoneNumber.btnDisabled || otpNumber.btnDisabled}
                        onClick={() => handleClickReSendOtp()}
                      >
                        재전송
                      </button>
                    </div>
                  </>
                )}
              </InputWrap>
            </fieldset>
          </form>
        </section>
        <div className="bottom-button">
          {/* <CustomLink href="/password-setting" linkGrade="primary" text="확인" /> */}
          <CustomBtn text="확인" buttonGrade="primary" onClick={() => handleClickFindPassword()} />
        </div>
      </Layout>
    </>
  );
}
