import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSetRecoilState } from "recoil";
import { loadingState, userState } from "atom";
import { Link } from "react-router-dom";

import Layout from "Layout";
import Header from "Layout/Header";
import Back from "Layout/Header/Back";
import CenterTitle from "Layout/Header/CenterTitle";
import InputWrap from "components/common/InputWrap";
import CustomBtn from "components/common/CustomBtn";
import CustomModal from "components/common/CustomModal";
import ModalHeading from "components/common/CustomModal/ModalHeading";
import HorizonLineThin from "components/common/HorizonLineThin";
import ModalText from "components/common/CustomModal/ModalText";
import ModalButtonBox from "components/common/CustomModal/ModalButtonBox";
import DynamicInput from "components/common/InputWrap/DynamicInput";
import { linkUrl } from "constants";
import useSignIn from "hook/useSignin";
import windowReactNativeWebView from "utils/windowReactNativeWebView";
import { saveFireBaseToken } from "api/push";

export default function SignInPage({ adminType }) {
  const navigate = useNavigate();
  const location = useLocation();

  // recoil
  const setUserInfo = useSetRecoilState(userState);
  const setIsLoading = useSetRecoilState(loadingState);

  // state
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const signInMutation = useSignIn();

  // onChange handler
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  // onClick handle
  const handleClickNoUserModalConfirmButton = (e) => {
    e.preventDefault();
    setOpenModal(false);
  };

  // onSubmit handle
  const handleSubmitSignIn = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const authType =
        adminType === "user"
          ? "ROLE_USER"
          : adminType === "caddie"
            ? "ROLE_CADDIE"
            : "ROLE_FRANCHISE";
      const { data } = await signInMutation.mutateAsync({
        username: email,
        password,
        authType,
      });
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "setStorage",
            value: { type: "refreshToken", value: data.refreshToken },
          }),
        );
      }
      setUserInfo({
        email: data.email,
        username: data.username,
        role: data.roles[0],
        phone: data.phone,
        social: data.socialType ?? "normal",
        point: data.point,
      });
      if (windowReactNativeWebView) {
        const deviceId = localStorage.getItem("deviceId");
        const fcmToken = localStorage.getItem("fcmToken");
        if (deviceId && fcmToken) {
          await saveFireBaseToken(deviceId, fcmToken);
        }
      }
      navigate(linkUrl.main, { state: { adminType } });
    } catch (error) {
      setOpenModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header BottomNavigation>
        <Back />
        <CenterTitle title="일반 로그인" />
      </Header>
      <Layout>
        <CustomModal isOpen={openModal} handleClose={() => setOpenModal(false)}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text="일치하는 회원 정보가 없습니다." />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn
              text="확인"
              buttonGrade="tertiary"
              onClick={handleClickNoUserModalConfirmButton}
            />
          </ModalButtonBox>
        </CustomModal>
        <section className="sign-in-section">
          <form onSubmit={handleSubmitSignIn}>
            <fieldset>
              <legend>로그인</legend>
              <InputWrap>
                {adminType !== "store" && (
                  <DynamicInput
                    required
                    inputType="email"
                    labelText="이메일"
                    placeholder="이메일을 입력하세요."
                    value={email}
                    onChange={handleChangeEmail}
                  />
                )}
                {adminType === "store" && (
                  <DynamicInput
                    required
                    inputType="text"
                    labelText="아이디"
                    placeholder="아이디를 입력하세요."
                    value={email}
                    onChange={handleChangeEmail}
                  />
                )}
                <DynamicInput
                  inputType="password"
                  required
                  labelText="비밀번호"
                  placeholder="비밀번호를 입력하세요."
                  value={password}
                  onChange={handleChangePassword}
                />
              </InputWrap>
            </fieldset>
            <div className="sign-in-section__bottom">
              <CustomBtn buttonType="submit" buttonGrade="primary" text="로그인" />
            </div>
          </form>
          {adminType !== "store" && (
            <div className="link-box">
              <Link to={linkUrl.findEmail} state={{ adminType }}>
                이메일 찾기
              </Link>
              <span>|</span>
              <Link to={linkUrl.findPassword} state={{ adminType }}>
                비밀번호 찾기
              </Link>
            </div>
          )}
        </section>
      </Layout>
    </>
  );
}
