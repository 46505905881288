/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useRef, useState } from "react";

const useTimer = (initialValue, ms) => {
  const [count, setCount] = useState(initialValue);
  const timerRef = useRef(null);

  const start = useCallback(() => {
    if (timerRef.current !== null) return;
    timerRef.current = setInterval(() => {
      setCount((c) => c - 1);
    }, ms);
  }, []);

  const stop = useCallback(() => {
    if (timerRef.current === null) return;
    clearInterval(timerRef.current);
    timerRef.current = null;
  }, []);

  const reset = useCallback(() => {
    setCount(initialValue);
    stop();
  }, []);

  return { count, start, stop, reset };
};

export default useTimer;
