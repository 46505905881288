import { atom } from "recoil";

export const userState = atom({
  key: "user",
  default: null,
});

export const loadingState = atom({
  key: "loading",
  default: false,
});

export const isEducationState = atom({
  key: "isEducation",
  default: false,
});

export const isServerReqErrorState = atom({
  key: "isServerError",
  default: false,
});
