import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import moment from "moment";
import { useSetRecoilState } from "recoil";

import { SVG } from "constants";
import Layout from "Layout";
import Header from "Layout/Header";
import Back from "Layout/Header/Back";
import CenterTitle from "Layout/Header/CenterTitle";
import DetailItem from "components/common/DetailItem";
import DetailIconList from "components/common/DetailItem/DetailIconList";
import HorizonLineThick from "components/common/HorizonLineThick";
import CustomModal from "components/common/CustomModal";
import ModalHeading from "components/common/CustomModal/ModalHeading";
import HorizonLineThin from "components/common/HorizonLineThin";
import ModalText from "components/common/CustomModal/ModalText";
import ModalButtonBox from "components/common/CustomModal/ModalButtonBox";
import CustomBtn from "components/common/CustomBtn";
import { fetchEducationDetail, postApplicationEducation } from "api/education";
import { getKoreanAmPm, getKoreanDayByDay } from "utils/commonFunction";
import { loadingState } from "atom";

export default function MyOtherEducationListDetailPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  // recoil
  const setIsLoading = useSetRecoilState(loadingState);

  // state
  const [education, setEducation] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [applyModal, setApplyModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  // fetch Education Detail
  const getEducationDetail = async (id) => {
    try {
      setIsLoading(true);
      const { success, data } = await fetchEducationDetail(id);
      if (!success || !data) {
        return;
      }
      setEducation(data);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const applicationEducation = async () => {
    setIsLoading(true);
    try {
      const { success, data, error } = await postApplicationEducation(id);
      if (success && data) {
        setApplyModal(true);
      } else {
        if (error.errCode === "4011") {
          setOpenModal(true);
        }
      }
      setIsLoading(false);
    } catch (error) {
      if (error.response.status === 500) {
        setErrorModal(true);
        return;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickApplicationButton = async () => {
    applicationEducation(1);
  };

  // useEffect
  useEffect(() => {
    if (!!id) {
      getEducationDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <Header mypage BottomNavigation>
        <Back />
        <CenterTitle title="교육 일정" />
      </Header>
      <Layout mainClassName="PADDING_TOP_ZERO PADDING_BOTTOM_ZERO FULL_SIDES">
        <CustomModal isOpen={openModal} handleClose={() => setOpenModal(false)}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text="이미 신청한 교육이 있습니다." />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn
              text="확인"
              type="tertiary"
              onClick={() => {
                setOpenModal(false);
              }}
            />
          </ModalButtonBox>
        </CustomModal>
        <CustomModal isOpen={applyModal}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text="교육을 신청했습니다." />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn
              text="확인"
              type="tertiary"
              onClick={() => {
                navigate(-1);
              }}
            />
          </ModalButtonBox>
        </CustomModal>
        <CustomModal isOpen={errorModal}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text="요청에 실패했습니다. 다시 시도해주세요." />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn text="확인" buttonGrade="tertiary" onClick={() => setErrorModal(false)} />
          </ModalButtonBox>
        </CustomModal>
        <section className="detail-section">
          <DetailItem title="교육 일시">
            <DetailIconList
              imgSrc={SVG.CALENDAR_SVG}
              imgAlt="calendar img"
              subTitle={
                education
                  ? `${moment(new Date(education.educationDate)).format(
                      "YYYY.MM.DD",
                    )} (${getKoreanDayByDay(moment(new Date(education.educationDate)).day())})`
                  : ""
              }
              className="FONT_WEIGHT_SEMI FONT_SIZE_16"
            />
            <DetailIconList
              imgSrc={SVG.TIME_SVG}
              imgAlt="time img"
              subTitle={
                education
                  ? `${getKoreanAmPm(moment(new Date(education.educationDate)).hour())} 
              ${moment(new Date(education.educationDate)).format("HH:mm")}`
                  : ""
              }
              className="FONT_WEIGHT_SEMI FONT_SIZE_16"
            />
          </DetailItem>
          <HorizonLineThick />

          <DetailItem title="예약 지점">
            <span>
              {education?.educationPlace ?? ""} {education?.educationPlaceName ?? ""}
            </span>
          </DetailItem>
          <HorizonLineThick />

          <DetailItem title="안내">
            <div
              className="danger-init"
              dangerouslySetInnerHTML={{
                __html: `<p>${education?.educationContent ?? ""}</p>`,
              }}
            ></div>
          </DetailItem>
          <HorizonLineThick />

          <div className="detail-button-box">
            {/* TODO :: if success <CustomLink href="/my-page/work/education/education-list" text="신청하기" linkGrade="primary" /> */}
            <CustomBtn
              text="신청하기"
              buttonGrade="primary"
              onClick={handleClickApplicationButton}
              disabled={education ? education.educationType === "DEADLINE" : true}
            />
          </div>
        </section>
      </Layout>
    </>
  );
}
