import moment from "moment";
import { useNavigate } from "react-router";
import React from "react";

import { SVG } from "constants";
import HorizonLineThin from "./common/HorizonLineThin";
import { getKoreanAmPm, getKoreanDayByDay } from "utils/commonFunction";

function WorkItem({ work }) {
  const navigate = useNavigate();

  return (
    <li
      className={`card-list__item ${
        new Date(work.reservation.reservationDatetime) < new Date() ? "disabled" : ""
      }`}
      onClick={() => {
        if (new Date(work.reservation.reservationDatetime) >= new Date()) {
          navigate("/reservation-reception/caddie", {
            state: {
              reservationId: work.id,
            },
          });
        }
      }}
    >
      <div className="card-list__item--info">
        <div className="info-box">
          <div className="info-box-_icon-box">
            <img src={SVG.CALENDAR_SVG} alt="calendar img" />
            <span>
              {work.reservation.reservationDatetime
                ? `${moment(new Date(work.reservation.reservationDatetime)).format(
                    "MM.DD",
                  )} (${getKoreanDayByDay(
                    moment(new Date(work.reservation.reservationDatetime)).day(),
                  )})`
                : ""}
            </span>
          </div>
          <span className="perpendicular-line">|</span>
          <div className="info-box-_icon-box">
            <img src={SVG.TIME_SVG} alt="clock img" />
            <span>
              {work.reservation.reservationDatetime
                ? `${getKoreanAmPm(moment(new Date(work.reservation.reservationDatetime)).hour())} 
                ${moment(new Date(work.reservation.reservationDatetime)).format("HH:mm")}`
                : ""}
            </span>
          </div>
        </div>
      </div>
      <HorizonLineThin />
      <div className="card-list__item--pay-info">
        <div className="info-box">
          <p>이용점명</p>
          <span>{work?.reservation?.franchise?.franchiseName ?? ""}</span>
        </div>
        <div className="info-box">
          <p>캐디유형</p>
          <span>
            {work?.reservation?.caddieType?.type === "S_CADDIE" ? "S캐디" : "라운드 S캐디"}
          </span>
        </div>
        <div className="info-box">
          <p>인원수</p>
          <span>{work?.reservation?.numberOfUser ?? 0}명</span>
        </div>
      </div>
    </li>
  );
}

export default WorkItem;
