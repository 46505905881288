import { axiosTokenInstance } from "./api";

export const postRating = async (comment, rating, reservationId) => {
  const url = `/rating`;
  const body = { comment, rating, reservationId };
  const { data } = await axiosTokenInstance.post(url, body);
  return data;
};

export const checkRating = async (reservationId) => {
  const url = `/rating/check?reservationId=${reservationId}`;
  const { data } = await axiosTokenInstance.get(url);
  return data;
};
