import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { useRecoilValue, useSetRecoilState } from "recoil";

import Layout from "Layout";
import Header from "Layout/Header";
import CenterTitle from "Layout/Header/CenterTitle";
import Back from "Layout/Header/Back";
import NoticeItem from "components/NoticeItem";
import CustomPagination from "components/common/CustomPagination";
import { fetchNoticeList } from "api/notice";
import { loadingState, userState } from "atom";

export default function MyNoticePage() {
  const [searchParams] = useSearchParams();
  // recoil
  const userInfo = useRecoilValue(userState);
  const setIsLoading = useSetRecoilState(loadingState);
  // state
  const [noticeList, setNoticeList] = useState([]);
  const [totalPage, setTotalPage] = useState(1);

  // fetch Notice List
  const getNoticeList = async (page) => {
    try {
      setIsLoading(true);
      const { success, data } = await fetchNoticeList(page);
      setNoticeList([]);
      if (!success) {
        return;
      }
      setNoticeList(data.noticeList);
      setTotalPage(data.pagingResponse.totalPage > 0 ? data.pagingResponse.totalPage : 1);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
    getNoticeList(searchParams.get("page") ? Number(searchParams.get("page")) : 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, userInfo]);

  return (
    <>
      <Header mypage>
        <Back />
        <CenterTitle title="공지사항" />
      </Header>
      <Layout mainClassName="PADDING_TOP_ZERO">
        <section className="my-page-service-notice-section">
          <div className="notice-area notice-title">
            <div className="notice--number">번호</div>
            <p className="notice--text">제목</p>
            <span className="notice--date">작성일시</span>
          </div>
          <ul className="notice-list">
            {noticeList &&
              noticeList.map((notice, index) => {
                const isRecent =
                  parseInt(
                    (new Date().getTime() - new Date(notice.createdDate).getTime()) /
                      (1000 * 60 * 60 * 24),
                    10,
                  ) <= 7;
                return (
                  <NoticeItem
                    key={`notice-${notice.id}`}
                    list_number={
                      ((searchParams.get("page") ? Number(searchParams.get("page")) : 1) - 1) * 10 +
                      index +
                      1
                    }
                    noticeId={notice.id}
                    title={notice.title}
                    recently={isRecent}
                    date={moment(new Date(notice.createdDate)).format("YYYY.MM.DD")}
                    prevPage={searchParams.get("page") ? Number(searchParams.get("page")) : 1}
                  />
                );
              })}
          </ul>

          <CustomPagination
            activePage={searchParams.get("page") ? Number(searchParams.get("page")) : 1}
            totalPage={totalPage}
            pageUrl="/my-page/service/notice-list"
          />
        </section>
      </Layout>
    </>
  );
}
