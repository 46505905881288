import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";

import Layout from "Layout";
import Header from "Layout/Header";
import CenterTitle from "Layout/Header/CenterTitle";
import Back from "Layout/Header/Back";
import { SVG } from "constants";
import CustomLink from "components/common/CustomLink";
import { fetchNoticeDetail } from "api/notice";
import { userState, loadingState } from "atom";

export default function MyNoticeDetailPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  // recoil
  const userInfo = useRecoilValue(userState);
  const setIsLoading = useSetRecoilState(loadingState);

  // state
  const [notice, setNotice] = useState(null);
  const [prevNotice, setPrevNotice] = useState(null);
  const [nextNotice, setNextNotice] = useState(null);

  const getNoticeDetail = async (id) => {
    try {
      setIsLoading(true);
      const { success, data } = await fetchNoticeDetail(id);
      if (!success || !data) {
        return;
      }
      setNotice(data.notice);
      setPrevNotice(data.prevNotice);
      setNextNotice(data.nextNotice);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect
  useEffect(() => {
    if (!!id && userInfo) {
      getNoticeDetail(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, userInfo]);
  return (
    <>
      <Header mypage BottomNavigation>
        <Back />
        <CenterTitle title="공지사항 내용" />
      </Header>
      <Layout>
        <section className="my-page-service-notice-detail-section">
          <div className="notice-detail">
            <div className="notice-detail__title">
              <div className="notice-detail--text-area">
                <p className="notice-detail--text-area--title">제목</p>
                <div className="notice-detail--danger-area">
                  <div
                    className="danger-init"
                    dangerouslySetInnerHTML={{
                      __html: `<p>${notice?.title ?? ""}</p>`,
                    }}
                  ></div>{" "}
                </div>
              </div>
            </div>
            <div className="notice-detail__contents">
              <div className="notice-detail--text-area">
                <p className="notice-detail--text-area--title">내용</p>
                <div className="notice-detail--danger-area">
                  <div
                    className="danger-init"
                    dangerouslySetInnerHTML={{
                      __html: `<p>${notice?.content ?? ""}</p>`,
                    }}
                  ></div>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="notice-detail__btn-wrap">
            <button
              className="notice-move-button notice-prev-button"
              onClick={() => {
                if (prevNotice?.id) {
                  navigate(`/my-page/service/notice-list/detail/${prevNotice.id}`, {
                    replace: true,
                  });
                }
              }}
            >
              {prevNotice && (
                <>
                  <img src={SVG.NEXT_CIRCLE_SVG} alt="next button img" />
                  <span className="ONE_LINE_ELLIPSIS">{prevNotice?.title ?? ""}</span>
                </>
              )}
            </button>

            <button
              className="notice-move-button notice-next-button"
              onClick={() => {
                if (nextNotice?.id) {
                  navigate(`/my-page/service/notice-list/detail/${nextNotice.id}`, {
                    replace: true,
                  });
                }
              }}
            >
              {nextNotice && (
                <>
                  <span className="ONE_LINE_ELLIPSIS">{nextNotice?.title ?? ""}</span>
                  <img src={SVG.NEXT_CIRCLE_SVG} alt="next button img" />
                </>
              )}
            </button>
          </div>
        </section>
        <div className="bottom-button">
          <CustomLink
            href={`/my-page/service/notice-list?page=${location.state?.page ?? 1}`}
            text="목록으로"
            linkGrade="primary"
            addClass="btn--white-bg"
          />
        </div>
      </Layout>
    </>
  );
}
