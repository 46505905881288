import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import moment from "moment";

import Layout from "Layout";
import Header from "Layout/Header";
import CenterTitle from "Layout/Header/CenterTitle";
import Back from "Layout/Header/Back";
import { fetchPrivacyPolicy } from "api/common";
import { loadingState } from "atom";

export default function MyTermsDetailPage({ termsType }) {
  // recoil
  const setIsLoading = useSetRecoilState(loadingState);
  // state
  const [termsTitle, setTermsTitle] = useState(termsType);
  const [terms, setTerms] = useState("");
  const [policy, setPolicy] = useState([]);

  const getPolicy = async (type) => {
    try {
      setIsLoading(true);
      const { data } = await fetchPrivacyPolicy(type);
      if (!data) {
        return;
      }
      setPolicy(data);
      setTerms(data[0].policyContent);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  // select box onchange handler
  const handleChangePolicy = (e) => {
    const filterPolicy = policy.filter((p) => Number(e.target.value) === p.id);
    if (filterPolicy[0]) {
      setTerms(filterPolicy[0].policyContent);
    }
  };

  useEffect(() => {
    if (termsType === "privacy") {
      setTermsTitle("개인정보 처리방침 약관");
      getPolicy("PRIVACY_POLICY");
    }
    if (termsType === "location") {
      setTermsTitle("위치정보 이용약관");
      getPolicy("LOCATION_POLICY");
    }
    if (termsType === "financial") {
      setTermsTitle("전자금융거래 이용약관");
      getPolicy("FINANCIAL_POLICY");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Header mypage>
        <Back />
        <CenterTitle title={termsTitle} />
      </Header>
      <Layout mainClassName="PADDING_TOP_ZERO">
        <section className="my-page-terms-detail-section">
          <div className="terms-detail__version">
            <div className="select-wrap">
              <div className="select-wrap__select MARGIN_LEFT_AUTO">
                <select name="terms" id="terms" onChange={handleChangePolicy}>
                  {policy &&
                    policy.map((p) => {
                      return (
                        <option key={`policy-${p.id}`} value={p.id}>
                          {moment(new Date(p.createdDate)).format("YYYY.MM.DD")}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          </div>

          <div className="terms-detail__danger-box">
            <div
              className="danger-init"
              dangerouslySetInnerHTML={{
                __html: `<p>${terms}</p>`,
              }}
            ></div>
          </div>
        </section>
      </Layout>
    </>
  );
}
