import { axiosInstance, axiosTokenInstance } from "./api";

export const fetchEducationList = async (page) => {
  const url = `/education?page=${page}`;
  const { data } = await axiosInstance.get(url);
  return data;
};

export const fetchEducationDetail = async (id) => {
  const url = `/education/${id}`;
  const { data } = await axiosInstance.get(url);
  return data;
};

export const fetchMyEducation = async () => {
  const url = "/education/application";
  const { data } = await axiosTokenInstance.get(url);
  return data;
};

export const checkCaddieEducation = async () => {
  const url = `/education/caddie`;
  const { data } = await axiosTokenInstance.get(url);
  return data;
};

export const postApplicationEducation = async (educationId) => {
  const url = "/education";
  const body = {
    educationId,
  };

  const { data } = await axiosTokenInstance.post(url, body);
  return data;
};
