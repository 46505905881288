import { LOGO } from "constants";
import React from "react";
import { Link } from "react-router-dom";

function Logo() {
  return (
    <li className="nav-top__list--logo">
      <h1>
        <Link to={"/main"}>
          <img src={LOGO} alt="logo" />
        </Link>
      </h1>
    </li>
  );
}

export default Logo;
