import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import Layout from "Layout";
import Header from "Layout/Header";
import Back from "Layout/Header/Back";
import CenterTitle from "Layout/Header/CenterTitle";
import EducationItem from "components/EducationItem";
import CustomPagination from "components/common/CustomPagination";
import { fetchEducationList } from "api/education";
import { userState, loadingState } from "atom";

export default function MyOtherEducationListPage() {
  const [searchParams] = useSearchParams();
  // recoil
  const userInfo = useRecoilValue(userState);
  const setIsLoading = useSetRecoilState(loadingState);
  // state
  const [educationList, setEducationList] = useState([]);
  const [totalPage, setTotalPage] = useState(1);

  // fetch Education List
  const getEducationList = async (page) => {
    try {
      setIsLoading(true);
      const { success, data } = await fetchEducationList(page);
      setEducationList([]);
      if (!success) {
        return;
      }
      setEducationList(data.educationList);
      setTotalPage(data.pagingResponse.totalPage);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect
  useEffect(() => {
    if (window) {
      window.scrollTo(0, 0);
    }
    if (userInfo) {
      getEducationList(searchParams.get("page") ? Number(searchParams.get("page")) : 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, userInfo]);

  return (
    <>
      <Header mypage>
        <Back />
        <CenterTitle title="교육 목록" />
      </Header>
      <Layout>
        <section className="work-list-section">
          <ol className="card-list">
            {educationList &&
              educationList.map((education, index) => {
                return (
                  <EducationItem
                    key={education.id}
                    educationId={education.id}
                    educationDate={education.educationDate}
                    location={education.educationPlace}
                    disabled={education.educationType === "DEADLINE"}
                  />
                );
              })}
          </ol>
          <CustomPagination
            activePage={searchParams.get("page") ? Number(searchParams.get("page")) : 1}
            totalPage={totalPage}
            pageUrl="/my-page/work/education/education-list"
          />
        </section>
      </Layout>
    </>
  );
}
