import CustomBtn from "./CustomBtn";
import CustomModal from "./CustomModal";
import ModalButtonBox from "./CustomModal/ModalButtonBox";
import ModalHeading from "./CustomModal/ModalHeading";
import ModalText from "./CustomModal/ModalText";
import HorizonLineThin from "./HorizonLineThin";

const ExistReviewModal = ({ isOpen, setIsOpen }) => {
  return (
    <CustomModal isOpen={isOpen}>
      <ModalHeading heading="알림" />
      <HorizonLineThin />
      <ModalText text="이미 별점을 남겨주신 예약입니다." />
      <HorizonLineThin />
      <ModalButtonBox>
        <CustomBtn text="확인" type="tertiary" onClick={() => setIsOpen(false)} />
      </ModalButtonBox>
    </CustomModal>
  );
};

export default ExistReviewModal;
