import { axiosTokenInstance } from "./api";

export const requestPayment = async (imp_uid) => {
  const url = `/payment?imp_uid=${imp_uid}`;
  const { data } = await axiosTokenInstance.get(url);
  return data;
};

export const pointPayment = async (body) => {
  const url = "/payment/point";
  const { data } = await axiosTokenInstance.post(url);
  return data;
};
