import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import { SVG, RN_TYPE } from "constants";
import { isEducationState, loadingState, isServerReqErrorState } from "atom";
import { checkCaddieEducation } from "api/education";
import { fetchUserInfo } from "api/user";
import reactNativeHandler from "./../../utils/reactNativeEx";

function Header({ children, BottomNavigation, home, reservation, mypage, service }) {
  const navigate = useNavigate();
  const { Kakao } = window;

  const setIsEducation = useSetRecoilState(isEducationState);
  const setIsLoading = useSetRecoilState(loadingState);
  const setIsServerError = useSetRecoilState(isServerReqErrorState);
  const getUserInfo = async () => {
    setIsLoading(true);
    try {
      const { data } = await fetchUserInfo();
      return data.user;
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  // check Education
  const checkEducation = async () => {
    setIsLoading(true);
    try {
      const { success, data } = await checkCaddieEducation();
      if (success) {
        if (!data) {
          setIsLoading(false);
          setIsEducation(true);
          return;
        }
        navigate("/reservation/caddie");
      }
    } catch (error) {
      if (error.response.status === 500) {
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!Kakao) return;

    if (!Kakao.isInitialized()) {
      Kakao.init(process.env.REACT_APP_KAKAO_JS_KEY);
    }
  }, [Kakao]);

  return (
    <>
      <header>
        <nav className="nav-top">
          <ol className="nav-top__list">{children}</ol>
        </nav>
        {!BottomNavigation && (
          <nav className="nav-bottom">
            <ol className="nav-bottom__list">
              <li className="nav-bottom__list--link">
                <Link className="link-item" to="/main">
                  {home ? (
                    <img src={SVG.HOME_ACTIVE} alt="home-img" />
                  ) : (
                    <img src={SVG.HOME} alt="home-img" />
                  )}{" "}
                  홈
                </Link>
              </li>
              <li className="nav-bottom__list--link">
                <Link
                  className="link-item"
                  to="/reservation"
                  onClick={(e) => {
                    e.preventDefault();
                    getUserInfo()
                      .then((res) => {
                        if (res.roles[0].name === "ROLE_CADDIE") {
                          checkEducation();
                        } else if (res.roles[0].name === "ROLE_FRANCHISE") {
                          navigate("/schedule-list/store");
                        } else {
                          navigate("/reservation");
                        }
                      })
                      .catch((error) => {
                        if (error.response.status === 500) {
                          setIsServerError(true);
                        }
                      });
                  }}
                >
                  {/* TODO ::  */}
                  {/* if login type is caddie <Link className="link-item" to="/reservation/caddie"> */}
                  {/* if login type is store <Link className="link-item" to="/schedule-list/store"> */}
                  {reservation ? (
                    <img src={SVG.RESERVATION_ACTIVE} alt="home-img" />
                  ) : (
                    <img src={SVG.RESERVATION} alt="reservation-img" />
                  )}{" "}
                  예약
                </Link>
              </li>
              <li className="nav-bottom__list--link">
                <Link className="link-item" to="/my-page/user">
                  {mypage ? (
                    <img src={SVG.MY_PAGE_ACTIVE} alt="home-img" />
                  ) : (
                    <img src={SVG.MY_PAGE} alt="myPage-img" />
                  )}{" "}
                  마이페이지
                </Link>
              </li>
              <li className="nav-bottom__list--link">
                <button
                  className="link-item"
                  type="button"
                  onClick={() => {
                    if (window.ReactNativeWebView) {
                      reactNativeHandler(RN_TYPE.BANNER, "http://pf.kakao.com/_Mxopxmxj");
                    } else {
                      Kakao.Channel.chat({
                        channelPublicId: "_Mxopxmxj",
                      });
                    }
                  }}
                >
                  {service ? (
                    <img src={SVG.SERVICE_ACTIVE} alt="home-img" />
                  ) : (
                    <img src={SVG.SERVICE} alt="service-img" />
                  )}{" "}
                  고객센터
                </button>
              </li>
            </ol>
          </nav>
        )}
      </header>
    </>
  );
}

export default Header;
