import React from "react";
import { Link } from "react-router-dom";

import windowReactNativeWebView from "utils/windowReactNativeWebView";
import reactNativeHandler from "./../../utils/reactNativeEx";
import { RN_TYPE } from "constants";

function BannerLink({ bannerUrl, bannerImage, imgAlt, className }) {
  return (
    <a
      href={bannerUrl}
      className={className ? className : "img-link"}
      target="_blank"
      rel="noreferrer"
      onClick={(e) => {
        if (windowReactNativeWebView) {
          e.preventDefault();
          reactNativeHandler(RN_TYPE.BANNER, bannerUrl);
        }
      }}
    >
      <img src={bannerImage} alt={imgAlt} />
    </a>
  );
}

export default BannerLink;
