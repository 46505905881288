import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  withCredentials: false,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    config.headers = config.headers ?? {};
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
// 헤더에 엑세스없는경우
//  만료된경우
// decoded햇는데 이상한경우
// 리프레시 토큰 만료된경우
// 리프레시 호출하는데 리프레시가 이상한경우
// 성공시 이전 api 재 호출
axiosInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      // 401 Unauthorize
    } else if (error.response.status === 403) {
      localStorage.removeItem("refreshToken");
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "removeStorage",
            value: { type: "refreshToken" },
          }),
        );
      }
      return;
    } else {
      return Promise.reject(error);
    }
  },
);

export const axiosTokenInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  withCredentials: false,
  retry: 3,
});

axiosTokenInstance.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

let isRefreshing = false;
let refreshSubscribers = [];

axiosTokenInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const refreshToken = localStorage.getItem("refreshToken");

    if (
      (error.response.status === 401 || error.response.status === 500) &&
      !originalRequest._retry
    ) {
      if (!refreshToken) {
        return Promise.reject(error);
      }

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          refreshSubscribers.push((accessToken) => {
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            resolve(axios(originalRequest));
          });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/auth/refreshtoken`,
          { refreshToken },
        );
        const newAccessToken = response.data.accessToken;
        localStorage.setItem("accessToken", newAccessToken);
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: "setStorage",
              value: { type: "accessToken", value: newAccessToken },
            }),
          );
        }
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        refreshSubscribers.forEach((callback) => callback(newAccessToken));
        refreshSubscribers = [];
        return axiosTokenInstance(originalRequest);
      } catch (err) {
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                type: "removeStorage",
                value: { type: "refreshToken" },
              }),
            );
          }
          window.location.href = "/";
          return Promise.reject(err);
        }
        return Promise.reject(err);
      } finally {
        isRefreshing = false;
      }
    }
    return Promise.reject(error);
  },
);
