import { fetchFranchiseCoordinate } from "api/franchise";
import React, { useEffect, useRef, useState } from "react";

function NaverMap({ nowPosition, setWhere }) {
  const { naver } = window;

  const mapElement = useRef(null);

  const [naverMap, setNaverMap] = useState(null);
  const [bounds, setBounds] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [infoWindows, setInfoWindows] = useState([]);
  const [selectedInfoWindow, setSelectedInfoWindow] = useState(null);

  useEffect(() => {
    if (nowPosition) {
      const location = new naver.maps.LatLng(nowPosition.latitude, nowPosition.longitude);
      const mapOptions = {
        center: location,
        zoom: 16,
      };

      setNaverMap(new naver.maps.Map(mapElement.current, mapOptions));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nowPosition]);

  // setMap
  useEffect(() => {
    if (naverMap) {
      setBounds(naverMap.getBounds());
      naver.maps.Event.addListener(naverMap, "idle", function () {
        setBounds(naverMap.getBounds());
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [naverMap]);

  useEffect(() => {
    return () => {
      setWhere({ id: null, text: "어디서 부르시나요?" });
      infoWindows.forEach((info) => {
        if (info.infoWindow.setMap()) {
          info.infoWindow.close();
          info.infoWindow.setMap(null);
        }
      });
      setInfoWindows([]);

      markers.forEach((mark) => {
        if (mark.marker.setMap()) {
          mark.marker.setMap(null);
        }
      });
      setMarkers([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch Franchise by bounds
  useEffect(() => {
    if (bounds) {
      const sw = bounds.getSW();
      const ne = bounds.getNE();
      fetchFranchiseCoordinate(
        String(ne._lat),
        String(ne._lng),
        String(sw._lat),
        String(sw._lng),
      ).then((res) => {
        const { data } = res;
        if (data) {
          infoWindows.forEach((info) => {
            if (info.infoWindow.getMap()) {
              info.infoWindow.close();
              info.infoWindow.setMap(null);
            }
          });
          setInfoWindows([]);

          markers.forEach((mark) => {
            if (mark.marker.setMap()) {
              mark.marker.setMap(null);
            }
          });
          setMarkers([]);

          data.forEach((m) => {
            const marker = new naver.maps.Marker({
              position: new naver.maps.LatLng(m.location.latitude, m.location.longitude),
              map: naverMap,
              icon: {
                url: "/asset/image/icons/map-marker-blue.svg",
              },
            });

            setMarkers([...markers, { marker, id: m.id }]);
            const infoWindow = new naver.maps.InfoWindow({
              position: new naver.maps.LatLng(m.location.latitude, m.location.longitude),
              content: `<div class="map-info"><p>${m.franchiseName}</p></div>`,
              disableAnchor: true,
              pixelOffset: { x: 0, y: -25 },
            });
            naver.maps.Event.addListener(marker, "click", function (e) {
              if (infoWindow.getMap()) {
                infoWindow.close();
                setSelectedInfoWindow(null);
                setWhere({ id: null, text: "어디서 부르시나요?" });
              } else {
                infoWindows.forEach((info) => {
                  info.infoWindow.close();
                });
                infoWindow.open(naverMap, marker);
                setSelectedInfoWindow({
                  location: m.location,
                  id: m.id,
                  franchiseName: m.franchiseName,
                });
                setSelectedMarker(m.location);
                setWhere({ id: m.id, text: m.franchiseName });
              }
            });
            setInfoWindows([...infoWindows, { infoWindow, id: m.id }]);
          });

          if (selectedInfoWindow) {
            if (infoWindows.filter((info) => info.id === selectedInfoWindow.id).length > 0) {
              infoWindows
                .filter((info) => info.id === selectedInfoWindow.id)[0]
                .infoWindow.open(
                  naverMap,
                  markers.filter((mark) => mark.id === selectedInfoWindow.id)[0].marker,
                );
            } else {
              const marker = new naver.maps.Marker({
                position: new naver.maps.LatLng(
                  selectedInfoWindow.location.latitude,
                  selectedInfoWindow.location.longitude,
                ),
                map: naverMap,
                icon: {
                  url: "/asset/image/icons/map-marker-blue.svg",
                },
              });

              setMarkers([...markers, { marker, id: selectedInfoWindow.id }]);

              const infoWindow = new naver.maps.InfoWindow({
                position: new naver.maps.LatLng(
                  selectedInfoWindow.location.latitude,
                  selectedInfoWindow.location.longitude,
                ),
                content: `<div class="map-info"><p>${selectedInfoWindow.franchiseName}</p></div>`,
                disableAnchor: true,
                pixelOffset: { x: 0, y: -25 },
              });

              const temp = {
                location: selectedInfoWindow.location,
                id: selectedInfoWindow.id,
                franchiseName: selectedInfoWindow.franchiseName,
              };
              naver.maps.Event.addListener(marker, "click", function (e) {
                if (infoWindow.getMap()) {
                  infoWindow.close();
                  setSelectedInfoWindow(null);
                  setWhere({ id: null, text: "어디서 부르시나요?" });
                } else {
                  infoWindows.forEach((info) => {
                    info.infoWindow.close();
                  });
                  infoWindow.open(naverMap, marker);
                  setSelectedInfoWindow({
                    location: temp.location,
                    id: temp.id,
                    franchiseName: temp.franchiseName,
                  });
                  setSelectedMarker(temp.location);
                  setWhere({ id: temp.id, text: temp.franchiseName });
                }
              });
              setInfoWindows([...infoWindows, { infoWindow, id: temp.id }]);
            }
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds]);

  return (
    <div className="map-wrap">
      <div
        className="map-wrapper"
        ref={mapElement}
        style={{ minHeight: "calc(100vh - 258px)", height: "100%" }}
      />
    </div>
  );
}

export default NaverMap;
