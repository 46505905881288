import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import Layout from "Layout";
import Header from "Layout/Header";
import Back from "Layout/Header/Back";
import CenterTitle from "Layout/Header/CenterTitle";
import ScheduleItem from "components/ScheduleItem";
import { loadingState } from "atom";
import CustomModal from "components/common/CustomModal";
import ModalHeading from "components/common/CustomModal/ModalHeading";
import HorizonLineThin from "components/common/HorizonLineThin";
import ModalText from "components/common/CustomModal/ModalText";
import ModalButtonBox from "components/common/CustomModal/ModalButtonBox";
import CustomBtn from "components/common/CustomBtn";
import { ERROR_TEXT } from "constants";
import { fetchReservationList } from "api/reservation";
import CustomPagination from "components/common/CustomPagination";

export default function SchedulePage({ adminType }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  // recoil
  const [isLoading, setIsLoading] = useRecoilState(loadingState);
  // state
  const [errorModal, setErrorModal] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [reservationList, setReservationList] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  // get Reservation List
  const getReservationList = async (page) => {
    setIsLoading(true);
    try {
      const { data } = await fetchReservationList(page);
      setReservationList(data.reservationList);
      setTotalPage(data.pagingResponse.totalPage > 0 ? data.pagingResponse.totalPage : 1);

      return;
    } catch (err) {
      if (err.response.status === 500) {
        setErrorText(ERROR_TEXT.failedToRequest);
        setErrorModal(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getReservationList(searchParams.get("page") ? Number(searchParams.get("page")) : 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <>
      <Header reservation>
        <Back href={location?.state?.notBack ? "/my-page/user" : null} />
        <CenterTitle title="예약 내역" />
      </Header>
      <Layout>
        <CustomModal isOpen={errorModal}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text={errorText} />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn text="확인" buttonGrade="tertiary" onClick={() => setErrorModal(false)} />
          </ModalButtonBox>
        </CustomModal>
        <section className="schedule-section">
          {!isLoading && reservationList.length <= 0 && (
            <div className="none-text-relative">예약 내역이 없습니다.</div>
          )}
          <ol className="schedule-list">
            {reservationList &&
              reservationList.map((reservation) => {
                return (
                  <ScheduleItem key={`reservation-${reservation.id}`} reservation={reservation} />
                );
              })}
            {/* <ScheduleItem
              title="이용점 명"
              href="/payment-detail/user"
              btnTitle="결제완료"
              linkType="payment"
            />
            <ScheduleItem
              title="이용점 명"
              href="/assign-complete/user"
              btnTitle="배정완료"
              linkType="assign"
            />
            <ScheduleItem
              title="이용점 명"
              href="/payment-cancel"
              btnTitle="예약취소"
              linkType="payment"
            /> */}
          </ol>
          <CustomPagination
            activePage={searchParams.get("page") ? Number(searchParams.get("page")) : 1}
            totalPage={totalPage}
            pageUrl="/schedule-list/user"
          />
        </section>
      </Layout>
    </>
  );
}
