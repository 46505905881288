import { axiosTokenInstance } from "./api";

export const getPushAlarmList = async () => {
  const url = "/push";
  const { data } = await axiosTokenInstance.get(url);
  return data;
};

export const saveFireBaseToken = async (deviceId, firebaseToken) => {
  const url = "/push";
  const { data } = await axiosTokenInstance.post(url, {
    deviceId,
    firebaseToken,
  });
  return data;
};

export const updateReadStatus = async () => {
  const url = "/push";
  const { data } = await axiosTokenInstance.put(url);
  return data;
};
