import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { isArray } from "lodash";

import { SVG } from "constants";
import Layout from "Layout";
import Header from "Layout/Header";
import Logo from "Layout/Header/Logo";
import Alarm from "Layout/Header/Alarm";
import NaverMap from "components/NaverMap";
import { fetchFranchiseKeyword } from "api/franchise";
import CustomBtn from "components/common/CustomBtn";

export default function ReservationPage() {
  const navigate = useNavigate();
  // state
  const [isClick, setIsClick] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [where, setWhere] = useState({
    id: null,
    text: "어디서 부르시나요?",
  });
  // map
  const [nowPosition, setNowPosition] = useState(null);

  // search input handler
  const handleChangeSearchInput = (e) => {
    if (String(e.target.value).length > 10) {
      e.target.value = [...String(e.target.value)].slice(0, 10).join("");
    }
    setSearchInput(e.target.value);
  };

  const handleSubmitSearchInput = async (e) => {
    e.preventDefault();
    if (String(searchInput).length < 2) {
      setSearchList([]);
    } else {
      const { data } = await fetchFranchiseKeyword(searchInput);
      if (data && isArray(data)) {
        setSearchList(data);
      } else {
        setSearchList([]);
      }
    }
  };

  // set Now Position
  function getCurrentPosition() {
    return new Promise(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  }
  const fetchNowPosition = async () => {
    try {
      if (localStorage.getItem("geolocation")) {
        const parsedLocation = JSON.parse(localStorage.getItem("geolocation"));
        setNowPosition(parsedLocation);
      } else {
        const position = await getCurrentPosition();
        setNowPosition(position.coords);
      }
    } catch (error) {
      setNowPosition({ latitude: 37.5666805, longitude: 126.9784147 });
    }
  };

  useEffect(() => {
    fetchNowPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header reservation BottomNavigation>
        <Logo />
        <Alarm />
      </Header>
      <Layout mainClassName="PADDING_BOTTOM_ZERO">
        {!isClick && (
          <>
            <section className="map-section">
              <button
                className="map-section--button"
                type="button"
                onClick={() => {
                  // setNowPosition({
                  //   latitude: 37.5233615,
                  //   longitude: 127.0942515,
                  // });
                  setIsClick((val) => !val);
                }}
              >
                {where.text}
              </button>
              {nowPosition && <NaverMap nowPosition={nowPosition} setWhere={setWhere} />}
              <div className="bottom-button MARGIN_TOP_20">
                <CustomBtn
                  buttonType="button"
                  text="예약 하기"
                  buttonGrade="primary"
                  disabled={!where.id}
                  onClick={() => {
                    navigate(`/reservation-detail/${where.id}`, { state: where });
                  }}
                />
              </div>
            </section>
          </>
        )}
        {isClick && (
          <section className="search-section">
            <form className="search-section__form" onSubmit={handleSubmitSearchInput}>
              <fieldset>
                <legend>map search</legend>
                <div className="search-input-box">
                  <button
                    type="button"
                    className="search-input-box__back-button"
                    onClick={() => setIsClick((val) => !val)}
                  >
                    <img src={SVG.BACK_SVG} alt="back button" />
                  </button>
                  <input
                    className="search-input-box__input"
                    type="search"
                    placeholder="가맹점명을 입력하세요."
                    value={searchInput}
                    onChange={handleChangeSearchInput}
                  />
                  <button type="submit" className="search-input-box__search-button">
                    <img src={SVG.SEARCH_GREY_SVG} alt="search button" />
                  </button>
                </div>
              </fieldset>
            </form>
            <ul className="search-section__list">
              {searchList &&
                searchList.map((search) => {
                  return (
                    <li className="search-item" key={`search-${search.id}`}>
                      <button
                        className="search-item--button"
                        onClick={() => {
                          setIsClick((val) => !val);
                          setNowPosition(search.location);
                        }}
                      >
                        <img src={SVG.MAP_MAKER_BLACK_SVG} alt="marker-img" />
                        <span className="ONE_LINE_ELLIPSIS">{search.franchiseName}</span>
                      </button>
                    </li>
                  );
                })}
            </ul>
          </section>
        )}
      </Layout>
    </>
  );
}
