import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./style/reset.css";
import "./style/common.css";
import "./style/style.css";
import ReactDOM from "react-dom/client";
import App from "./App";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { RecoilRoot } from "recoil";
import ScrollToTop from "components/common/ScrollTop";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <RecoilRoot>
      <Router>
        <ScrollToTop />
        <App />
      </Router>
    </RecoilRoot>
    <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
  </QueryClientProvider>,
  //{/* </React.StrictMode> */}
);
