import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import { SVG } from "constants";
import { getKoreanAmPm, getKoreanDayByDay } from "utils/commonFunction";

function EducationItem({ location, disabled, educationDate, educationId }) {
  return (
    <li className={`card-list__item ${disabled ? "disabled" : ""}`}>
      <Link to={`/my-page/work/education/education-list/detail/${educationId}`}>
        <div className="card-list__item--info">
          <div className="info-box">
            <div className="info-box-_icon-box">
              <img src={SVG.PLACE_MARKER_SVG} alt="calendar img" />
              <span className="ONE_LINE_ELLIPSIS">{location ?? ""}</span>
            </div>
            {disabled ? (
              <span className="info-box__toggle-text">마감</span>
            ) : (
              <span className="info-box__toggle-text active">접수중</span>
            )}
          </div>
          <div className="info-box">
            <div className="info-box-_icon-box">
              <img src={SVG.CALENDAR_SVG} alt="calendar img" />
              <span>
                {educationDate
                  ? `${moment(new Date(educationDate)).format("MM.DD")} (${getKoreanDayByDay(
                      moment(new Date(educationDate)).day(),
                    )})`
                  : ""}
              </span>
            </div>
            <span className="perpendicular-line">|</span>
            <div className="info-box-_icon-box">
              <img src={SVG.TIME_SVG} alt="clock img" />
              <span>
                {educationDate
                  ? `${getKoreanAmPm(moment(new Date(educationDate)).hour())} 
                ${moment(new Date(educationDate)).format("HH:mm")}`
                  : ""}
              </span>
            </div>
          </div>
        </div>
      </Link>
    </li>
  );
}

export default EducationItem;
