import moment from "moment";
import React from "react";

import { getKoreanAmPm, getKoreanDayByDay } from "utils/commonFunction";
import CustomLink from "./common/CustomLink";
import { SVG, RN_TYPE } from "constants";
import HorizonLineThin from "./common/HorizonLineThin";

function ScheduleItem({ reservation }) {
  return (
    <li className="card-list__item">
      <div className="card-list__item--info">
        <div className="info-box">
          <a
            href={`tel:${reservation.franchise.phone}`}
            onClick={(e) => {
              if (window.ReactNativeWebView) {
                e.preventDefault();
                window.ReactNativeWebView.postMessage(
                  JSON.stringify({ type: RN_TYPE.CALL, value: reservation.franchise.phone }),
                );
              }
            }}
          >
            {reservation.franchise.franchiseName}&nbsp;&nbsp;
            <img src={SVG.SERVICE} alt="phone img" />
          </a>
          {/* {linkType === "cancel" && <CustomLink text={btnTitle} linkGrade="tertiary" addClass="fit-element disabled" />} */}
          {reservation.status === "PAYMENT_DONE" && (
            <CustomLink
              href={`/payment-detail/user`}
              state={{ reservationId: reservation.id }}
              text="결제완료"
              linkGrade="btn-common fit-element payment"
            />
          )}
          {reservation.status === "RESERVATION_CANCEL" && (
            <CustomLink
              href={`/payment-cancel`}
              onClick={(e) => {
                e.preventDefault();
              }}
              state={{ reservationId: reservation.id }}
              text="예약취소"
              linkGrade="btn-common fit-element cancel"
            />
          )}
          {reservation.status === "ASSIGNMENT_DONE" && (
            <CustomLink
              href={`/assign-complete/user`}
              state={{ reservationId: reservation.id }}
              text="배정완료"
              linkGrade="btn-common fit-element assign"
            />
          )}
        </div>
        <div className="info-box">
          <div className="info-box-_icon-box">
            <img src={SVG.CALENDAR_SVG} alt="calendar img" />
            <span>
              {reservation.reservationDatetime
                ? `${moment(new Date(reservation.reservationDatetime)).format(
                    "MM.DD",
                  )} (${getKoreanDayByDay(
                    moment(new Date(reservation.reservationDatetime)).day(),
                  )})`
                : ""}
            </span>
          </div>
          <span className="perpendicular-line">|</span>
          <div className="info-box-_icon-box">
            <img src={SVG.TIME_SVG} alt="clock img" />
            <span>
              {reservation.reservationDatetime
                ? `${getKoreanAmPm(moment(new Date(reservation.reservationDatetime)).hour())} 
                ${moment(new Date(reservation.reservationDatetime)).format("HH:mm")}`
                : ""}
            </span>
          </div>
        </div>
      </div>
      <HorizonLineThin />
      <div className="card-list__item--pay-info">
        <div className="info-box">
          <p>결제금액</p>
          <span>{Number(reservation.payments.amount).toLocaleString("USD")} 원</span>
        </div>
      </div>
    </li>
  );
}

// function ScheduleItem({ title, href, btnTitle, linkType }) {
//   return (
//     <li className="card-list__item">
//       <div className="card-list__item--info">
//         <div className="info-box">
//           <a href="tel:1599-1599">
//             {title}&nbsp;&nbsp;
//             <img src={SVG.SERVICE} alt="phone img" />
//           </a>
//           {linkType === "cancel" && <CustomLink text={btnTitle} linkGrade="tertiary" addClass="fit-element disabled" />}
//           {linkType !== "cancel" && <CustomLink href={href} text={btnTitle} linkGrade="tertiary" addClass={`fit-element ${linkType}`} />}
//         </div>
//         <div className="info-box">
//           <div className="info-box-_icon-box">
//             <img src={SVG.CALENDAR_SVG} alt="calendar img" />
//             <span>12.30 (금)</span>
//           </div>
//           <span className="perpendicular-line">|</span>
//           <div className="info-box-_icon-box">
//             <img src={SVG.TIME_SVG} alt="clock img" />
//             <span>오전 12:30</span>
//           </div>
//         </div>
//       </div>
//       <HorizonLineThin />
//       <div className="card-list__item--pay-info">
//         <div className="info-box">
//           <p>결제금액</p>
//           <span>220,0000 원</span>
//         </div>
//       </div>
//     </li>
//   );
// }

export default ScheduleItem;
