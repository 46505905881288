import React, { forwardRef, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useRecoilState, useSetRecoilState } from "recoil";
import { loadingState, userState } from "atom";
import { isNumber } from "lodash";
import moment from "moment";

import { SVG, ERROR_TEXT } from "constants";
import ReactDatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import Layout from "Layout";
import Header from "Layout/Header";
import Back from "Layout/Header/Back";
import CenterTitle from "Layout/Header/CenterTitle";
import CustomBtn from "components/common/CustomBtn";
import CustomDatePicker from "components/CustomDatePicker";
import { fetchUserPoints } from "api/point";
import CustomModal from "components/common/CustomModal";
import ModalHeading from "components/common/CustomModal/ModalHeading";
import HorizonLineThin from "components/common/HorizonLineThin";
import ModalText from "components/common/CustomModal/ModalText";
import ModalButtonBox from "components/common/CustomModal/ModalButtonBox";
import ErrorLoadPaymentModal from "components/common/ErrorLoadPaymentModal";
import { fetchUserInfo } from "api/user";
import { fetchFranchiseInfo } from "api/franchise";
import windowReactNativeWebView from "utils/windowReactNativeWebView";
import { pointPayment } from "api/payment";
import { checkRefreshToken } from "api/auth";
import CheckReservationDateModal from "components/common/CheckReservationDateModal";

export default function ReservationDetailPage() {
  const navigate = useNavigate();
  const { id } = useParams();

  // recoil
  const [userInfo, setUserInfo] = useRecoilState(userState);
  const setIsLoading = useSetRecoilState(loadingState);
  // state
  const [franchiseInfo, setFranchiseInfo] = useState({
    text: "",
    id: null,
  });
  const [meridiem, setMeridiem] = useState("am");
  const [hour, setHour] = useState(1);
  const [minute, setMinute] = useState(0);
  const [caddieType, setCaddieType] = useState("s-caddie-1");
  const [userNumber, setUserNumber] = useState(1);
  const [request, setRequest] = useState("");
  const [userPoints, setUserPoints] = useState(0);
  const [points, setPoints] = useState("");
  const [price, setPrice] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [expectPoints, setExpectPoints] = useState(0);

  const [isNotUsePoint, setIsNotUsePoint] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [paymentLoadError, setPaymentLoadError] = useState(false);
  const [paymentFailError, setPaymentFailError] = useState(false);
  const [amTimeObject, setAmTimeObject] = useState(null);
  const [pmTimeObject, setPmTimeObject] = useState(null);
  const [sHour, setSHour] = useState(0);
  const [checkTime, setCheckTime] = useState(false);

  // state date Picker
  const [isGuidePop, setIsGuidePop] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [pickDate, setPickDate] = useState(new Date());
  const [isBottomSheet, setIsBottomSheet] = useState(false);
  const [maxDate, setMaxDate] = useState(new Date());

  const CustomDatePickerButton = forwardRef(({ value, onClick }, ref) => (
    <button className="datepicker-custom-input" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  // onChange handler
  const handleChangeMeridiem = (e) => {
    setMeridiem(e.target.value);
    if (e.target.value === "am") {
      setHour(sHour);
      setMinute(amTimeObject[sHour][0] ? amTimeObject[sHour][0] : 0);
    } else {
      setHour(0);
      setMinute(0);
    }
  };
  const handleChangeHour = (e) => {
    setHour(e.target.value);
    setMinute(0);
  };
  const handleChangeMinute = (e) => {
    setMinute(e.target.value);
  };
  const handleChangeCaddieType = (e) => {
    setCaddieType(e.target.value);
  };
  const handleChangeRequest = (e) => {
    setRequest(e.target.value);
  };
  const handleFocusPoint = (e) => {
    if (String(e.target.value).includes("P")) {
      e.target.value = String(e.target.value).split(" P").join("");
    }
  };
  const handleChangePoints = (e) => {
    e.target.value = String(e.target.value).split(",").join("");
    if (isNaN(Number(e.target.value))) return;

    if (String(e.target.value).length > 0) {
      let tempPoint = Number(e.target.value) > userPoints ? userPoints : Number(e.target.value);
      tempPoint = tempPoint >= price ? price : tempPoint;
      if (tempPoint < 1000) {
        if (tempPoint > 0) setIsNotUsePoint(true);
        else setIsNotUsePoint(false);
      } else {
        if (tempPoint % 100 !== 0) setIsNotUsePoint(true);
        else setIsNotUsePoint(false);
      }
      setPoints(tempPoint.toLocaleString("USD"));
    } else {
      setPoints("");
      setIsNotUsePoint(false);
    }
  };
  const handleBlurPoints = (e) => {
    if (String(e.target.value).length > 0) {
      e.target.value = `${e.target.value} P`;
    }
  };
  // onClick handler
  const handleClickMinusUserNumber = () => {
    if (userNumber <= 1) return;
    setUserNumber(userNumber - 1);
  };
  const handleClickPlusUserNumber = () => {
    if (userNumber >= 4) return;
    setUserNumber(userNumber + 1);
  };

  // onClick Payment handler
  const handleClickPayment = async () => {
    if (
      new Date(
        `${moment(new Date(startDate)).format("YYYY-MM-DD")} ${String(
          meridiem === "am" ? hour : 12 + Number(hour),
        ).padStart(2, 0)}:${String(minute).padStart(2, 0)}:00`,
      ) <= new Date()
    ) {
      setCheckTime(true);
      return;
    }
    if (finalPrice <= 0) {
      try {
        const { success, data } = await pointPayment({
          caddieCount: Number(String(caddieType).slice(-1)),
          caddieType: String(caddieType).includes("round-s-caddie") ? "ROUND_S_CADDIE" : "S_CADDIE",
          franchise: true,
          franchiseId: franchiseInfo.id,
          requestMessage: request,
          reservationDatetime: `${moment(new Date(startDate)).format("YYYY-MM-DD")} ${String(
            meridiem === "am" ? hour : 12 + Number(hour),
          ).padStart(2, 0)}:${String(minute).padStart(2, 0)}:00`,
          usedPoint: !points ? 0 : Number(String(points).split(" P").join("").split(",").join("")),
          userCount: userNumber,
        });
        if (success) {
          navigate("/payment-detail/store", {
            state: {
              reservationId: data.id,
            },
          });
        } else {
          setErrorText(ERROR_TEXT.failedToPayment);
          setErrorModal(true);
        }
      } catch (error) {
        setErrorText(ERROR_TEXT.failedToRequest);
        setErrorModal(true);
      }
    } else {
      if (!window) {
        setPaymentLoadError(true);
        return;
      }
      // 결제 데이터
      const data = {
        pg: `tosspayments.${process.env.REACT_APP_MERCHANT_ID}`,
        pay_method: "card",
        merchant_uid: `mid_${new Date().getTime()}`,
        amount: finalPrice,
        name: "S캐디 호출 결제",
        buyer_name: userInfo.username,
        buyer_email: userInfo.email,
        buyer_tel: userInfo.phone,
        custom_data: {
          caddieCount: Number(String(caddieType).slice(-1)),
          caddieType: String(caddieType).includes("round-s-caddie") ? "ROUND_S_CADDIE" : "S_CADDIE",
          isFranchise: false,
          franchiseId: franchiseInfo.id,
          requestMessage: request,
          reservationDatetime: `${moment(new Date(startDate)).format("YYYY-MM-DD")} ${String(
            meridiem === "am" ? hour : 12 + Number(hour),
          ).padStart(2, 0)}:${String(minute).padStart(2, 0)}:00`,
          usedPoint: !points ? 0 : Number(String(points).split(" P").join("").split(",").join("")),
          userCount: userNumber,
        },
        m_redirect_url: `${process.env.REACT_APP_BASE_URL}/payment`,
      };

      if (windowReactNativeWebView) {
        const params = {
          userCode: process.env.REACT_APP_IMP_ID,
          value: data,
          type: "payment",
        };
        const paramsToString = JSON.stringify(params);
        windowReactNativeWebView.postMessage(paramsToString);
      } else {
        checkRefreshToken()
          .then((response) => {
            localStorage.setItem("refreshToken", response.refreshToken);
            localStorage.setItem("accessToken", response.accessToken);

            const { IMP } = window;
            IMP.init(process.env.REACT_APP_IMP_ID);

            // 결제 창 호출
            IMP.request_pay(data, paymentCallback);
          })
          .catch((error) => {
            if (error.response.status === 403) {
              localStorage.removeItem("refreshToken");
              localStorage.removeItem("accessToken");

              navigate("/");
            }
          });
      }
    }
  };

  async function paymentCallback(response) {
    if (!response.success) {
      alert("오류로 인하여 결제가 시작되지 못했습니다.");
      return;
    }
    // const {
    //   apply_num,
    //   card_number,
    //   card_quota,
    //   custom_data,
    //   imp_uid,
    //   success,
    //   merchant_uid,
    //   paid_amount,
    //   pg_tid,
    //   receipt_url,
    //   status,
    // } = response;
    // if (success) {
    //   setIsLoading(true);
    //   try {
    //     const requestBody = {
    //       apply_num,
    //       card_number,
    //       card_quota,
    //       custom_data,
    //       imp_uid,
    //       merchant_uid,
    //       paid_amount,
    //       pg_tid,
    //       receipt_url,
    //       status,
    //       success,
    //     };
    //     const { success: updateSuccess, data: paymentData } = await requestPayment(requestBody);
    //     if (updateSuccess) {
    //       return navigate("/payment-detail/user", {
    //         state: {
    //           reservationId: paymentData.id,
    //         },
    //         replace: true,
    //       });
    //     }
    //   } catch (error) {
    //     setPaymentFailError(true);
    //   } finally {
    //     setIsLoading(false);
    //   }
    // }
  }

  const getUserPoint = async () => {
    setIsLoading(true);
    try {
      const { data: userData } = await fetchUserInfo();
      setUserInfo({
        email: userData.user.email,
        username: userData.user.username,
        role: userData.user.roles[0].name,
        social: userData.user.social?.socialType ?? "normal",
        phone: userData.user.phone ?? "",
      });
      const { success, data } = await fetchUserPoints();
      if (success) {
        setUserPoints(Number(data));
      }
      setIsLoading(false);
    } catch (error) {
      if (error.response.status === 500) {
        setErrorText(ERROR_TEXT.failedToRequest);
        setErrorModal(true);
        return;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getFranchiseInfo = async () => {
    setIsLoading(true);
    try {
      const { data } = await fetchFranchiseInfo(id);
      setFranchiseInfo({
        text: data.franchiseName,
        id: id,
      });

      if (data.startTime && data.endTime) {
        let amTempTime = {};
        let pmTempTime = {};
        const minList = [0, 10, 20, 30, 40, 50];
        const [startHour, startMinute] = String(data.startTime)
          .split(":")
          .map((t) => Number(t));
        const [endHour, endMinute] = String(data.endTime)
          .split(":")
          .map((t) => Number(t));
        for (let i = startHour; i <= endHour; i++) {
          if (i < 12) {
            if (i === startHour) {
              amTempTime[i] = minList.filter((m) => m >= startMinute);
            } else if (i === endHour) {
              amTempTime[i] = minList.filter((m) => m <= endMinute);
            } else {
              amTempTime[i] = minList;
            }
          } else {
            if (i === startHour) {
              pmTempTime[i - 12] = minList.filter((m) => m >= startMinute);
            } else if (i === endHour) {
              pmTempTime[i - 12] = minList.filter((m) => m <= endMinute);
            } else {
              pmTempTime[i - 12] = minList;
            }
          }
        }

        setAmTimeObject(amTempTime);
        setPmTimeObject(pmTempTime);
        setHour(startHour);
        setSHour(startHour);
        setMinute(endMinute);
      }
    } catch (err) {
      setErrorText(ERROR_TEXT.invalidAccess);
      setErrorModal(true);
      return;
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userInfo && userInfo.role) {
      if (userInfo.role === "ROLE_USER") {
        setExpectPoints(1000);
      } else if (userInfo.role === "ROLE_FRANCHISE") {
        setExpectPoints(2000);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [userInfo]);

  useEffect(() => {
    if (String(caddieType).includes("round-s")) {
      setPrice(60000 * Number(caddieType.slice(-1)));
    } else {
      setPrice((50000 + 10000 * userNumber) * Number(caddieType.slice(-1)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userNumber, caddieType]);

  useEffect(() => {
    if (!isNumber(Number(id))) {
      setErrorText(ERROR_TEXT.invalidAccess);
      setErrorModal(true);
      return;
    }
    getFranchiseInfo();
    if (windowReactNativeWebView) {
    }
    getUserPoint();

    // set MaxDate
    setMaxDate(
      new Date(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate() - 1),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Number(String(points).split(" P").join("").split(",").join("")) > price) {
      setPoints(`${Number(price).toLocaleString("USD")} P`);
    }

    if (isNotUsePoint) {
      setFinalPrice(price);
    } else {
      setFinalPrice(price - Number(String(points).split(" P").join("").split(",").join("")));
    }
  }, [price, isNotUsePoint, points]);

  useEffect(() => {
    if (amTimeObject && Object.keys(amTimeObject).length <= 0) {
      setMeridiem("pm");
      if (sHour > 12) {
        setHour(sHour - 12);
      } else {
        setHour(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amTimeObject, pmTimeObject]);

  return (
    <>
      <Header reservation BottomNavigation>
        <Back />
        <CenterTitle title={franchiseInfo.text} />
      </Header>
      <Layout mainClassName="PADDING_TOP_ZERO PADDING_BOTTOM_ZERO FULL_SIDES">
        <CustomModal isOpen={errorModal}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text={errorText} />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn text="확인" buttonGrade="tertiary" onClick={() => navigate(-1)} />
          </ModalButtonBox>
        </CustomModal>
        <CustomModal isOpen={paymentFailError}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text="결제 요청에 실패하였습니다. 다시 시도해주세요." />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn
              text="확인"
              buttonGrade="tertiary"
              onClick={() => setPaymentFailError(false)}
            />
          </ModalButtonBox>
        </CustomModal>
        <ErrorLoadPaymentModal
          isOpen={paymentLoadError}
          onClick={() => {
            setPaymentLoadError(false);
          }}
        />
        <CheckReservationDateModal
          isOpen={checkTime}
          onClick={() => {
            setCheckTime(false);
          }}
        />
        <section className="reservation-section">
          <form>
            <fieldset>
              <legend>날짜 시간 선택</legend>
              <div className="reservation-section__item">
                <p className="reservation-section__item--title">날짜 · 시간 선택</p>
                <ol className="reservation-list">
                  <li className="reservation-list__item">
                    <div className="reservation-list__item--list">
                      <img src={SVG.CALENDAR_SVG} alt="calendar img" />
                      <ReactDatePicker
                        open={false}
                        locale={ko}
                        shouldCloseOnSelect={false}
                        dateFormat={`yyyy.MM.dd (E)`}
                        selected={startDate}
                        onInputClick={() => setIsBottomSheet(true)}
                        customInput={<CustomDatePickerButton />}
                      />
                      <BottomSheet
                        blocking={true}
                        open={isBottomSheet}
                        onDismiss={() => {
                          setPickDate(startDate);
                          setIsBottomSheet(false);
                        }}
                      >
                        <CustomDatePicker
                          startDate={pickDate}
                          setStartDate={setPickDate}
                          handleCloseBottomSheet={() => {
                            setPickDate(startDate);
                            setIsBottomSheet(false);
                          }}
                          maxDate={maxDate}
                        />
                        <CustomBtn
                          text="날짜선택"
                          buttonGrade="primary"
                          addClass="MARGIN_BOTTOM_20 MARGIN_TOP_20"
                          onClick={() => {
                            setStartDate(pickDate);
                            setIsBottomSheet(false);
                          }}
                        />
                      </BottomSheet>
                    </div>
                  </li>
                  <li className="reservation-list__item">
                    <div className="reservation-list__item--list">
                      <img src={SVG.TIME_SVG} alt="clock img" />
                      <div className="select-wrap">
                        <div className="radio-input MARGIN_LEFT_5">
                          <input
                            className="radio-input__input"
                            type="radio"
                            id="am"
                            name="time"
                            value="am"
                            checked={meridiem === "am"}
                            disabled={amTimeObject && Object.keys(amTimeObject).length <= 0}
                            onChange={handleChangeMeridiem}
                          />
                          <label className="radio-input__label" htmlFor="am">
                            오전
                          </label>
                        </div>
                        <div className="radio-input MARGIN_LEFT_5">
                          <input
                            className="radio-input__input"
                            type="radio"
                            id="pm"
                            name="time"
                            value="pm"
                            checked={meridiem === "pm"}
                            disabled={pmTimeObject && Object.keys(pmTimeObject).length <= 0}
                            onChange={handleChangeMeridiem}
                          />
                          <label className="radio-input__label" htmlFor="pm">
                            오후
                          </label>
                        </div>
                      </div>
                      <div className="select-wrap MARGIN_LEFT_AUTO">
                        <div className="select-wrap__select ">
                          <select name="hour" id="hour" onChange={handleChangeHour} value={hour}>
                            {meridiem === "am" &&
                              amTimeObject &&
                              Object.keys(amTimeObject).map((h) => (
                                <option key={`hour-${h}`} value={h}>
                                  {h}
                                </option>
                              ))}
                            {meridiem === "pm" &&
                              pmTimeObject &&
                              Object.keys(pmTimeObject).map((h) => (
                                <option key={`hour-${h}`} value={h}>
                                  {Number(h) === 0 ? Number(h) + 12 : h}
                                </option>
                              ))}
                            {(!amTimeObject || !pmTimeObject) &&
                              Array.from({ length: 12 }, (v, i) => i).map((h) => (
                                <option key={`hour-${h}`} value={h}>
                                  {meridiem === "pm" && h === 0 ? h + 12 : h}
                                </option>
                              ))}
                          </select>
                        </div>
                        <span className="MARGIN_LEFT_5">시</span>
                        <div className="select-wrap__select MARGIN_LEFT_5">
                          <select
                            name="minute"
                            id="minute"
                            value={minute}
                            onChange={handleChangeMinute}
                          >
                            {meridiem === "am" &&
                              amTimeObject &&
                              amTimeObject[hour] &&
                              amTimeObject[hour].map((m) => (
                                <option key={`minute-${m}`} value={m}>
                                  {m}
                                </option>
                              ))}
                            {meridiem === "pm" &&
                              pmTimeObject &&
                              pmTimeObject[hour] &&
                              pmTimeObject[hour].map((m) => (
                                <option key={`minute-${m}`} value={m}>
                                  {m}
                                </option>
                              ))}
                            {(!amTimeObject || !pmTimeObject) &&
                              Array.from({ length: 6 }, (v, i) => i).map((m) => (
                                <option key={`minute-${m * 10}`} value={m * 10}>
                                  {m * 10}
                                </option>
                              ))}
                          </select>
                        </div>
                        <span className="MARGIN_LEFT_5">분</span>
                      </div>
                    </div>
                  </li>
                </ol>
              </div>
            </fieldset>
            <hr className="hr-thick" />

            <fieldset>
              <legend>캐디선택</legend>
              <div className="reservation-section__item">
                <ol className="reservation-list">
                  <li className="reservation-list__item">
                    <div className="reservation-list__item--list">
                      <div className="reservation-list__item--title">
                        <p>캐디 선택</p>
                        <button
                          type="button"
                          className="caddie-pop-button"
                          onClick={() => setIsGuidePop((val) => !val)}
                        >
                          <img src={SVG.NOTICE_BLUE_SVG} alt="notice img" />
                        </button>
                        {isGuidePop && (
                          <div className="notice-box" onClick={() => setIsGuidePop((val) => !val)}>
                            <div className="notice-box__item">
                              <p>S 캐디란?</p>
                              <span>같이 게임을 할 수 없음</span>
                            </div>

                            <div className="notice-box__item">
                              <p>라운드 S 캐디란?</p>
                              <span>같이 게임도 가능함</span>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="select-wrap MARGIN_LEFT_AUTO">
                        <div className="select-wrap__select ">
                          <select
                            name="caddie"
                            value={caddieType}
                            onChange={handleChangeCaddieType}
                          >
                            <option value="s-caddie-1">S 캐디 1명</option>
                            <option value="s-caddie-2">S 캐디 2명</option>
                            <option value="s-caddie-3">S 캐디 3명</option>
                            <option value="s-caddie-4">S 캐디 4명</option>
                            <option value="round-s-caddie-1">라운드 S 캐디 1명</option>
                            <option value="round-s-caddie-2">라운드 S 캐디 2명</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="reservation-list__item">
                    <div className="reservation-list__item--list flex-wrap">
                      <div className="reservation-list__item--title">
                        <p>인원수</p>
                      </div>

                      <div className="select-wrap MARGIN_LEFT_AUTO">
                        <div className="select-wrap__increase-decrease">
                          <button type="button" onClick={handleClickMinusUserNumber}>
                            {userNumber > 1 && <img src={SVG.MINUS_BLUE_SVG} alt="minus img" />}
                            {userNumber <= 1 && (
                              <img src={SVG.MINUS_DISABLE_SVG} alt="minus img disabled" />
                            )}
                          </button>
                          <span>{userNumber}명</span>
                          <button type="button" onClick={handleClickPlusUserNumber}>
                            {userNumber < 4 && <img src={SVG.PLUS_BLUE_SVG} alt="plus img" />}
                            {/* 필요하면 사용 */}
                            {userNumber >= 4 && (
                              <img src={SVG.PLUS_DISABLE_SVG} alt="plus img disabled" />
                            )}
                          </button>
                        </div>
                      </div>
                      <p className="product-guide-text">*1게임, 18홀 기준 금액입니다.</p>
                    </div>
                  </li>
                </ol>
              </div>
            </fieldset>
            <hr className="hr-thick" />

            <fieldset>
              <legend>요청사항</legend>
              <div className="reservation-section__item">
                <p className="reservation-section__item--title">요청사항</p>
                <ol className="reservation-list">
                  <li className="reservation-list__item">
                    <div className="reservation-list__item--list">
                      <div className="select-wrap FULL_WIDTH">
                        <div className="select-wrap__text-area">
                          <textarea
                            value={request}
                            onChange={handleChangeRequest}
                            placeholder="요청사항을 입력해 주세요."
                            maxLength={100}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </li>
                </ol>
              </div>
            </fieldset>
            <hr className="hr-thick" />

            <fieldset>
              <legend>결제하기</legend>
              <div className="reservation-section__item">
                <p className="reservation-section__item--title">결제하기</p>
                <ol className="reservation-list">
                  <li className="reservation-list__item">
                    <div className="reservation-list__item--list">
                      <div className="reservation-list__item--title">
                        <p className="FONT_SIZE_14">포인트 사용</p>
                      </div>

                      <div className="select-wrap MARGIN_LEFT_AUTO">
                        <div className="select-wrap__input">
                          <input
                            type="text"
                            value={points}
                            onFocus={handleFocusPoint}
                            onChange={handleChangePoints}
                            onBlur={handleBlurPoints}
                            placeholder={`사용가능포인트 ${Number(userPoints).toLocaleString(
                              "USD",
                            )}P`}
                          />
                          {isNotUsePoint && (
                            <p className="select-wrap__input--guide-text">
                              최소 1,000 포인트 이상 100 포인트 단위로 사용 가능합니다.
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="reservation-list__item MARGIN_TOP_40">
                    <div className="reservation-list__item--list">
                      <div className="reservation-list__item--title">
                        <p className="FONT_SIZE_14">예상적립포인트</p>
                      </div>

                      <div className="select-wrap MARGIN_LEFT_AUTO">
                        <span className="select-wrap__point-number FONT_SIZE_14">
                          {expectPoints.toLocaleString("USD")}P
                        </span>
                      </div>
                    </div>
                  </li>
                </ol>
              </div>
            </fieldset>
            <hr className="hr-thick" />

            <fieldset>
              <legend>총 결제금액</legend>
              <div className="reservation-section__item">
                <p className="reservation-section__item--title">환불정책</p>
                <p className="refund-text">게임시간 이전 100% 환불 가능 </p>
                <p className="refund-text">게임시간 이후 환불 불가</p>
              </div>
            </fieldset>

            <hr className="hr-thick" />
            <fieldset>
              <legend>총 결제금액</legend>
              <div className="reservation-section__item">
                <ol className="reservation-list">
                  <li className="reservation-list__item">
                    <div className="reservation-list__item--list">
                      <div className="reservation-list__item--title">
                        <p className="FONT_WEIGHT_SEMI_BOLD">총 결제 금액</p>
                      </div>

                      <div className="select-wrap MARGIN_LEFT_AUTO">
                        <span className="select-wrap__payment-number">
                          {Number(finalPrice).toLocaleString("USD")}원
                        </span>
                      </div>
                    </div>
                  </li>
                </ol>
              </div>
            </fieldset>
            <div className="payment-button-box">
              <button
                type="button"
                className="btn-common btn--primary"
                onClick={handleClickPayment}
                disabled={isNotUsePoint}
              >
                {/* TODO */}
                {/* if login type is store -> <button type="button" className="btn-common btn--primary" onClick={() => navigate("/payment-detail/user")}> */}
                {Number(finalPrice).toLocaleString("USD")}원 결제하기
              </button>
            </div>
          </form>
        </section>
      </Layout>
    </>
  );
}
