import moment from "moment";
import React from "react";

import { SVG } from "constants";
import { getKoreanAmPm, getKoreanDayByDay } from "utils/commonFunction";
import CustomLink from "./common/CustomLink";
import HorizonLineThin from "./common/HorizonLineThin";

function StoreScheduleItem({ reservation }) {
  return (
    <li className="card-list__item">
      <div className="card-list__item--info">
        <div className="info-box">
          <div className="info-box-_icon-box">
            <img src={SVG.CALENDAR_SVG} alt="calendar img" />
            <span>
              {reservation.reservationDatetime
                ? `${moment(new Date(reservation.reservationDatetime)).format(
                    "MM.DD",
                  )} (${getKoreanDayByDay(
                    moment(new Date(reservation.reservationDatetime)).day(),
                  )})`
                : ""}
            </span>
          </div>
          <span className="perpendicular-line">|</span>
          <div className="info-box-_icon-box">
            <img src={SVG.TIME_SVG} alt="clock img" />
            <span>
              {reservation.reservationDatetime
                ? `${getKoreanAmPm(moment(new Date(reservation.reservationDatetime)).hour())} 
                ${moment(new Date(reservation.reservationDatetime)).format("HH:mm")}`
                : ""}
            </span>
          </div>
        </div>
      </div>
      <HorizonLineThin />
      <div className="card-list__item--pay-info">
        <div className="info-box">
          <p>캐디유형</p>
          <span>{reservation.caddieType.type === "S_CADDIE" ? "S캐디" : "라운드 S캐디"}</span>
        </div>
        <div className="info-box">
          <p>캐디수</p>
          <span>{`${reservation.caddieType.count}명`}</span>
        </div>
        <div className="info-box">
          <p>인원수</p>
          <span>{`${reservation.numberOfUser}명`}</span>
        </div>
        {reservation.status === "PAYMENT_DONE" && (
          <CustomLink
            href={`/payment-detail/store`}
            state={{ reservationId: reservation.id }}
            text="결제완료"
            linkGrade="btn-common fit-element payment"
          />
        )}
        {reservation.status === "RESERVATION_CANCEL" && (
          <CustomLink
            href={`/payment-cancel`}
            state={{ reservationId: reservation.id }}
            text="예약취소"
            linkGrade="btn-common fit-element cancel"
            onClick={(e) => {
              e.preventDefault();
            }}
          />
        )}
        {reservation.status === "ASSIGNMENT_DONE" && (
          <CustomLink
            href={`/assign-complete/store`}
            state={{ reservationId: reservation.id }}
            text="배정완료"
            linkGrade="btn-common fit-element assign"
          />
        )}
      </div>
    </li>
  );
}

export default StoreScheduleItem;
