import React, { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { loadingState } from "atom";
import Layout from "Layout";
import Header from "Layout/Header";
import Back from "Layout/Header/Back";
import CenterTitle from "Layout/Header/CenterTitle";
import { getPushAlarmList, updateReadStatus } from "api/push";
import moment from "moment";
import EmptyContents from "components/common/EmptyContents";
// import EmptyContents from "components/common/EmptyContents";

export default function AlarmPage() {
  // recoil
  const [isLoading, setIsLoading] = useRecoilState(loadingState);

  const [alarmList, setAlarmList] = useState([]);

  const fetchAlarmList = async () => {
    setIsLoading(true);
    try {
      const { data } = await getPushAlarmList();
      setAlarmList(data.alarmList);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const updateRead = async () => {
    try {
      const { data } = await updateReadStatus();
    } catch (error) {}
  };

  useEffect(() => {
    fetchAlarmList().then(() => {
      updateRead();
    });
  }, []);

  return (
    <>
      <Header BottomNavigation>
        <Back />
        <CenterTitle title="알람" />
      </Header>
      <Layout mainClassName="visible-footer FULL_SIDES">
        <section class="alarm-section">
          <ol class="alarm-section__list">
            {!isLoading && alarmList.length <= 0 && <EmptyContents text="알림 내역이 없습니다." />}
            {!isLoading &&
              alarmList.length > 0 &&
              alarmList.map((alarm) => {
                if (alarm.read) {
                  return (
                    <li class="alarm-item" key={`alarm-${alarm.id}`}>
                      <span class="alarm-item--dot-read"></span>
                      <p class="alarm-item--text-read ONE_LINE_ELLIPSIS">{alarm.content} </p>
                      <span class="alarm-item--date-read">
                        {moment(new Date(alarm.createdDate)).format("YYYY.MM.DD")}
                      </span>
                    </li>
                  );
                } else {
                  return (
                    <li class="alarm-item" key={`alarm-${alarm.id}`}>
                      <span class="alarm-item--dot"></span>
                      <p class="alarm-item--text ONE_LINE_ELLIPSIS">{alarm.content} </p>
                      <span class="alarm-item--date">
                        {moment(new Date(alarm.createdDate)).format("YYYY.MM.DD")}
                      </span>
                    </li>
                  );
                }
              })}
            {/* <li class="alarm-item">
              <span class="alarm-item--dot-read"></span>
              <p class="alarm-item--text-read ONE_LINE_ELLIPSIS">
                테스트
              </p>
              <span class="alarm-item--date-read">2022.00.00</span>
            </li> */}
          </ol>
        </section>
      </Layout>
    </>
  );
}
