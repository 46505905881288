import { SVG } from "constants";

function ReservationCaddiePagination({ activePage, totalPage, setPage }) {
  const maxPage =
    activePage > 0 && activePage % 5 === 0
      ? activePage
      : (parseInt(activePage / 5, 10) + 1) * 5 < totalPage
      ? (parseInt(activePage / 5, 10) + 1) * 5
      : totalPage;
  const pageNumber = maxPage % 5 === 0 ? 5 : maxPage % 5;
  const pageList = Array.from(
    { length: pageNumber },
    (v, i) => i + 1 + parseInt((activePage - 1) / 5, 10) * 5,
  );
  const prevButtonPage =
    parseInt((activePage - 1) / 5, 10) > 0 ? (parseInt((activePage - 1) / 5, 10) - 1) * 5 + 1 : 1;
  const nextButtonPage =
    parseInt((activePage - 1) / 5, 10) === parseInt((totalPage - 1) / 5, 10)
      ? totalPage
      : (parseInt((activePage - 1) / 5, 10) + 1) * 5 + 1;
  return (
    <div className="pagination-wrap">
      <button
        type="button"
        className={`pagination-button prev-button ${activePage !== 1 && "active"}`}
        disabled={activePage === 1}
        onClick={() => setPage(prevButtonPage)}
      >
        <img src={SVG.NEXT_CIRCLE_SVG} alt="back circle img" />
      </button>
      <div className="pagination-button-list">
        {pageList.map((page) => {
          return (
            <button
              key={`paging-${page}`}
              type="button"
              className={`pagination-button-list__button ${page === activePage && "active"}`}
              onClick={() => setPage(page)}
            >
              {page}
            </button>
          );
        })}
      </div>
      <button
        type="button"
        className={`pagination-button next-button ${totalPage !== activePage && "active"}`}
        disabled={totalPage === activePage}
        onClick={() => setPage(nextButtonPage)}
      >
        <img src={SVG.NEXT_CIRCLE_SVG} alt="back circle img" />
      </button>
    </div>
  );
}

export default ReservationCaddiePagination;
