/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { Route, Routes } from "react-router-dom";

import * as Pages from "./pages";
import Splash from "components/Splash";
import { linkUrl, RN_TYPE } from "constants";
import windowReactNativeWebView from "utils/windowReactNativeWebView";

const App = () => {
  useEffect(() => {
    ReactModal.setAppElement("#root");
  });

  const listener = (event) => {
    const { data, type } = JSON.parse(event.data);

    if (type === RN_TYPE.DEVICE_TOKEN) {
      if (data) {
        localStorage.setItem("deviceId", data)
      }
    } else if (type === RN_TYPE.FCM_TOKEN) {
      if (data) {
        localStorage.setItem("fcmToken", data);
      }
    }
  };

  const reactNativeListener = () => {
    if (windowReactNativeWebView) {
      document.addEventListener("message", listener); //안드로이드 에서만 인식
      window.addEventListener("message", listener); // IOS에서만 인식
    }
  };

  useEffect(() => {
    reactNativeListener();
    if (windowReactNativeWebView) {
      windowReactNativeWebView.postMessage(JSON.stringify({ type: RN_TYPE.DEVICE_TOKEN }));

      windowReactNativeWebView.postMessage(JSON.stringify({ type: RN_TYPE.FCM_TOKEN }));
    }
    return () => {
      if (windowReactNativeWebView) {
        document.removeEventListener("message", listener); //안드로이드 에서만 인식
        window.removeEventListener("message", listener); // IOS에서만 인식
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      {/* common start*/}
      <Route path={linkUrl.main} element={<Pages.MainPage />} />
      <Route path={linkUrl.alarm} element={<Pages.AlarmPage />} />
      {/* <Route path="/sign-in-guide" element={<Pages.SignInGuidePage />} /> */}
      <Route path={linkUrl.signInGuide} element={<Pages.SignInGuidePage />} />
      <Route
        path={linkUrl.signInSocial.user}
        element={<Pages.SignInSocialPage adminType="user" />}
      />
      <Route
        path={linkUrl.signInSocial.caddie}
        element={<Pages.SignInSocialPage adminType="caddie" />}
      />
      <Route
        path={linkUrl.signInSocial.store}
        element={<Pages.SignInSocialPage adminType="store" />}
      />
      <Route path={linkUrl.signIn.user} element={<Pages.SignInPage adminType="user" />} />
      <Route path={linkUrl.signIn.caddie} element={<Pages.SignInPage adminType="caddie" />} />
      <Route path={linkUrl.signIn.store} element={<Pages.SignInPage adminType="store" />} />

      {/* normal sign up */}
      <Route path={linkUrl.signUp} element={<Pages.SignUpTermsOfUsePage />} />
      <Route path={linkUrl.signUpForm} element={<Pages.SignUpPage />} />
      <Route path={linkUrl.signUpComplete} element={<Pages.SignUpCompletePage />} />

      {/* sns sign up */}
      <Route path={linkUrl.snsSignUp} element={<Pages.SnsSignUpTermsOfUsePage />} />
      <Route path={linkUrl.snsSignUpForm} element={<Pages.SnsSignUpPage />} />
      {/* find id / password */}
      <Route path="/find-email" element={<Pages.FindEmailPage />} />
      <Route path="/find-email-complete" element={<Pages.FindEmailComplete />} />
      <Route path="/find-password" element={<Pages.FindPasswordPage />} />
      <Route path="/password-setting" element={<Pages.PasswordSettingPage />} />

      {/* user start */}
      <Route path="/reservation" element={<Pages.ReservationPage />} />
      <Route
        path="/reservation-detail/:id"
        adminType="user"
        element={<Pages.ReservationDetailPage />}
      />
      <Route path="/payment-detail/user" adminType="user" element={<Pages.PaymentDetailPage />} />
      <Route path="/payment-cancel" element={<Pages.PaymentCancelPage />} />
      <Route path="/assign-complete/user" adminType="user" element={<Pages.AssignCompletePage />} />
      <Route path="/schedule-list/user" adminType="user" element={<Pages.SchedulePage />} />
      <Route path="/review" element={<Pages.ReviewPage />} />
      {/* user ------ end ------*/}

      {/* caddie start */}
      <Route
        path="/reservation/caddie"
        adminType="caddie"
        element={<Pages.ReservationCaddiePage />}
      />
      <Route
        path="/reservation-reception/caddie"
        adminType="caddie"
        element={<Pages.ReservationReceptionPage />}
      />
      {/* caddie ------ end ------*/}

      {/* store start */}
      <Route path="/schedule-list/store" element={<Pages.StoreSchedulePage />} />
      <Route path="/reservation-detail/store" element={<Pages.StoreReservationDetailPage />} />
      <Route path="/payment-detail/store" element={<Pages.StorePaymentDetailPage />} />
      <Route path="/assign-complete/store" element={<Pages.StoreAssignCompletePage />} />
      {/* store ------ end ------*/}

      {/* My_page */}
      <Route
        path="/my-page/user"
        adminType="user"
        element={<Pages.MyPageHome adminType="user" />}
      />
      <Route
        path="/my-page/caddie"
        adminType="caddie"
        element={<Pages.MyPageHome adminType="caddie" />}
      />
      <Route
        path="/my-page/store"
        adminType="store"
        element={<Pages.MyPageHome adminType="store" />}
      />

      <Route path="/my-page/info/user" element={<Pages.MyInfoPage adminType="user" />} />
      <Route path="/my-page/info/caddie" element={<Pages.MyInfoPage adminType="caddie" />} />
      <Route path="/my-page/info/store" element={<Pages.MyInfoPage adminType="store" />} />

      {/* My_page -common */}
      <Route path="/my-page/info/edit/user" element={<Pages.MyInfoEditPage adminType="user" />} />
      <Route
        path="/my-page/info/edit/caddie"
        element={<Pages.MyInfoEditPage adminType="caddie" />}
      />
      <Route path="/my-page/info/edit/store" element={<Pages.MyInfoEditPage adminType="store" />} />

      <Route
        path="/my-page/account-setting/user"
        adminType="user"
        element={<Pages.MyAccountSetting />}
      />
      <Route
        path="/my-page/account-setting/caddie"
        adminType="caddie"
        element={<Pages.MyAccountSetting />}
      />
      <Route
        path="/my-page/account-setting/store"
        adminType="store"
        element={<Pages.MyAccountSetting />}
      />
      <Route path="/my-page/service" element={<Pages.MyServicePage />} />
      <Route path="/my-page/service/qna" element={<Pages.MyQnaPage />} />
      <Route path="/my-page/service/notice-list" element={<Pages.MyNoticePage />} />
      <Route
        path="/my-page/service/notice-list/detail/:id"
        element={<Pages.MyNoticeDetailPage />}
      />
      <Route path="/my-page/service/terms" element={<Pages.MyTermsPage />} />
      <Route
        path="/my-page/service/terms/privacy"
        element={<Pages.MyTermsDetailPage termsType="privacy" />}
      />
      <Route
        path="/my-page/service/terms/location"
        element={<Pages.MyTermsDetailPage termsType="location" />}
      />
      <Route
        path="/my-page/service/terms/financial"
        element={<Pages.MyTermsDetailPage termsType="financial" />}
      />
      {/* My_page -common ------ end ------ */}

      {/* My_page for-caddie */}
      <Route path="/my-page/work" element={<Pages.MyWorkPage />} />
      <Route path="/my-page/work/work-list" element={<Pages.MyWorkListPage />} />

      <Route path="/my-page/work/education" element={<Pages.MyEducationPage />} />
      <Route
        path="/my-page/work/education/education-list"
        element={<Pages.MyOtherEducationListPage />}
      />
      <Route
        path="/my-page/work/education/education-list/detail/:id"
        element={<Pages.MyOtherEducationListDetailPage />}
      />
      {/* My_page for-caddie ------ end ------ */}

      {/* My_page ------ end ------*/}
      {/* social sign in */}
      <Route path="/auth/:socialType/:userType" element={<Pages.SocialPage />} />

      {/* Payment Process Page */}
      <Route path="/payment" element={<Pages.PaymentProcessPage />} />
    </Routes>
  );
};

export default App;
