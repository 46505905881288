import { axiosInstance } from "./api";

export const fetchNoticeList = async (page) => {
  const url = `/notice?page=${page}`;
  const { data } = await axiosInstance.get(url);
  return data;
};

export const fetchNoticeDetail = async (id) => {
  const url = `/notice/${id}`;
  const { data } = await axiosInstance.get(url);
  return data;
};
