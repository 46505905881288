import React, { useState, useEffect } from "react";
import { fetchBanners } from "api/common";
import { loadingState } from "atom";
import { useSetRecoilState } from "recoil";

import BannerLink from "components/common/BannerLInk";
import { RN_TYPE } from "constants";
import Layout from "Layout";
import Footer from "Layout/Footer";
import Header from "Layout/Header";
import Alarm from "Layout/Header/Alarm";
import Logo from "Layout/Header/Logo";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import { Swiper, SwiperSlide } from "swiper/swiper-react.cjs";
import SwiperCore, { Autoplay } from "swiper/core";
import windowReactNativeWebView from "utils/windowReactNativeWebView";
import reactNativeHandler from "utils/reactNativeEx";

export default function MainPage() {
  SwiperCore.use([Autoplay]);

  const geoLocation = localStorage.getItem("geolocation") ?? null;
  // recoil
  const setIsLoading = useSetRecoilState(loadingState);
  // state
  const [banners, setBanners] = useState([]);

  // fetch Banners
  const getBanners = async () => {
    try {
      setIsLoading(true);
      const { data } = await fetchBanners();
      setBanners(data);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const listener = (event) => {
    const { data, type } = JSON.parse(event.data);

    if (type === RN_TYPE.GEOLOCATION) {
      if (data) {
        localStorage.setItem(
          "geolocation",
          JSON.stringify({ latitude: data.latitude, longitude: data.longitude }),
        );
      }
    }
  };

  const reactNativeListener = () => {
    if (windowReactNativeWebView) {
      document.addEventListener("message", listener); //안드로이드 에서만 인식
      window.addEventListener("message", listener); // IOS에서만 인식
    }
  };

  useEffect(() => {
    getBanners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reactNativeListener();
    if (windowReactNativeWebView) {
      const value = "in";
      reactNativeHandler("back", value);

      if (!geoLocation) {
        windowReactNativeWebView.postMessage(JSON.stringify({ type: RN_TYPE.GEOLOCATION }));
      }
    }

    return () => {
      if (windowReactNativeWebView) {
        const value = "out";
        reactNativeHandler("back", value);

        document.removeEventListener("message", listener); //안드로이드 에서만 인식
        window.removeEventListener("message", listener); // IOS에서만 인식
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header home>
        <Logo />
        <Alarm />
      </Header>
      <Layout mainClassName="visible-footer FULL_SIDES">
        <section className="main-section">
          <div className="main-section__banner-box">
            <Swiper
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
            >
              {banners
                .filter((banner) => banner.type === "MAIN_TOP")
                .map((banner) => {
                  return (
                    <SwiperSlide key={`first-banner-${banner.id}`}>
                      <BannerLink
                        className="main-section__link"
                        bannerUrl={banner.url}
                        bannerImage={`${process.env.REACT_APP_S3_BUCKET_URL}${banner.filePath}`}
                        imgAlt="advertisement-banner-img"
                      />
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>

          <div className="main-section__banner-box">
            <Swiper
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
            >
              {banners
                .filter((banner) => banner.type === "MAIN_BOTTOM")
                .map((banner) => {
                  return (
                    <SwiperSlide key={`second-banner-${banner.id}`}>
                      <BannerLink
                        className="main-section__link"
                        bannerUrl={banner.url}
                        bannerImage={`${process.env.REACT_APP_S3_BUCKET_URL}${banner.filePath}`}
                        imgAlt="advertisement-banner-img"
                      />
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>

          {banners.filter((banner) => banner.type === "MAIN_TOP_FIXED_FIRST").length > 0 && (
            <div className="main-section__banner-box">
              <BannerLink
                className="main-section__link"
                bannerUrl={
                  banners.filter((banner) => banner.type === "MAIN_TOP_FIXED_FIRST")[0].url
                }
                bannerImage={`${process.env.REACT_APP_S3_BUCKET_URL}${
                  banners.filter((banner) => banner.type === "MAIN_TOP_FIXED_FIRST")[0].filePath
                }`}
                imgAlt="advertisement-banner-img"
              />
            </div>
          )}

          {banners.filter((banner) => banner.type === "MAIN_TOP_FIXED_SECOND").length > 0 && (
            <div className="main-section__banner-box">
              <BannerLink
                className="main-section__link"
                bannerUrl={
                  banners.filter((banner) => banner.type === "MAIN_TOP_FIXED_SECOND")[0].url
                }
                bannerImage={`${process.env.REACT_APP_S3_BUCKET_URL}${
                  banners.filter((banner) => banner.type === "MAIN_TOP_FIXED_SECOND")[0].filePath
                }`}
                imgAlt="advertisement-banner-img"
              />
            </div>
          )}

          {banners.filter((banner) => banner.type === "MAIN_TOP_FIXED_THIRD").length > 0 && (
            <div className="main-section__banner-box">
              <BannerLink
                className="main-section__link"
                bannerUrl={
                  banners.filter((banner) => banner.type === "MAIN_TOP_FIXED_THIRD")[0].url
                }
                bannerImage={`${process.env.REACT_APP_S3_BUCKET_URL}${
                  banners.filter((banner) => banner.type === "MAIN_TOP_FIXED_THIRD")[0].filePath
                }`}
                imgAlt="advertisement-banner-img"
              />
            </div>
          )}
        </section>
      </Layout>
      <Footer />
    </>
  );
}
