import CustomBtn from "./CustomBtn";
import CustomModal from "./CustomModal";
import ModalButtonBox from "./CustomModal/ModalButtonBox";
import ModalHeading from "./CustomModal/ModalHeading";
import ModalText from "./CustomModal/ModalText";
import HorizonLineThin from "./HorizonLineThin";

const ErrorLoadPaymentModal = ({ isOpen, onClick }) => {
  return (
    <CustomModal isOpen={isOpen}>
      <ModalHeading heading="알림" />
      <HorizonLineThin />
      <ModalText
        text={
          <>
            결제창을 불러오는데 실패했습니다.
            <br />
            잠시 후 다시 시도해주세요.
          </>
        }
      />
      <HorizonLineThin />
      <ModalButtonBox>
        <CustomBtn text="확인" buttonGrade="tertiary" onClick={onClick} />
      </ModalButtonBox>
    </CustomModal>
  );
};

export default ErrorLoadPaymentModal;
