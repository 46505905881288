import { applyAttendance } from "api/attendance";
import { loadingState } from "atom";
import { SVG } from "constants";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router";
import { useSetRecoilState } from "recoil";
import { getKoreanAmPm, getKoreanDayByDay } from "utils/commonFunction";
import CustomBtn from "./common/CustomBtn";
import HorizonLineThin from "./common/HorizonLineThin";

function CaddieSearchItem({ disabled, reservation, setAlreadyApply }) {
  const navigate = useNavigate();
  const setIsLoading = useSetRecoilState(loadingState);

  const handleClickAttendance = async () => {
    setIsLoading(true);
    try {
      const { success, data, error } = await applyAttendance(reservation.id);
      if (success) {
        navigate("/reservation-reception/caddie", {
          state: {
            reservationId: data.id,
          },
        });
      } else {
        if (error.errCode === "4017") {
          setAlreadyApply(true);
        }
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <li className={`card-list__item ${disabled ? "disabled" : ""}`}>
      <div className="card-list__item--info">
        <div className="info-box">
          <div className="info-box-_icon-box">
            <img src={SVG.CALENDAR_SVG} alt="calendar img" />
            <span>
              {reservation.reservationDatetime
                ? `${moment(new Date(reservation.reservationDatetime)).format(
                    "MM.DD",
                  )} (${getKoreanDayByDay(
                    moment(new Date(reservation.reservationDatetime)).day(),
                  )})`
                : ""}
            </span>
          </div>
          <span className="perpendicular-line">|</span>
          <div className="info-box-_icon-box">
            <img src={SVG.TIME_SVG} alt="clock img" />
            <span>
              {reservation.reservationDatetime
                ? `${getKoreanAmPm(moment(new Date(reservation.reservationDatetime)).hour())} 
                ${moment(new Date(reservation.reservationDatetime)).format("HH:mm")}`
                : ""}
            </span>
          </div>
        </div>
      </div>
      <HorizonLineThin />
      <div className="card-list__item--pay-info">
        <div className="info-box">
          <p>이용점명</p>
          <span>{reservation.franchise.franchiseName}</span>
        </div>
        <div className="info-box">
          <p>캐디유형</p>
          <span>{reservation.caddieType.type === "S_CADDIE" ? "S캐디" : "라운드 S캐디"}</span>
        </div>
        <div className="info-box">
          <p>인원수</p>
          <span>{reservation.numberOfUser}명</span>
        </div>
        <div className="info-box">
          <p>고객 결제 금액</p>
          <span>{Number(reservation.payments.amount).toLocaleString("USD")} 원</span>
        </div>
        <div className="apply-box">
          <textarea readOnly defaultValue={reservation.requestMessage}></textarea>
          <CustomBtn
            state={{ reservationId: reservation.id }}
            addClass="btn--white-bg"
            text="출근신청"
            buttonType="primary"
            onClick={handleClickAttendance}
          />
          {/* <CustomLink href="/reservation-reception/caddie" text="출근신청" linkType="primary" /> */}
          {/* <CustomLink href="/reservation-reception/caddie" addClass="btn--white-bg disabled" text="출근신청" linkType="primary" /> */}
        </div>
      </div>
    </li>
  );
}

export default CaddieSearchItem;
