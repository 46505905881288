import React, { useState, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import { loadingState } from "atom";
import Layout from "Layout";
import Header from "Layout/Header";
import Back from "Layout/Header/Back";
import CenterTitle from "Layout/Header/CenterTitle";
import WorkItem from "components/WorkItem";
import { fetchAttendanceList } from "api/attendance";
import { ERROR_TEXT } from "constants";
import CustomModal from "components/common/CustomModal";
import ModalHeading from "components/common/CustomModal/ModalHeading";
import HorizonLineThin from "components/common/HorizonLineThin";
import ModalText from "components/common/CustomModal/ModalText";
import ModalButtonBox from "components/common/CustomModal/ModalButtonBox";
import CustomBtn from "components/common/CustomBtn";
import CustomPagination from "components/common/CustomPagination";
// import EmptyContents from "components/common/EmptyContents";

export default function MyWorkListPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // recoil
  const setIsLoading = useSetRecoilState(loadingState);
  // state
  const [attendanceList, setAttendanceList] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [errorModal, setErrorModal] = useState(false);
  const [errorText, setErrorText] = useState("");

  // get My Work History
  const getMyWorkList = async (page) => {
    setIsLoading(true);
    setAttendanceList([]);

    try {
      const { data } = await fetchAttendanceList(page);
      setTotalPage(data.pagingResponse.totalPage > 0 ? data.pagingResponse.totalPage : 1);
      setAttendanceList(data.caddieAttendanceList);
      return;
    } catch (error) {
      if (error.response.status === 500) {
        setTotalPage(1);
        setErrorText(ERROR_TEXT.failedToRequest);
        setErrorModal(true);
        return;
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMyWorkList(searchParams.get("page") ? Number(searchParams.get("page")) : 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <>
      <Header mypage>
        <Back />
        <CenterTitle title="근무 내역" />
      </Header>
      <Layout>
        <CustomModal isOpen={errorModal}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text={errorText} />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn text="확인" buttonGrade="tertiary" onClick={() => navigate(-1)} />
          </ModalButtonBox>
        </CustomModal>
        <section className="work-list-section">
          {attendanceList && attendanceList.length === 0 && (
            <div className="none-text-relative">근무 내역이 없습니다.</div>
          )}
          <ol className="card-list">
            {attendanceList &&
              attendanceList.length > 0 &&
              attendanceList.map((work) => {
                return <WorkItem key={`work-${work.id}`} work={work} />;
              })}
          </ol>
          {attendanceList && attendanceList.length > 0 && (
            <CustomPagination
              activePage={searchParams.get("page") ? Number(searchParams.get("page")) : 1}
              totalPage={totalPage}
              pageUrl="/my-page/work/work-list"
            />
          )}
        </section>
      </Layout>
    </>
  );
}
