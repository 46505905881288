import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSetRecoilState } from "recoil";
import { userState, loadingState } from "atom";

import Layout from "Layout";
import Header from "Layout/Header";
import Back from "Layout/Header/Back";
import CenterTitle from "Layout/Header/CenterTitle";
import InputWrap from "components/common/InputWrap";
import CustomBtn from "components/common/CustomBtn";
import HorizonLineThin from "components/common/HorizonLineThin";
import DynamicInput from "components/common/InputWrap/DynamicInput";
import CustomModal from "components/common/CustomModal";
import ModalHeading from "components/common/CustomModal/ModalHeading";
import ModalText from "components/common/CustomModal/ModalText";
import ModalButtonBox from "components/common/CustomModal/ModalButtonBox";
import useTimer from "hook/useTimer";
import { emailValidation, passwordValidation, phoneNumberValidation } from "utils/commonFunction";
import { SEND_VERIFICATION_TIME, OTP_GUIDE_TEXT, ERROR_TEXT } from "constants";
import { checkEmail, signUp } from "api/auth";
import { certificationSmsCode, sendSms } from "api/sms";
import { linkUrl } from "constants";
import useSignIn from "hook/useSignin";

// 이메일 인증되면 email.isCheck true로 변경
export default function SignUpPage() {
  const navigate = useNavigate();
  const location = useLocation();
  // recoil
  const setUserInfo = useSetRecoilState(userState);
  const setIsLoading = useSetRecoilState(loadingState);
  // ref
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const rePasswordRef = useRef(null);
  const nameRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const otpRef = useRef(null);

  // state
  const [openModal, setOpenModal] = useState(false);

  const [email, setEmail] = useState({
    value: "",
    invalid: false,
    isChecked: false,
  });
  const [password, setPassword] = useState({
    value: "",
    invalid: false,
  });
  const [rePassword, setRePassword] = useState({
    value: "",
    invalid: false,
  });
  const [name, setName] = useState({
    value: "",
    invalid: false,
  });
  const [phoneNumber, setPhoneNumber] = useState({
    value: "",
    invalid: false,
    isChecked: false,
    btnDisabled: false,
  });
  const [otpNumber, setOtpNumber] = useState({
    value: "",
    invalid: false,
    valid: false,
    btnDisabled: false,
  });
  const [otpGuideText, setOtpGuideText] = useState("");
  const [errorText, setErrorText] = useState("");
  const [isSend, setIsSend] = useState(false);

  // timer
  const [currentMinutes, setCurrentMinutes] = useState(0);
  const [currentSeconds, setCurrentSeconds] = useState(0);
  const { count, start, stop, reset } = useTimer(SEND_VERIFICATION_TIME, 1000);

  const timer = () => {
    const checkMinutes = Math.floor(count / 60);
    const minutes = checkMinutes % 60;
    const seconds = count % 60;
    setCurrentMinutes(minutes);
    setCurrentSeconds(seconds);
  };

  const signInMutation = useSignIn();

  // onChange input handler
  const handleChangeEmail = (e) => {
    setEmail({ ...email, value: e.target.value, isChecked: false, invalid: false });
  };
  const handleChangePassword = (e) => {
    setPassword({ ...password, value: e.target.value, invalid: false });
  };
  const handleChangeRePassword = (e) => {
    setRePassword({ ...rePassword, value: e.target.value, invalid: false });
  };
  const handleChangeName = (e) => {
    setName({ ...name, value: e.target.value, invalid: false });
  };
  const handleChangePhoneNumber = (e) => {
    setPhoneNumber({
      ...phoneNumber,
      value: e.target.value,
      invalid: false,
      isChecked: false,
      btnDisabled: false,
    });
    setOtpNumber({ ...otpNumber, btnDisabled: true });
  };
  const handleChangeOtpNumber = (e) => {
    setOtpNumber({ ...otpNumber, value: e.target.value, btnDisabled: false, invalid: false });
  };

  // onClick handle
  const handleClickEmailDuplicated = async () => {
    // 이메일 형식 안 맞을 경우
    if (emailValidation(email.value)) {
      setEmail({ ...email, invalid: true });
      return;
    }

    try {
      setIsLoading(true);
      const result = await checkEmail(email.value);
      if (!result.success || result.data) {
        setEmail({ ...email, invalid: true });
        setErrorText(ERROR_TEXT.alreadySignUp);
        setOpenModal(true);
        return;
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }

    setEmail({ ...email, isChecked: true, invalid: false });
  };
  const handleClickSendOtp = async () => {
    setPhoneNumber({ ...phoneNumber, btnDisabled: true });
    if (phoneNumberValidation(phoneNumber.value)) {
      setPhoneNumber({ ...phoneNumber, invalid: true, btnDisabled: false });
      return;
    }
    try {
      setIsLoading(true);
      await sendSms("SIGN_UP", phoneNumber.value);
      // const data = await sendSms("SIGN_UP", phoneNumber.value);
      reset();
      if (!isSend) {
        setIsSend(true);
      }
      start();
      setPhoneNumber({ ...phoneNumber, isChecked: true, btnDisabled: true });
      setOtpNumber({ ...otpNumber, invalid: false, btnDisabled: false });
    } catch (err) {
      setErrorText(ERROR_TEXT.failedToSendSms);
      setOpenModal(true);
      setPhoneNumber({ ...phoneNumber, btnDisabled: false });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickReSendOtp = async () => {
    try {
      setIsLoading(true);
      await sendSms("SIGN_UP", phoneNumber.value);
      // const data = await sendSms("SIGN_UP", phoneNumber.value);
      reset();
      start();
      setOtpNumber({ ...otpNumber, invalid: false, btnDisabled: false });
    } catch (err) {
      setErrorText(ERROR_TEXT.failedToSendSms);
      setOpenModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignUp = async () => {
    initializeStateValid();
    // 아이디 체크
    if (!email.isChecked) {
      if (emailRef) {
        emailRef.current.focus();
      }
      setEmail({ ...email, invalid: true });
      return;
    }
    // 비밀번호 유효성 체크
    if (passwordValidation(password.value)) {
      if (passwordRef) {
        passwordRef.current.focus();
      }
      setPassword({ ...password, invalid: true });
      return;
    }
    // 비밀번호 두 개 동인한 지 확인
    if (password.value !== rePassword.value) {
      if (rePasswordRef) {
        rePasswordRef.current.focus();
      }
      setRePassword({ ...rePassword, invalid: true });
      return;
    }
    // 이름 비었는지 확인
    if (!name.value) {
      if (nameRef) {
        nameRef.current.focus();
      }
      setName({ ...name, invalid: true });
      return;
    }
    // phoneNumber 확인
    if (!phoneNumber.isChecked) {
      if (phoneNumberRef) {
        phoneNumberRef.current.focus();
      }
      setPhoneNumber({ ...phoneNumber, invalid: true });
      return;
    }
    // timer 확인
    if (count <= 0) {
      setOtpGuideText(OTP_GUIDE_TEXT.timeOver);
      setOtpNumber({ ...otpNumber, invalid: true, valid: false });
      return;
    }
    // otp 확인
    try {
      setIsLoading(true);
      const { success, data } = await certificationSmsCode(
        otpNumber.value,
        "SIGN_UP",
        phoneNumber.value,
      );
      if (!success || !data) {
        if (otpRef) {
          otpRef.current.focus();
        }
        setOtpGuideText(OTP_GUIDE_TEXT.wrong);
        setOtpNumber({ ...otpNumber, invalid: true });
        setIsLoading(false);
        return;
      }
      setOtpNumber({ ...otpNumber, valid: true });
    } catch (err) {}
    // sign up api
    try {
      setIsLoading(true);
      const { success, data, error } = await signUp({
        email: email.value,
        password: password.value,
        role: [location.state.adminType],
        username: name.value,
        phone: phoneNumber.value,
      });
      if (!success) {
        if (error.errCode === "4019") {
          setErrorText(ERROR_TEXT.alreadyPhone);
          setOpenModal(true);
          return;
        }
        setErrorText(ERROR_TEXT.failedToSignUp);
        setOpenModal(true);
        return;
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }

    try {
      const { data } = await signInMutation.mutateAsync({
        username: email.value,
        password: password.value,
        authType: location.state.adminType === "user" ? "ROLE_USER" : "ROLE_CADDIE",
      });
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: "setStorage",
            value: { type: "refreshToken", value: data.refreshToken },
          }),
        );
      }
      setUserInfo({
        email: data.email,
        username: data.username,
        role: data.roles[0],
        phone: data.phone,
        social: data.socialType ?? "normal",
        point: data.point,
      });
      navigate(linkUrl.signUpComplete, { state: { adminType: location.state.adminType } });
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  // function
  const initializeStateValid = () => {
    setEmail({ ...email, invalid: false });
    setPassword({ ...password, invalid: false });
    setRePassword({ ...rePassword, invalid: false });
    setName({ ...name, invalid: false });
    setPhoneNumber({ ...phoneNumber, invalid: false });
    setOtpNumber({ ...otpNumber, invalid: false, valid: false });
  };

  useEffect(() => {
    timer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  useEffect(() => {
    if (count <= 0) {
      stop();
    }
  }, [count, stop]);

  return (
    <>
      <Header BottomNavigation>
        <Back />
        <CenterTitle title="일반 회원가입" />
      </Header>
      <Layout>
        {/* already sign up modal */}
        <CustomModal isOpen={openModal} handleClose={() => setOpenModal(false)}>
          <ModalHeading heading="알림" />
          <HorizonLineThin />
          <ModalText text={errorText} />
          <HorizonLineThin />
          <ModalButtonBox>
            <CustomBtn text="확인" buttonGrade="tertiary" onClick={() => setOpenModal(false)} />
          </ModalButtonBox>
        </CustomModal>

        <section className="terms-of-use-section">
          <form id="sign-up">
            <fieldset>
              <legend>이용약관</legend>
              <InputWrap>
                <DynamicInput
                  required
                  labelText="이메일"
                  id="email-check"
                  placeholder="이메일을 입력하세요."
                  guideText="이메일을 확인해주세요."
                  validText="사용 가능한 이메일입니다."
                  invalid={email.invalid}
                  valid={email.isChecked}
                  onChange={handleChangeEmail}
                  value={email.value}
                  inputRef={emailRef}
                >
                  <CustomBtn
                    text="중복확인"
                    buttonGrade="primary"
                    disabled={email.isChecked}
                    onClick={() => handleClickEmailDuplicated()}
                  />
                </DynamicInput>
                <DynamicInput
                  required
                  labelText="비밀번호"
                  inputType="password"
                  id="password-check"
                  placeholder="8~15자 · 영문 대소문자, 숫자, 특수문자를 조합하세요."
                  guideText="비밀번호가 일치하지 않습니다."
                  invalid={password.invalid}
                  onChange={handleChangePassword}
                  value={password.value}
                  inputRef={passwordRef}
                />
                <DynamicInput
                  required
                  labelText="비밀번호 확인"
                  inputType="password"
                  id="password-check-confirm"
                  placeholder="위 비밀번호와 동일하게 입력하세요."
                  guideText="비밀번호가 동일하지 않습니다."
                  invalid={rePassword.invalid}
                  onChange={handleChangeRePassword}
                  value={rePassword.value}
                  inputRef={rePasswordRef}
                />
                <DynamicInput
                  required
                  labelText="이름"
                  id="name"
                  placeholder="이름을 입력하세요."
                  guideText="입력정보를 확인 해주세요."
                  invalid={name.invalid}
                  onChange={handleChangeName}
                  value={name.value}
                  inputRef={nameRef}
                />
                <DynamicInput
                  required
                  labelText="휴대폰 번호"
                  id="phone"
                  placeholder="휴대폰 번호를 입력하세요."
                  guideText="휴대폰 번호를 확인해주세요."
                  invalid={phoneNumber.invalid}
                  onChange={handleChangePhoneNumber}
                  value={phoneNumber.value}
                  inputRef={phoneNumberRef}
                >
                  <CustomBtn
                    text="인증코드 발송"
                    buttonGrade="primary"
                    disabled={phoneNumber.isChecked || phoneNumber.btnDisabled}
                    onClick={() => handleClickSendOtp()}
                  />
                </DynamicInput>
                {isSend && (
                  <>
                    <DynamicInput
                      required
                      id="code-confirm"
                      placeholder="인증번호를 입력하세요."
                      guideText={otpGuideText}
                      validText="인증번호가 확인되었습니다."
                      invalid={otpNumber.invalid}
                      valid={otpNumber.valid}
                      onChange={handleChangeOtpNumber}
                      value={otpNumber.value}
                      inputRef={otpRef}
                      maxLength={6}
                    >
                      <p className="time-check">
                        {String(currentMinutes).padStart(2, "0")}:
                        {String(currentSeconds).padStart(2, "0")}
                      </p>
                    </DynamicInput>
                    <div className="re-send-button-box">
                      <button
                        className="re-send-button"
                        type="button"
                        disabled={!phoneNumber.btnDisabled || otpNumber.btnDisabled}
                        onClick={() => handleClickReSendOtp()}
                      >
                        재전송
                      </button>
                    </div>
                  </>
                )}
              </InputWrap>
            </fieldset>
          </form>
        </section>
        <div className="bottom-button">
          {/* <CustomBtn text="회원가입" buttonGrade="primary" onClick={() => setOpenModal(true)} /> */}
          <CustomBtn
            buttonType="submit"
            text="회원가입"
            buttonGrade="primary"
            onClick={() => handleSignUp()}
          />
          {/* <CustomBtn form="sign-up" buttonType="submit" text="회원가입" buttonGrade="primary" /> */}
        </div>
      </Layout>
    </>
  );
}
