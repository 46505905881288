// email validation
export const emailValidation = (email) => {
  const re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
  return !email.match(re);
};

// password validation
export const passwordValidation = (password) => {
  const re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&\-*])[A-Za-z\d!@#$%^&\-*]{8,15}$/;
  return !password.match(re);
};

// phone number validation
export const phoneNumberValidation = (phoneNumber) => {
  const re = /^[0-9]{9,11}$/;
  return !phoneNumber.match(re);
};

export const commaHandler = (stringParameter) => {
  if (typeof stringParameter === "string") {
    if (stringParameter.length <= 0) {
      return "";
    }
    const numberParsing = Number(stringParameter.split(",").join(""));
    return numberParsing.toLocaleString("en-US");
  }

  return stringParameter.toLocaleString("en-US");
};

// phoneNumber format
export const phoneNumberFormat = (phone) => {
  const stringPhone = String(phone);
  const length = stringPhone.length;

  if (length === 8) {
    return stringPhone.replace(/(\d{4})(\d{4})/, "$1-$2");
  } else if (stringPhone.startsWith("02") && (length === 9 || length === 10)) {
    return stringPhone.replace(/(\d{2})(\d{3,4})(\d{4})/, "$1-$2-$3");
  } else if (stringPhone.startsWith("02") && (length === 10 || length === 11)) {
    return stringPhone.replace(/(\d{3})(\d{3,4})(\d{4})/, "$1-$2-$3");
  } else {
    return stringPhone;
  }
};

// Return User Type
export const returnUserType = (type) => {
  switch (type) {
    case "ROLE_USER":
      return "user";
    case "ROLE_CADDIE":
      return "caddie";
    default:
      return "store";
  }
};

// 요일 리턴
export const getKoreanDayByDay = (day) => {
  switch (day) {
    case 0:
      return "일";
    case 1:
      return "월";
    case 2:
      return "화";
    case 3:
      return "수";
    case 4:
      return "목";
    case 5:
      return "금";
    default:
      return "토";
  }
};

// 오전 오후 리턴
export const getKoreanAmPm = (hour) => {
  if (Number(hour) >= 12) return "오후";
  return "오전";
};

export const getTimeObject = (startHour, startMinute, endHour, endMinute) => {
  const timeObject = {};
  if (startHour < 12) {
    timeObject["am"] = {};
  } else {
  }

  if (endHour < 12) {
  } else {
  }
  return timeObject;
};
