import React, { useState, useEffect, useRef } from "react";

import { SVG } from "constants";
import ScrollContainer from "react-indiana-drag-scroll";
import CustomBtn from "../CustomBtn";
import ModalButtonBox from "./ModalButtonBox";
import { fetchAddressList, saveCaddieLocation } from "api/address";

function ModalLocation({ onClose, locationList, setLocationList }) {
  const scrollRef = useRef(null);
  const [tempUserLocationList, setTempUserLocationList] = useState(
    JSON.parse(JSON.stringify(locationList)),
  );
  const [selectedCity, setSelectedCity] = useState(null);
  const [cityList, setCityList] = useState([]);
  const [secondCityList, setSecondCityList] = useState([]);

  const handleClickUserLocationItem = (idx) => {
    setTempUserLocationList([
      ...tempUserLocationList.slice(0, idx),
      ...tempUserLocationList.slice(idx + 1, tempUserLocationList.length),
    ]);
  };

  const handleClickSaveButton = async () => {
    try {
      const { success } = await saveCaddieLocation(
        JSON.parse(JSON.stringify(tempUserLocationList)),
      );
      if (success) {
        setLocationList(JSON.parse(JSON.stringify(tempUserLocationList)));
      }
    } catch (error) {
    } finally {
      onClose(false);
    }
  };
  const getAddressList = async () => {
    try {
      const { data } = await fetchAddressList();
      const newCityList = [];
      Object.keys(data).forEach((city) => {
        newCityList.push(String(city));
      });
      setCityList(newCityList);

      const newCitiesList = [];
      Object.values(data).forEach((cities) => {
        newCitiesList.push(cities);
      });
      setSecondCityList(newCitiesList);
    } catch (error) {
      setCityList([]);
      setSecondCityList([]);
    }
  };

  useEffect(() => {
    setTempUserLocationList(JSON.parse(JSON.stringify(locationList)));
    getAddressList();
    return () => {
      setSelectedCity(null);
      setCityList([]);
      setSecondCityList([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="custom-modal-local-wrap">
      <div className="local-wrap">
        <div className="local-box local-wrap__heading">
          <p>지역선택</p>
          <button type="button" onClick={onClose}>
            <img src={SVG.CLOSE_SVG} alt="close img" />
          </button>
        </div>

        <div className="local-box half local-wrap__sub-heading">
          <p>도/시</p>
          <p>시/구/군</p>
        </div>

        <div className="local-box half local-wrap__select">
          <ul className="local-select">
            {cityList &&
              cityList.map((city, index) => {
                return (
                  <li
                    className="local-select__item"
                    key={`city-${city}`}
                    onClick={() => {
                      if (selectedCity !== index) {
                        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
                      }
                      setSelectedCity(index);
                    }}
                  >
                    <button type="button" className={`${selectedCity === index ? "active" : ""}`}>
                      {city}
                    </button>
                  </li>
                );
              })}
          </ul>
          <ul className="local-select" ref={scrollRef}>
            {selectedCity !== null && (
              <>
                <li
                  className="local-select__item"
                  onClick={() => {
                    const item = [String(cityList[selectedCity]), "전체"];
                    if (JSON.stringify(tempUserLocationList).includes(JSON.stringify(item))) {
                      return;
                    }
                    const filterList = tempUserLocationList.filter(
                      (loc) => loc[0] !== String(cityList[selectedCity]),
                    );
                    if (filterList.length >= 3) return;
                    setTempUserLocationList([...filterList, item]);
                  }}
                >
                  <button type="button">모두선택</button>
                </li>
                {secondCityList[selectedCity].map((city) => {
                  return (
                    <li
                      className="local-select__item"
                      key={`second-city-${city}`}
                      onClick={() => {
                        if (tempUserLocationList.length >= 3) return;
                        if (
                          JSON.stringify(tempUserLocationList).includes(
                            JSON.stringify([String(cityList[selectedCity]), "전체"]),
                          )
                        ) {
                          return;
                        }
                        const item = [String(cityList[selectedCity]), String(city)];
                        if (!JSON.stringify(tempUserLocationList).includes(JSON.stringify(item))) {
                          setTempUserLocationList([...tempUserLocationList, item]);
                        }
                      }}
                    >
                      <button type="button">{city}</button>
                    </li>
                  );
                })}
              </>
            )}
          </ul>
        </div>

        <div className="local-box local-wrap__heading">
          <ScrollContainer className="scroll-container">
            {tempUserLocationList.map((location, index) => {
              return (
                <div key={`user-location-${location[0]}-${location[1]}`} className="scroll-item">
                  <div className="local-preview">
                    <span className="local-preview__title">{`${location[0]} ${location[1]}`}</span>
                    <button
                      type="button"
                      className="local-preview__close-button"
                      onClick={() => {
                        handleClickUserLocationItem(index);
                      }}
                    >
                      <img src={SVG.CLOSE_WHITE_SVG} alt="close img" />
                    </button>
                  </div>
                </div>
              );
            })}
          </ScrollContainer>
        </div>
        <ModalButtonBox>
          <CustomBtn text="완료" buttonGrade="tertiary" onClick={handleClickSaveButton} />
        </ModalButtonBox>
      </div>
    </div>
  );
}

export default ModalLocation;
