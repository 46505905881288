import React from "react";
import { Link } from "react-router-dom";

import { RN_TYPE } from "constants";

function Footer() {
  return (
    <footer>
      <section className="footer-section">
        <h3 className="footer-section__title">(주)에스캐디</h3>
        <div className="footer-section__info">
          <span>주소</span>
          <span>:</span>
          <span>대전광역시 유성구 대덕대로 548 2층(도룡동)</span>
          <span>|</span>
          <span>사업자정보</span>
          <span>:</span>
          <span>418-81-56024</span>
          <span>|</span>
          <span>통신판매 신고번호</span>
          <span>:</span>
          <span>제 2023-대전유성-0320호</span>
          <span>|</span>
          <span>정보처리 관리자</span>
          <span>:</span>
          <span>이소백 admscaddie@gmail.com</span>
          <span>|</span>
          <Link to="/my-page/service/terms">이용약관</Link>
        </div>
        <div className="footer-section__call">
          <p>
            고객센터{" "}
            <a
              href="tel:042-863-6024"
              onClick={(e) => {
                if (window.ReactNativeWebView) {
                  e.preventDefault();
                  window.ReactNativeWebView.postMessage(
                    JSON.stringify({ type: RN_TYPE.CALL, value: "042-863-6024" }),
                  );
                }
              }}
            >
              042-863-6024
            </a>
          </p>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
