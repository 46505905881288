import { axiosInstance } from "./api";

export const fetchBanners = async (isReservationAd = false) => {
  return axiosInstance.get(`/banner?isReservationAd=${isReservationAd}`).then((res) => {
    return res.data;
  });
};

export const fetchPrivacyPolicy = async (privacyPolicyType) => {
  return axiosInstance.get(`/privacy-policy/${privacyPolicyType}`).then((res) => {
    return res.data;
  });
};
