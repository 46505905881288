import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { SVG } from "constants";
import Layout from "Layout";
import Header from "Layout/Header";
import Back from "Layout/Header/Back";
import CenterTitle from "Layout/Header/CenterTitle";
import DetailHeading from "components/common/DetailItem/DetailHeading";
import CustomLink from "components/common/CustomLink";

export default function SignInSocialPage({ adminType }) {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  useEffect(() => {
    if (adminType === "user") return setName("사용자");
    if (adminType === "caddie") return setName("캐디");
    if (adminType === "store") return setName("이용점");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header BottomNavigation>
        <Back />
        <CenterTitle title={`${name} 로그인`} />
      </Header>
      <Layout>
        <section className="sign-in-social-section">
          <DetailHeading
            className="page-heading"
            title={
              <>
                간편 <strong>로그인</strong>으로
                <br />
                빠르게 이용해보세요.
              </>
            }
          />
          <div className="sign-in-social__btn-box">
            <div className="social-btn__box">
              {adminType !== "store" && (
                <>
                  <button
                    type="button"
                    className="kakao"
                    onClick={() => {
                      const url = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URI}/${adminType}&response_type=code&scope=openid`;
                      if (window) {
                        window.location.href = url;
                      }
                    }}
                  >
                    <img src={SVG.KAKAO_LOGO} alt="kakao logo" />
                    카카오 로그인
                  </button>
                  {/* <a href={SOCIAL_URL(adminType, "KAKAO")} className="link kakao">
                    <img src={SVG.KAKAO_LOGO} alt="kakao logo" />
                    KAKAO
                  </a> */}
                  {/* <a href={SOCIAL_URL(adminType, "NAVER")} className="link naver">
                    <img src={SVG.NAVER_LOGO} alt="naver logo" />
                    NAVER
                  </a> */}
                  <button
                    type="button"
                    className="naver"
                    onClick={() => {
                      if (window) {
                        window.location.href = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_NAVER_REDIRECT_URI}/${adminType}`;
                      }
                    }}
                  >
                    <img src={SVG.NAVER_LOGO} alt="naver logo" />
                    네이버 로그인
                  </button>
                  {/* <a href={SOCIAL_URL(adminType, "GOOGLE")} className="link google">
                    <img src={SVG.GOOGLE_LOGO} alt="google logo" />
                    GOOGLE
                  </a> */}
                  <button
                    type="button"
                    className="google"
                    onClick={() => {
                      if (window) {
                        window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}.apps.googleusercontent.com&redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_URI}/${adminType}&response_type=code&scope=https://www.googleapis.com/auth/userinfo.email&access_type=offline`;
                      }
                    }}
                  >
                    <img src={SVG.GOOGLE_LOGO} alt="google logo" />
                    구글 로그인
                  </button>
                  {/* TODO:: 애플로그인 추후 붙이기 */}
                  {/* <a href="/" className="link apple">
                    <img src={SVG.APPLE_LOGO} alt="apple logo" />
                    APPLE
                  </a> */}
                  <button
                    type="button"
                    className="apple"
                    onClick={() => {
                      if (window) {
                        window.location.href = `https://appleid.apple.com/auth/authorize?client_id=com.scaddie.service&redirect_uri=https://scaddie.co.kr/api/auth/${adminType}/apple/callback&response_type=code%20id_token&scope=email&response_mode=form_post`;
                      }
                    }}
                  >
                    <img src={SVG.APPLE_LOGO} alt="apple logo" />
                    Apple 로그인
                  </button>
                </>
              )}
            </div>

            <div className="normal-sign-in-btn">
              <CustomLink
                href={`/sign-in/${adminType}`}
                linkGrade="primary"
                text="일반 로그인"
                addClass="btn--white-bg"
                state={{ adminType }}
              />
            </div>
          </div>

          {adminType !== "store" && (
            <div className="sign-up-link">
              <Link to="/sign-up" state={{ adminType }}>
                아직 회원이 아니신가요?&nbsp;&nbsp;<span>회원가입</span>
              </Link>
            </div>
          )}
        </section>
      </Layout>
    </>
  );
}
