import { SVG } from "constants";
import moment from "moment";
import React from "react";
import { getKoreanAmPm, getKoreanDayByDay } from "utils/commonFunction";

function MyEducationItem({ complete, education }) {
  return (
    <li className="card-list__item">
      <div className="card-list__item--info">
        <div className="info-box">
          <div className="info-box-_icon-box">
            <img src={SVG.CALENDAR_SVG} alt="calendar img" />
            <span>
              {education.educationDate
                ? `${moment(new Date(education.educationDate)).format(
                    "MM.DD",
                  )} (${getKoreanDayByDay(moment(new Date(education.educationDate)).day())})`
                : ""}
            </span>
          </div>
          <div className="info-box-_icon-box">
            <img src={SVG.TIME_SVG} alt="clock img" />
            <span>
              {education.educationDate
                ? `${getKoreanAmPm(moment(new Date(education.educationDate)).hour())} 
                ${moment(new Date(education.educationDate)).format("HH:mm")}`
                : ""}
            </span>
          </div>
          {complete ? (
            <span className="info-box__toggle-text">수료완료</span>
          ) : (
            <span className="info-box__toggle-text active">교육예정</span>
          )}
        </div>
      </div>
      <div className="card-list__item--pay-info">
        <div className="info-box">
          <p>교육장소</p>
          <span>{education.educationPlace}</span>
        </div>
      </div>
    </li>
  );
}

export default MyEducationItem;
